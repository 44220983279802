const personal_safety = require("./sections/personal_safety");
const quarry = require("./sections/quarry");

const sheet = {
  id: "b1",
  name: "basic",
  sections: [personal_safety.section, quarry.section]
};

exports.sheet = sheet;
