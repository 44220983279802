import StorageServiceInterface from "mdlui/services/storage-interface";

class BackendStorageService extends StorageServiceInterface {
  constructor({ backend, endpoint }) {
    super();

    this.backend = backend;
    this.endpoint = endpoint;
  }

  is_available() {
    return this.backend.is_available();
  }

  create(create_info) {
    return this.backend.post(this.endpoint, {
      body: create_info
    });
  }

  retrieve(uuid) {
    return this.backend.get(`${this.endpoint}/${uuid}`);
  }

  retrieve_all() {
    return this.backend.get(this.endpoint);
  }

  // eslint-disable-next-line no-unused-vars
  search({ filters, parameters }) {
    return this.backend.post("v1/logs/search", {
      body: { parameters }
    });
  }

  update(uuid, update_info) {
    return this.backend.patch(`${this.endpoint}/${uuid}`, {
      body: update_info
    });
  }

  delete(uuid) {
    return this.backend.delete(`${this.endpoint}/${uuid}`);
  }
}

export default BackendStorageService;
