<script>
  import { createEventDispatcher, getContext, onDestroy } from "svelte";
  import Icon from "fa-svelte";
  import { Dialog } from "svelma";
  import cloneDeep from "lodash/cloneDeep";
  import isEqual from "lodash/isEqual";
  import {
    faRulerCombined,
    faBomb,
    faCopy,
    faQuestionCircle,
    faInfo
  } from "@fortawesome/free-solid-svg-icons";

  import Radio from "mdlui/components/forms/radio";
  import TextBox from "mdlui/components/forms/textbox";
  import CancelButton from "mdlui/components/forms/cancel-button";

  import BlastingHoleForm from "mdlui/components/forms/hole/blasting";
  import InfoHoleForm from "mdlui/components/forms/hole/info";

  export let active = false;
  export let clipboard;
  export let cell = {};
  export let drill_log;
  export let drillers;
  export let readonly;

  $: if (!cell) {
    cell = {};
  }

  $: if (active === true) {
    on_open();
  }

  const dispatch = createEventDispatcher();
  const user = getContext("get_user")();
  let hole = {};
  let validate;
  let show_errors;

  const hole_type_list = [
    { name: "Blasting", value: "Blasting", icon: faBomb },
    { name: "Angle", value: "Angle", icon: faRulerCombined },
    { name: "Info", value: "Info", icon: faInfo },
    { name: "Other", value: "Other", icon: faQuestionCircle }
  ];

  // "Other" holes are exactly the same as "Blasting" holes.

  const hole_type_form_components = {
    Blasting: BlastingHoleForm,
    Angle: BlastingHoleForm,
    Info: InfoHoleForm,
    Other: BlastingHoleForm
  };

  function on_open() {
    show_errors = false;

    // The hole detail modal has been opened.  Clone any existing cell
    // data for the modal's use as a temporary data structure for form
    // elements to scribble on.  Otherwise, provide a default scribble
    // pad.  We don't want to write anything to the parent component's
    // data store until the user selects the "Save" button.

    if (cell.data) {
      hole = cloneDeep(cell.data);
    } else {
      hole = {
        type: "Blasting",
        notes: ""
      };

      // Only attempt to set the current user as the driller if the
      // current user is available as a driller.

      if (drillers.find((driller) => driller.uuid === user.uuid)) {
        hole.driller = user.uuid;
      }
    }

    show_errors = false;

    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");
  }

  function on_save() {
    if (validate()) {
      dispatch("save", { cell, data: cloneDeep(hole) });
      close();
    }
  }

  async function on_delete() {
    const confirm_delete = await Dialog.confirm({
      message:
        "Are you sure you want to delete this hole?<br>All information regarding this hole will be removed from the graph.",
      title: "Delete Hole?",
      type: "is-danger",
      confirmText: "Yes, Delete",
      cancelText: "No, Don't Delete"
    });

    if (confirm_delete) {
      dispatch("delete", { cell });
      close();
    }
  }

  function on_paste_button_click() {
    hole = { ...cloneDeep(clipboard), type: hole.type };
  }

  function do_we_prompt_on_cancel() {
    if (readonly) {
      return false;
    }
    return !cell.data ? true : !isEqual(hole, cell.data);
  }

  function close() {
    active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");
    dispatch("close");
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<div class="modal mdl-modal" class:is-active={active}>
  <div class="modal-background" />
  <div class="modal-content">
    <div class="box">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Hole {cell.id}</h1>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <div
              class="buttons has-tooltip-left has-tooltip-arrow"
              class:is-hidden={cell.data || !(hole.type === "Blasting" || hole.type === "Angle" || hole.type === "Other")}
              data-tooltip={!clipboard ? "Hole clipboard is empty" : null}>
              <button
                class="button"
                disabled={!clipboard}
                on:click={on_paste_button_click}>
                <span class="icon is-left">
                  <Icon icon={faCopy} />
                </span>
                <span>Paste details from last hole</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Radio
        label="Type"
        data={hole_type_list}
        disabled={readonly}
        tip={readonly ? "Hole data is currently read-only" : null}
        bind:value={hole.type} />

      <svelte:component
        this={hole_type_form_components[hole.type]}
        {hole}
        {drill_log}
        {drillers}
        {readonly}
        bind:show_errors
        bind:validate />

      <TextBox {readonly} label="Notes" bind:value={hole.notes} />

      <hr />

      <div class="buttons is-right">
        <div
          class="is-flex has-tooltip-top has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <button
            class="button is-primary"
            disabled={readonly}
            on:click={on_save}>
            Save
          </button>
        </div>
        {#if cell.data}
          <div
            class="is-flex has-tooltip-top has-tooltip-arrow"
            data-tooltip={readonly ? "Hole data is currently read-only" : null}>
            <button
              class="button is-danger"
              disabled={readonly}
              on:click={on_delete}>
              Delete
            </button>
          </div>
        {/if}
        <CancelButton
          do_we_prompt={do_we_prompt_on_cancel}
          button_text={readonly ? "Close" : "Cancel"}
          close_callback={close} />
      </div>
    </div>
  </div>
  <CancelButton
    do_we_prompt={do_we_prompt_on_cancel}
    button_type="close"
    close_callback={close} />
</div>
