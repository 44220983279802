import Model from "mdlui/model";

class SyncTaskModel extends Model {
  constructor({ models, indexed }) {
    super({
      models,
      storage: {
        indexed: { indexed, store: "sync_task" }
      }
    });
  }

  create(create_info) {
    // Ensure that all sync tasks we create have a timestamp; otherwise,
    // we won't know what order to process them in.

    if (!create_info.date_created) {
      return super.create({
        ...create_info,
        date_created: new Date().toISOString()
      });
    }

    return super.create(create_info);
  }
}

export default SyncTaskModel;
