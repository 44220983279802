<script>
  import { getContext, onMount } from "svelte";

  import { faFileExport } from "@fortawesome/free-solid-svg-icons";

  import Moment from "moment";

  import DateTime from "mdlui/components/forms/datetime";
  import DropDown from "mdlui/components/forms/dropdown";

  import CompanyTotalModal from "mdlui/modals/reports/company_total";
  import DailySubtotalByDrillerModal from "mdlui/modals/reports/daily_subtotal_by_driller";
  import JobTotalModal from "mdlui/modals/reports/job_total";
  import PatternSubtotalModal from "mdlui/modals/reports/pattern_subtotal";

  const backend = getContext("backend");
  const backnav = getContext("backnav");
  const history = getContext("history");
  const heartbeat = getContext("heartbeat");
  const cache = getContext("cache");

  let loading = false;

  const report_types = [
    {
      system_name: "daily subtotal by driller",
      name: "Daily Subtotals",
      id: 1
    },
    { system_name: "pattern subtotal", name: "Pattern Subtotals", id: 2 },
    { system_name: "job total", name: "Job Totals", id: 3 },
    { system_name: "company total", name: "Company Totals", id: 4 }
  ];

  let drillers = [];
  let drills = [];

  const reports = {
    "company total": {},
    "daily subtotal by driller": {},
    "job total": {},
    "pattern subtotal": {}
  };

  const default_start_date = Moment().subtract(30, "days").toISOString();
  const default_end_date = Moment().toISOString();

  let start_date = default_start_date;
  let end_date = default_end_date;

  const report_query = {
    report_parameters: {}
  };
  let show_daily_subtotal_filters = false;

  let is_job_total_modal_active = false;
  let is_company_total_modal_active = false;
  let is_daily_subtotal_by_driller_modal_active = false;
  let is_pattern_subtotal_modal_active = false;
  let hide_report_type_error = true;
  let hide_time_travel_error = true;
  const sort_report_types = (a, b) => {
    return a.id - b.id;
  };

  onMount(on_mount);

  async function on_mount() {
    backnav.set($history.previous ? $history.previous.path : "/");

    drillers = [{ name: "All" }, ...cache.lists.drillers];
    drills = [{ name: "All" }, ...cache.lists.drills];
  }

  function on_report_type_changed(event, report_type) {
    hide_report_type_error = true;
    switch (report_type) {
      case "daily subtotal by driller":
        show_daily_subtotal_filters = true;
        break;
      default:
        show_daily_subtotal_filters = false;
        break;
    }
  }

  function on_reset_fields() {
    report_query.type = undefined;
    report_query.report_parameters.drill = undefined;
    report_query.report_parameters.driller = undefined;
    show_daily_subtotal_filters = false;
    hide_report_type_error = true;
    hide_time_travel_error = true;
    start_date = default_start_date;
    end_date = default_end_date;
  }

  async function on_run_report() {
    if (!start_date || !end_date) {
      return;
    }

    if (start_date > end_date) {
      hide_time_travel_error = false;
      return;
    }

    // Clear this in case it's set from a previous attempt.
    hide_time_travel_error = true;

    if (report_query.type === undefined) {
      hide_report_type_error = false;
      return;
    }

    loading = true;
    const report_results = await backend.run_report({
      type: report_query.type,
      start_date,
      end_date,
      report_parameters: report_query.report_parameters
    });

    reports[report_query.type] = report_results;
    start_date = report_results.report_output.start_date;
    end_date = report_results.report_output.end_date;

    switch (report_query.type) {
      case "job total":
        is_job_total_modal_active = true;
        break;
      case "company total":
        is_company_total_modal_active = true;
        break;
      case "pattern subtotal":
        is_pattern_subtotal_modal_active = true;
        break;
      case "daily subtotal by driller":
        is_daily_subtotal_by_driller_modal_active = true;
        break;
      default:
        break;
    }
    loading = false;
  }
</script>

<h1 class="title">Reports</h1>

<div class="message is-danger" class:is-hidden={$heartbeat}>
  <div class="message-body">
    You must be connected to the Internet to run reports.
  </div>
</div>

{#if loading}
  <div class="button is-fullwidth is-large is-dark is-loading" />
{:else}
  <DropDown
    label="Report Type"
    icon={faFileExport}
    data={report_types}
    custom_sort={sort_report_types}
    data_key="system_name"
    placeholder="Select Report Type"
    on:selection={(event) => on_report_type_changed(event, report_query.type)}
    bind:value={report_query.type} />
  <DateTime label="Start Date" date_only={true} bind:value={start_date} />
  <DateTime label="End Date" date_only={true} bind:value={end_date} />
  {#if show_daily_subtotal_filters === true}
    <DropDown
      label="Driller List"
      data={drillers}
      data_key="uuid"
      bind:value={report_query.report_parameters.driller} />
  {/if}
  {#if show_daily_subtotal_filters === true}
    <DropDown
      label="Drill List"
      data={drills}
      data_key="uuid"
      bind:value={report_query.report_parameters.drill} />
  {/if}
  <div class="message is-danger" class:is-hidden={hide_report_type_error}>
    <div class="message-body">You must specify a report type to run.</div>
  </div>
  <div class="message is-danger" class:is-hidden={hide_time_travel_error}>
    <div class="message-body">
      The start date can not be before the end date.
    </div>
  </div>
  <div
    class="message is-danger"
    class:is-hidden={!(start_date === null || end_date === null)}>
    <div class="message-body">
      You must specify a date range for the report to run on.
    </div>
  </div>
  <div class="buttons is-right">
    <button class="button is-danger" on:click={on_reset_fields}>
      Clear Filters
    </button>
    <button
      class="button is-primary"
      disabled={!$heartbeat}
      on:click={on_run_report}>
      Run Report
    </button>
  </div>

  <CompanyTotalModal
    report_data={reports["company total"]}
    {start_date}
    {end_date}
    bind:active={is_company_total_modal_active} />

  <JobTotalModal
    report_data={reports["job total"]}
    {start_date}
    {end_date}
    bind:active={is_job_total_modal_active} />

  <PatternSubtotalModal
    report_data={reports["pattern subtotal"]}
    {start_date}
    {end_date}
    bind:active={is_pattern_subtotal_modal_active} />

  <DailySubtotalByDrillerModal
    report_data={reports["daily subtotal by driller"]}
    drillers_by_uuid={cache.lookups.drillers}
    {start_date}
    {end_date}
    bind:active={is_daily_subtotal_by_driller_modal_active} />
{/if}
