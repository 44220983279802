import Model from "mdlui/model";

class HoleSizeListModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/hole_size" },
        indexed: { indexed, store: "list.hole_size" }
      }
    });
  }
}

export default HoleSizeListModel;
