const VError = require("verror");

class GraphUtils {
  // Take the UI's graph data model and turn it into a format that's easier to
  // patch and store. Data coming from the UI looks like:
  //
  // [
  //   [
  //     {
  //       "row": 1,
  //       "column": "A",
  //       "id": "A1",
  //       "hole_depth": 36
  //     },
  //     {
  //       "row": 1,
  //       "column": "B",
  //       "id": "B1",
  //     },
  //     ...
  //   ],
  //   [
  //     {
  //       "row": 2,
  //       "column": "A",
  //       "id": "A2",
  //       "hole_depth": 18
  //     },
  //     {
  //       "row": 2,
  //       "column": "B",
  //       "id": "B2",
  //     },
  //     ...
  //   ],
  //   ...
  // ]
  //
  // The serialized form looks like:
  //
  // {
  //   "1:A" : { row: 1, column: "A", hole_depth: 36 },
  //   "1:B" : { row: 1, column: "B" },
  //   "2:A" : { row: 2, column: "A", hole_depth: 18 },
  //   "2:B" : { row: 2, column: "B" },
  //   ...
  // }
  //
  static serialize_graph(raw_graph_data) {
    if (Array.isArray(raw_graph_data) !== true) {
      throw new VError("not an array");
    }

    const serialized = {};
    raw_graph_data.forEach((row) => {
      if (Array.isArray(row) !== true) {
        throw new VError("not an array of arrays");
      }

      row.forEach((cell) => {
        const cell_id = `${cell.row}:${cell.column}`;
        serialized[cell_id] = cell;
      });
    });

    return serialized;
  }

  static deserialize_graph(serialized_graph_data) {
    const deserialized = [];

    // expect Row:Column e.g. (7:R), sort by row only (7)
    const sorted_keys = Object.keys(serialized_graph_data).sort((a, b) => {
      const digit_a = parseInt(a.split(":")[0], 10);
      const digit_b = parseInt(b.split(":")[0], 10);

      return digit_a - digit_b;
    });

    // build a sparse array array of arrays
    for (const key of sorted_keys) {
      // PATCH allows nulls to show up here.
      if (serialized_graph_data[key] !== null) {
        const row_num = parseInt(key.split(":")[0], 10);

        if (deserialized[row_num - 1] === undefined) {
          deserialized[row_num - 1] = [];
        }

        deserialized[row_num - 1].push(serialized_graph_data[key]);
      }
    }

    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const row_index in deserialized) {
      deserialized[row_index] = deserialized[row_index].sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.column > b.column ? 1 : a.column < b.column ? -1 : 0
      );
    }

    // Clear out any nulls we got from empty rows
    return deserialized.filter((arr) => Array.isArray(arr));
  }

  // this expects two hashes in C:R = {cell} format as stored in firestorm.
  // deserialized format that we serve up on the graph fetch.
  static sync_cells(existing_graph_data, new_graph_data) {
    const updated = {};
    const to_update = {};

    for (const [cell_key, cell_values] of Object.entries(new_graph_data)) {
      const sync_check = GraphUtils._check_cell_sync(
        existing_graph_data[cell_key],
        cell_values
      );

      if (sync_check === true) {
        updated[cell_key] = true;
        to_update[cell_key] = cell_values;
      } else {
        updated[cell_key] = false;
      }
    }

    // Anything from the original object that we don't have, send it up so it doesn't get zeroed out
    for (const [cell_key, cell_values] of Object.entries(existing_graph_data)) {
      if (to_update[cell_key] === undefined) {
        to_update[cell_key] = cell_values;
      }
    }

    return { updated, to_update };
  }

  static _check_cell_sync(old_cell, new_cell) {
    // If the old cell is null or undefined, allow it
    if (!old_cell) {
      return true;
    }

    // If the old cell doesn't have a date_updated for whatever reason, allow it
    if (!old_cell.date_updated) {
      return true;
    }

    // The cell we have is the most recent version, so no chance for conflict
    // the date_updated on the new cell indicates that it was created from this old_cell and modified
    // if it was newer that means we must have old_cell data here it means that the old_cell data we have is stale?
    if (old_cell.date_updated === new_cell.date_updated) {
      return true;
    }

    // None of the acceptable conditions passed, so it's a conflict
    return false;
  }
}

module.exports = GraphUtils;
