const Moment = require("moment");
const GraphUtils = require("./graph_utils.js");

const days_of_week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const RS = String.fromCharCode(30);

class Totals {
  // Calculate the daily activity on a drill log and its member graphs.
  //
  // Things we need to calculate:
  //
  // Job totals
  //  - Total holes for the job
  //  - Total footage for the job (sum of hole depths)
  //  - Total yardage for the job (sum each pattern's footage multiplied by coordinates)
  //  - Total bags of bentonite (this is actually input by the user)
  //
  // Pattern Totals
  //  -
  //
  // Daily totals (one row per date/driller/drill)
  //  - Driller/drill/date rows, one per combination:
  //    - Day (three letter day code: SUN/MON/etc)
  //    - Date (this is in the structure itself; we don't repeat it)
  //    - Driller name (the UUID is in the structure; a caller can look that up themselves)
  //    - Hole size
  //    - Drill # (this is in the structure and is not repeated)
  //    - Subdrill #
  //    - Hole depth range expressed as `${min}-${max}`
  //    - Number of holes
  //    - Footage drilled
  //  - Bags of bentonite (user input)
  //
  // Cap counts
  // There are two cap counts, estar and nonel.
  //
  // Per the wiki, Nonel is calculated via:
  // "Formula in pseudo code: Count of the number of holes where (HoleDepth is less than
  // (<measure> - (biggest number in any of the patterns) + Count of the number of holes
  // where (HoleDepth is greater than or equal to <prior measure> - (biggest number in any
  // of the patterns) e.g. for job where the biggest pattern is 8x10, tell me how many holes
  // are between 0' and (12'-10'=2') deep, between (16'-10'=6') and (20'-10'=10') deep, and
  // so forth."
  //
  // Estar is calculated via:
  //
  // "Formula in pseudo code: Count the number of holes where (HoleDepth is less than <measure>
  // AND HoleDepth is greater than or equal to <prior measure>) e.g. tell me how many holes
  // are between 0' and 16' deep, between 16' and 24' deep, and so forth.""

  static calculate_all(dataset) {
    const totals = Totals.new_totals_object();
    Object.values(dataset).forEach((drill_log) => {
      Totals.calculate_one(drill_log, [[drill_log.graphs]], totals);
    });
    return totals;
  }

  static calculate_one(drill_log, graphs, existing_results_object) {
    // eslint-disable-next-line no-unused-vars
    const self = this;

    const { job_number } = drill_log;
    if (Array.isArray(graphs) !== true) {
      throw new Error("passed a non-array to Totals");
    }

    const graph_data = [];
    graphs.forEach((graph) => {
      // We either got the full object or the graph_data array.
      if (graph.graph_data !== undefined) {
        // full object
        graph_data.push(graph.graph_data);
      } else {
        // array
        graph_data.push(graph);
      }
    });

    const results = existing_results_object || Totals.new_totals_object();

    const hole_depths = {};
    if (results.job[job_number] === undefined) {
      results.job[job_number] = { yardage: 0 };
    }

    // Bentonite calculations
    const bentonite_entries = drill_log.bentonite || {};
    Object.entries(bentonite_entries).forEach(
      ([record_indicator, no_of_bags]) => {
        const [, , , b_pattern, ,] = record_indicator.split(RS);
        Totals._add_stat_to_all_layers(
          results.daily,
          record_indicator,
          "bentonite",
          no_of_bags,
          "append"
        );

        if (results.patterns[b_pattern] === undefined) {
          results.patterns[b_pattern] = {};
        }

        if (results.patterns[b_pattern].bentonite === undefined) {
          results.patterns[b_pattern].bentonite = 0;
        }
        results.patterns[b_pattern].bentonite += parseInt(no_of_bags, 10);

        if (results.job[job_number] === undefined) {
          results.job[job_number] = {};
        }

        if (results.job[job_number].bentonite === undefined) {
          results.job[job_number].bentonite = 0;
        }
        results.job[job_number].bentonite += parseInt(no_of_bags, 10);

        if (results.company.bentonite === undefined) {
          results.company.bentonite = 0;
        }
        results.company.bentonite += parseInt(no_of_bags, 10);
      }
    );

    graph_data.forEach((graph) => {
      const cells = Totals._flatten(graph);
      cells.filter(Totals._is_hole_eligible).forEach((cell) => {
        const { driller, date_drilled, pattern, size } = cell;
        let { drill, subdrill } = cell;
        if (drill === undefined) {
          drill = "unassigned_drill";
        }
        if (subdrill === undefined) {
          subdrill = "unassigned_subdrill";
        }

        // What pattern do we belong to?
        if (hole_depths[pattern] === undefined) {
          hole_depths[pattern] = [];
        }

        const date = new Moment(date_drilled).local().format("YYYY-MM-DD");

        // Build the pattern container
        if (results.patterns[pattern] === undefined) {
          results.patterns[pattern] = {};
        }

        const record_hierarchy = [
          date,
          driller,
          drill,
          pattern,
          size,
          subdrill
        ];
        const record_locator = record_hierarchy.join(RS);

        // Easier shorthand later
        const pattern_tally = results.patterns[pattern];

        // Day of the week (daily only)
        Totals._add_stat_to_all_layers(
          results.daily,
          record_locator,
          "day_of_week",
          days_of_week[new Date(date_drilled).getDay()],
          "replace"
        );

        const cell_depth = parseFloat(cell.depth, 10);

        // Hole range (daily only)
        Totals._add_stat_to_all_layers(
          results.daily,
          record_locator,
          "min_depth",
          cell_depth,
          "less_than"
        );
        Totals._add_stat_to_all_layers(
          results.daily,
          record_locator,
          "max_depth",
          cell_depth,
          "greater_than"
        );

        const cell_yardage = Totals._calculate_yardage(pattern, cell_depth);
        Totals._add_stat_to_all_layers(
          results.daily,
          record_locator,
          "yardage",
          cell_yardage,
          "append"
        );

        // Hole count (daily, pattern, company, and job)
        Totals._add_stat_to_all_layers(
          results.daily,
          record_locator,
          "hole_count",
          1,
          "append"
        );
        if (pattern_tally.hole_count === undefined) {
          pattern_tally.hole_count = 1;
        } else {
          pattern_tally.hole_count += 1;
        }
        if (results.job[job_number].hole_count === undefined) {
          results.job[job_number].hole_count = 1;
        } else {
          results.job[job_number].hole_count += 1;
        }
        if (results.company.hole_count === undefined) {
          results.company.hole_count = 1;
        } else {
          results.company.hole_count += 1;
        }

        // Total footage (daily, pattern, company, and job)
        if (Number.isFinite(cell_depth)) {
          hole_depths[pattern].push(cell_depth);

          Totals._add_stat_to_all_layers(
            results.daily,
            record_locator,
            "footage_drilled",
            cell_depth,
            "append"
          );

          if (pattern_tally.footage_drilled === undefined) {
            pattern_tally.footage_drilled = cell_depth;
          } else {
            pattern_tally.footage_drilled += cell_depth;
          }

          if (results.job[job_number].footage_drilled === undefined) {
            results.job[job_number].footage_drilled = cell_depth;
          } else {
            results.job[job_number].footage_drilled += cell_depth;
          }

          if (results.company.footage_drilled === undefined) {
            results.company.footage_drilled = cell_depth;
          } else {
            results.company.footage_drilled += cell_depth;
          }
        }
      });
    });

    // Total yardage (pattern, company, and job)
    Object.entries(results.patterns).forEach(([pattern, result]) => {
      const yardage = Totals._calculate_yardage(
        pattern,
        result.footage_drilled
      );
      // eslint-disable-next-line no-param-reassign
      result.yardage = yardage;
      results.company.yardage += yardage;
      results.job[job_number].yardage += yardage;
    });

    Totals._estar_count(results.estar_counts, hole_depths);
    Totals._nonel_count(results.nonel_counts, hole_depths);
    return results;
  }

  static _calculate_yardage(pattern, footage) {
    const dimensions = pattern.split("x");
    if (dimensions.length === 2 && footage > 0) {
      return (
        (footage * parseFloat(dimensions[0]) * parseFloat(dimensions[1])) / 27
      );
    }
    return 0;
  }

  // This takes the results of two dump_entire_collection_to_hash() calls and collates
  // the entries for easier processing.
  static collate_entire_dataset(logs, graphs) {
    const dataset = {};
    for (const [log_uuid, log_values] of Object.entries(logs)) {
      dataset[log_uuid] = log_values;
      const expanded_graphs = [];
      log_values.graphs.forEach((graph_uuid) => {
        if (graphs[graph_uuid]) {
          const deserialized = GraphUtils.deserialize_graph(
            graphs[graph_uuid].graph_data
          );
          if (deserialized.length > 0) {
            expanded_graphs.push(Totals._flatten(deserialized));
          }
        }
      });
      dataset[log_uuid].graphs = Totals._flatten(expanded_graphs);
    }
    return dataset;
  }

  // Helper methods
  // We don't get Array.prototype.flat() til Node 11, and GCP is Node 10.
  static _flatten(arr) {
    return arr.reduce((acc, val) => [...acc, ...val], []);
  }

  static _add_stat_to_all_layers(
    daily_results_object,
    record_locator,
    stat_name,
    stat_value,
    operation
  ) {
    const all_layers = record_locator.split(RS);
    return Totals._add_layer(
      daily_results_object,
      all_layers,
      stat_name,
      stat_value,
      operation
    );
  }

  // eslint-disable-next-line consistent-return
  static _add_layer(results_object, layers, stat_name, stat_value, operation) {
    if (layers.length) {
      const new_layer = layers.shift();
      if (results_object[new_layer] === undefined) {
        // eslint-disable-next-line no-param-reassign
        results_object[new_layer] = {};
      }
      Totals._add_stat_to_layer(
        results_object[new_layer],
        stat_name,
        stat_value,
        operation
      );
      if (layers.length) {
        return Totals._add_layer(
          results_object[new_layer],
          layers,
          stat_name,
          stat_value,
          operation
        );
      }
    }
  }

  static _add_stat_to_layer(current_layer, stat_name, stat_value, operation) {
    switch (operation) {
      case "append":
        if (current_layer[stat_name] === undefined) {
          // eslint-disable-next-line no-param-reassign
          current_layer[stat_name] = stat_value;
        } else {
          // eslint-disable-next-line no-param-reassign
          current_layer[stat_name] += stat_value;
        }
        break;
      case "replace":
        // eslint-disable-next-line no-param-reassign
        current_layer[stat_name] = stat_value;
        break;
      case "less_than":
        if (current_layer[stat_name] === undefined) {
          // eslint-disable-next-line no-param-reassign
          current_layer[stat_name] = stat_value;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (current_layer[stat_name] >= stat_value) {
            // eslint-disable-next-line no-param-reassign
            current_layer[stat_name] = stat_value;
          }
        }
        break;
      case "greater_than":
        if (current_layer[stat_name] === undefined) {
          // eslint-disable-next-line no-param-reassign
          current_layer[stat_name] = stat_value;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (current_layer[stat_name] < stat_value) {
            // eslint-disable-next-line no-param-reassign
            current_layer[stat_name] = stat_value;
          }
        }
        break;
      default:
        console.log("default");
        break;
    }
  }

  static _estar_count(estar_counts, hole_depths) {
    Object.keys(hole_depths).forEach((pattern) => {
      hole_depths[pattern].forEach((depth) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const bucket_str of Object.keys(estar_counts)) {
          const bucket_int = parseInt(bucket_str, 10);
          if (depth < bucket_int) {
            // eslint-disable-next-line no-param-reassign
            estar_counts[bucket_str] += 1;
            return true;
          }
        }
        // We didn't return yet, so drop it in the biggest one and be done
        // eslint-disable-next-line no-param-reassign
        estar_counts["100"] += 1;
        return true;
      });
    });

    return true;
  }

  static _is_hole_eligible(cell) {
    if (cell.type === "Blasting") {
      return true;
    }

    if (
      (cell.type === "Other" || cell.type === "Angle") &&
      !cell.exclude_from_totals
    ) {
      return true;
    }

    return false;
  }

  static _nonel_count(nonel_counts, hole_depths) {
    Object.keys(hole_depths).forEach((pattern) => {
      let pattern_number = 0;
      pattern.split("x").forEach((dimension) => {
        const dim = parseFloat(dimension, 10);
        if (dim > pattern_number) {
          pattern_number = dim;
        }
      });

      hole_depths[pattern].forEach((depth) => {
        let prev_bucket_factor = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const bucket_str of Object.keys(nonel_counts)) {
          const bucket_int = parseInt(bucket_str, 10);
          const bucket_factor = bucket_int - pattern_number;
          if (depth > prev_bucket_factor && depth <= bucket_factor) {
            // eslint-disable-next-line no-param-reassign
            nonel_counts[bucket_str] += 1;
            return true;
          }
          prev_bucket_factor = bucket_factor;
        }
        // We didn't return yet, so drop it in the biggest bucket
        // eslint-disable-next-line no-param-reassign
        nonel_counts["100"] += 1;
        return true;
      });
    });

    return nonel_counts;
  }

  static new_totals_object() {
    return {
      company: { yardage: 0 },
      daily: {},
      job: {},
      patterns: {},
      estar_counts: {
        "16": 0,
        "24": 0,
        "30": 0,
        "40": 0,
        "60": 0,
        "80": 0,
        "100": 0
      },
      nonel_counts: {
        "0": 0,
        "12": 0,
        "16": 0,
        "20": 0,
        "30": 0,
        "40": 0,
        "50": 0,
        "60": 0,
        "80": 0,
        "100": 0
      }
    };
  }
}

module.exports = Totals;
