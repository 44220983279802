<script>
  export let subtitle;
</script>

<style>
/* Bulma's hero body has 3em of padding at the top and bottom.  That
 * doesn't leave much room to fit error messages, so we'll eliminate
 * the padding.  Everything is vertically centered anyhow.
 */

.hero-body {
  padding-top: 0;
  padding-bottom: 0;
}

p > img {
  margin-bottom: 1em;
  width: 400px;
}

h2 {
  margin-bottom: 2em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2ludGVyc3RpdGlhbC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBOzs7RUFHRTs7QUFFRjtFQUNFLGNBQWM7RUFDZCxpQkFBaUI7QUFDbkI7O0FBRUE7RUFDRSxrQkFBa0I7RUFDbEIsWUFBWTtBQUNkOztBQUVBO0VBQ0Usa0JBQWtCO0FBQ3BCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2ludGVyc3RpdGlhbC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi8qIEJ1bG1hJ3MgaGVybyBib2R5IGhhcyAzZW0gb2YgcGFkZGluZyBhdCB0aGUgdG9wIGFuZCBib3R0b20uICBUaGF0XG4gKiBkb2Vzbid0IGxlYXZlIG11Y2ggcm9vbSB0byBmaXQgZXJyb3IgbWVzc2FnZXMsIHNvIHdlJ2xsIGVsaW1pbmF0ZVxuICogdGhlIHBhZGRpbmcuICBFdmVyeXRoaW5nIGlzIHZlcnRpY2FsbHkgY2VudGVyZWQgYW55aG93LlxuICovXG5cbi5oZXJvLWJvZHkge1xuICBwYWRkaW5nLXRvcDogMDtcbiAgcGFkZGluZy1ib3R0b206IDA7XG59XG5cbnAgPiBpbWcge1xuICBtYXJnaW4tYm90dG9tOiAxZW07XG4gIHdpZHRoOiA0MDBweDtcbn1cblxuaDIge1xuICBtYXJnaW4tYm90dG9tOiAyZW07XG59XG4iXX0= */</style>

<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <p class="has-text-centered">
        <img src="/logo-black-800.png" alt="McCallum Rock Drilling" />
      </p>

      <h1 class="has-text-centered title">Drill Log System</h1>

      <h2 class="has-text-centered subtitle">{subtitle}</h2>

      <slot />
    </div>
  </div>
</section>
