<script>
  import { getContext, onMount, onDestroy } from "svelte";
  import { Dialog } from "svelma";
  import Icon from "fa-svelte";
  import {
    faCheckCircle,
    faCaretSquareUp,
    faCaretSquareDown,
    faExclamationCircle,
    faSearch
  } from "@fortawesome/free-solid-svg-icons";
  import naturalCompare from "natural-compare";
  import { SafetyChecklist } from "mdl-shared";
  import SafetyChecklistModal from "mdlui/modals/safety-checklist";
  import TimeEditorModal from "mdlui/modals/time-editor";
  import ViewSafetyChecklistModal from "mdlui/modals/view-safety-checklist";

  import DateTime from "mdlui/datetime";
  import DateTimeField from "mdlui/components/forms/datetime";

  const backend = getContext("backend");
  const cache = getContext("cache");
  const heartbeat = getContext("heartbeat");
  const models = getContext("models");
  const sync = getContext("sync");
  const user = getContext("get_user")();

  // These will get replaced with actual lists
  const times = {
    active_time: [],
    historical_time: []
  };
  let users = [];
  let time_types = [];
  let time_sheet_entries = [];
  let active_time = [];
  let historical_time = [];
  let filtered_historical_times = [];
  let strategy = [];
  let checklist_answers;
  let checklist_questions = {};
  let edited_time = {};
  let export_range = {};
  let timesheet = {};
  const logs_map = new Map();

  let heartbeat_unsubscribe;
  let loading = false;
  let show_errors = false;
  let is_clock_out_checklist = false;
  let is_editor_modal_active = false;
  let is_safety_checklist_modal_active = false;
  let is_view_safety_checklist_modal_active = false;
  let search_text = "";
  let sort = { column: "start_time", direction: "desc" };

  const list_sorter = (a, b) => {
    let a_val;
    let b_val;

    if (sort.cache) {
      a_val =
        (cache.lookups[`${sort.cache}s`][a[sort.column]] &&
          cache.lookups[`${sort.cache}s`][a[sort.column]].name) ||
        "";
      b_val =
        (cache.lookups[`${sort.cache}s`][b[sort.column]] &&
          cache.lookups[`${sort.cache}s`][b[sort.column]].name) ||
        "";
    } else {
      a_val = a[sort.column] || "";
      b_val = b[sort.column] || "";
    }

    return sort.direction === "asc"
      ? naturalCompare(a_val, b_val)
      : naturalCompare(b_val, a_val);
  };

  onMount(on_mount);
  onDestroy(on_destroy);

  async function filter_times() {
    filtered_historical_times = times.historical_time
      .filter(
        (time) =>
          (time.job_number &&
            time.job_number.match(new RegExp(search_text, "i"))) ||
          cache.lookups.users[time.user_uuid].name.match(
            new RegExp(search_text, "i")
          ) ||
          (time.vehicle_uuid &&
            cache.lookups.vehicles[time.vehicle_uuid].name.match(
              new RegExp(search_text, "i")
            )) ||
          cache.lookups.time_types[time.time_type_uuid].name.match(
            new RegExp(search_text, "i")
          )
      )
      .sort(list_sorter);
  }

  async function on_mount() {
    loading = true;
    heartbeat_unsubscribe = heartbeat.subscribe(load);
    loading = false;
  }

  async function on_destroy() {
    heartbeat_unsubscribe();
  }

  function on_checklist_button_click(time, read_only, end) {
    const type = cache.lookups.time_types[time.time_type_uuid];
    const raw_checklist = SafetyChecklist.raw(
      end ? type.end_checklist_type : type.safety_checklist_type
    );
    is_clock_out_checklist = end;
    if (raw_checklist !== null) {
      timesheet = time;
      checklist_questions = raw_checklist;
      if (read_only) {
        is_view_safety_checklist_modal_active = true;
      } else {
        is_safety_checklist_modal_active = true;
      }
    }
  }

  async function on_checklist_modal_closed({ detail: { complete } }) {
    if (complete && is_clock_out_checklist === true) {
      await stop_time();
    }
    is_clock_out_checklist = false;
    load();
  }

  function on_edit_button_click(time) {
    if (time !== null) {
      edited_time = time;
      is_editor_modal_active = true;
    }
  }

  function on_editor_modal_closed({ detail: { modified } }) {
    if (modified) {
      load();
    }
  }

  async function on_search_text_input() {
    filter_times();
  }

  function on_sort_change(column_key, list) {
    if (sort.column === column_key) {
      sort = {
        column: column_key,
        direction: sort.direction === "asc" ? "desc" : "asc"
      };
    } else {
      sort = { column: column_key, direction: "asc" };
    }

    if (column_key.endsWith("_uuid")) {
      [sort.cache] = column_key.split("_uuid");
    } else {
      sort.cache = null;
    }

    sort.list = list;

    times[list] = times[list].sort(list_sorter);
    filter_times();
  }

  async function download_csv(powder = false) {
    if (
      export_range.start_date &&
      export_range.end_date &&
      export_range.end_date >= export_range.start_date
    ) {
      const res = await backend.export_timesheets(export_range, powder);
      if (res) {
        const filename = powder ? "mcp_timesheets.csv" : "mrd_timesheets.csv";
        const url = window.URL.createObjectURL(await res);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }

      export_range = {
        start_date: undefined,
        end_date: undefined
      };
    }
  }

  async function end_time(time) {
    edited_time = time;

    if (edited_time.end_time) {
      show_errors = false;

      const type = cache.lookups.time_types[edited_time.time_type_uuid];
      const raw_checklist = SafetyChecklist.raw(type.end_checklist_type);
      const is_first_time = await models.safety_checklist.is_first_time_today(
        edited_time.user_uuid,
        type.end_checklist_type
      );

      if (is_first_time && raw_checklist !== null) {
        const items = SafetyChecklist.indexed(type.end_checklist_type);
        checklist_answers = {
          items,
          creator: user.email,
          updator: user.email,
          user_uuid: edited_time.user_uuid,
          type: type.end_checklist_type
        };

        timesheet = edited_time;
        checklist_questions = raw_checklist;
        is_safety_checklist_modal_active = true;
        is_clock_out_checklist = true;
      } else {
        stop_time();
      }
    } else {
      console.log("not a valid time");
    }
  }

  async function stop_time() {
    try {
      if (checklist_answers && checklist_answers.items) {
        checklist_answers = await models.safety_checklist.create(
          checklist_answers,
          {
            strategy
          }
        );
        edited_time.end_checklist_uuid = checklist_answers.uuid;
      }
      delete edited_time.job_number;
      delete edited_time.is_active_end_time_valid;
      await models.time_sheet_entry.update(edited_time.uuid, edited_time, {
        strategy,
        sync: { to: "indexed" }
      });
      if (!$heartbeat) {
        await models.sync_task.update(edited_time.uuid, {
          uuid: edited_time.uuid,
          model: "time_sheet_entry",
          operation: "update",
          object: edited_time
        });
        if (edited_time.end_checklist_uuid) {
          await models.sync_task.update(edited_time.end_checklist_uuid, {
            uuid: edited_time.end_checklist_uuid,
            model: "safety_checklist",
            operation: "update",
            object: checklist_answers
          });
        }
      } else {
        await sync.synchronize_time_sheet(edited_time);
      }
      edited_time = {};
      checklist_answers = {};
      is_clock_out_checklist = false;
      load();
    } catch (err) {
      if (err.code) {
        await Dialog.confirm({
          message: `The API returned a code of ${err.code}`,
          title: "API Error",
          type: "is-danger",
          confirmText: "Ok",
          showCancel: false
        });
      }
    }
  }

  function on_mouse_enter_overlapping(time_uuid) {
    const className = `u${time_uuid}`;
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].classList.add("highlight-overlapping");
    }
  }

  function on_mouse_leave_overlapping(time_uuid) {
    const className = `u${time_uuid}`;
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].classList.remove("highlight-overlapping");
    }
  }

  function get_overlapping_uuid_classes(time_uuid, overlapping_uuids) {
    let classNames = "";
    overlapping_uuids.push(time_uuid);
    overlapping_uuids.forEach((item) => {
      classNames += `u${item} `;
    });
    return classNames;
  }

  async function load() {
    if ($heartbeat) {
      try {
        await sync.push_time_sheets();
        await sync.push_safety_checklists();
      } catch (err) {
        console.error("Sync failure");
        console.log(err);
      }
    }

    strategy = $heartbeat ? ["backend"] : ["indexed"];

    // Get a list of logs and turn them into a map of uuids and numbers
    const all_logs = await models.drill_log.retrieve_all();
    all_logs.forEach((log) => logs_map.set(log.uuid, log.job_number));

    // Get a list of users the current user is allowed to clock in
    // and make an array of their uuids.
    users = await models.time_sheet_entry.allowed_users(user);
    const user_uuids = users.map((u) => u.uuid);

    // Get a list of all the job time types and vehicles
    time_types = await models.time_type.retrieve_all({ strategy });
    time_types = time_types.filter((tt) => tt.job_time === true);

    // Get a list of all the time_sheet_entries
    time_sheet_entries = await models.time_sheet_entry.retrieve_all();
    // Only show those for allowed users
    time_sheet_entries = time_sheet_entries.filter((tse) =>
      user_uuids.includes(tse.user_uuid)
    );

    // Split it up into active and historical times
    historical_time = time_sheet_entries.filter((tse) => !!tse.end_time);
    historical_time.forEach((time) => {
      const entry = time;
      entry.job_number = logs_map.get(entry.log_uuid);
      entry.editable = models.time_sheet_entry.is_editable(entry, user);
    });
    times.historical_time = historical_time.sort(list_sorter);
    filter_times();

    active_time = time_sheet_entries.filter((tse) => !tse.end_time);
    active_time = active_time.map((at) => {
      const time = { ...at };
      time.job_number = logs_map.get(time.log_uuid);
      time.end_time = DateTime.round_time_quarter_hour();
      time.is_active_end_time_valid = DateTime.is_time_entry_valid(
        time.start_time,
        time.end_time
      );
      console.log(time);
      return time;
    });
    times.active_time = active_time.sort(list_sorter);
  }
</script>

<style>
th {
  text-align: left;
}

button {
  cursor: pointer;
}

:root {
  --mdl-heading: 0deg;
  --mdl-popover-heading: 0deg;
}

.table.is-hoverable tbody tr {
  transition: background-color 0.5s;
}

tr.highlight-overlapping,
.table.is-hoverable tbody tr.highlight-overlapping:not(.is-selected):hover {
  background-color: rgba(241, 70, 104, 0.1);
}

@media screen and (max-width: 768px) {
  .columns .columns.is-mobile .column {
    padding-top: 0;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy90aW1lc2hlZXRzLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSxnQkFBZ0I7QUFDbEI7O0FBRUE7RUFDRSxlQUFlO0FBQ2pCOztBQUVBO0VBQ0UsbUJBQW1CO0VBQ25CLDJCQUEyQjtBQUM3Qjs7QUFFQTtFQUNFLGlDQUFpQztBQUNuQzs7QUFFQTs7RUFFRSx5Q0FBeUM7QUFDM0M7O0FBRUE7RUFDRTtJQUNFLGNBQWM7RUFDaEI7QUFDRiIsImZpbGUiOiJzcmMvcGFnZXMvdGltZXNoZWV0cy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbnRoIHtcbiAgdGV4dC1hbGlnbjogbGVmdDtcbn1cblxuYnV0dG9uIHtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG46cm9vdCB7XG4gIC0tbWRsLWhlYWRpbmc6IDBkZWc7XG4gIC0tbWRsLXBvcG92ZXItaGVhZGluZzogMGRlZztcbn1cblxuLnRhYmxlLmlzLWhvdmVyYWJsZSB0Ym9keSB0ciB7XG4gIHRyYW5zaXRpb246IGJhY2tncm91bmQtY29sb3IgMC41cztcbn1cblxudHIuaGlnaGxpZ2h0LW92ZXJsYXBwaW5nLFxuLnRhYmxlLmlzLWhvdmVyYWJsZSB0Ym9keSB0ci5oaWdobGlnaHQtb3ZlcmxhcHBpbmc6bm90KC5pcy1zZWxlY3RlZCk6aG92ZXIge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDI0MSwgNzAsIDEwNCwgMC4xKTtcbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgLmNvbHVtbnMgLmNvbHVtbnMuaXMtbW9iaWxlIC5jb2x1bW4ge1xuICAgIHBhZGRpbmctdG9wOiAwO1xuICB9XG59XG4iXX0= */</style>

<SafetyChecklistModal
  {checklist_questions}
  {timesheet}
  {checklist_answers}
  {strategy}
  bind:is_safety_checklist_modal_active
  on:close={on_checklist_modal_closed} />

<ViewSafetyChecklistModal
  {is_clock_out_checklist}
  {checklist_questions}
  {timesheet}
  bind:is_view_safety_checklist_modal_active
  on:close={on_checklist_modal_closed} />

<TimeEditorModal
  {edited_time}
  bind:is_editor_modal_active
  on:close={on_editor_modal_closed} />

{#if loading}
  <div class="modal is-active">
    <div class="modal-content">
      <div class="box">
        <h1 class="title">Loading Time Data...</h1>

        <progress class="progress is-large is-primary" />
      </div>
    </div>
  </div>
{/if}

<nav class="level">
  <div class="level-left">
    <div class="level-item">
      <h1 class="title">Time Sheets</h1>
    </div>
  </div>
</nav>

{#if user.capabilities.includes("time_sheet_entry:superuser")}
  <section class="section">
    <div class="container">
      <p class="title">Export Timesheets</p>
    </div>
    <div>
      <DateTimeField
        bind:value={export_range.start_date}
        date_only={true}
        label={"Export start date"}
        no_future={true} />
      <DateTimeField
        bind:value={export_range.end_date}
        date_only={true}
        label={"Export end date"}
        no_future={false} />
      <button class="button is-danger" on:click={() => download_csv(false)}>
        Download MRD CSV
      </button>
      <button class="button is-danger" on:click={() => download_csv(true)}>
        Download MCP CSV
      </button>
    </div>
  </section>
{/if}

<section class="section">
  <div class="container">
    <p class="title">Active Work</p>
    <table class="table stack is-fullwidth is-hoverable time-six-headings">
      <thead>
        <tr>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("user_uuid", "active_time")}>
                    Name
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "active_time" || sort.column !== "user_uuid"}
                    on:click={() => on_sort_change("user_uuid", "active_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("time_type_uuid", "active_time")}>
                    Role
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "active_time" || sort.column !== "time_type_uuid"}
                    on:click={() => on_sort_change("time_type_uuid", "active_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("job_number", "active_time")}>
                    Job
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "active_time" || sort.column !== "job_number"}
                    on:click={() => on_sort_change("job_number", "active_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("vehicle_uuid", "active_time")}>
                    Vehicle
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "active_time" || sort.column !== "vehicle_uuid"}
                    on:click={() => on_sort_change("vehicle_uuid", "active_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("start_time", "active_time")}>
                    Start
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "active_time" || sort.column !== "start_time"}
                    on:click={() => on_sort_change("start_time", "active_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-left">Stop</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {#if loading}
          <tr>
            <td colspan="3">
              <div class="button is-fullwidth is-light is-loading" />
            </td>
          </tr>
        {:else if times && times.active_time && times.active_time.length}
          {#each times.active_time as time (time.uuid)}
            <tr>
              <td>
                {(cache.lookups.users[time.user_uuid] && cache.lookups.users[time.user_uuid].name) || "unknown"}
              </td>
              <td>
                {(cache.lookups.time_types[time.time_type_uuid] && cache.lookups.time_types[time.time_type_uuid].name) || ""}
                {#if time.safety_checklist_uuid}
                  <button
                    class="button is-small"
                    on:click={() => on_checklist_button_click(time, true)}>
                    <Icon class="has-text-success" icon={faCheckCircle} />
                  </button>
                {/if}
              </td>
              <td class="is-hidden-handset">{time.job_number || ""}</td>
              <td>
                {(cache.lookups.vehicles[time.vehicle_uuid] && cache.lookups.vehicles[time.vehicle_uuid].name) || ""}
              </td>
              <td class="is-hidden-handset">
                {DateTime.format_american_date_and_time(time.start_time)}
              </td>
              <td>
                <DateTimeField
                  {show_errors}
                  on:time_validation={({ detail: { is_time_valid } }) => {
                    time.is_active_end_time_valid = is_time_valid;
                  }}
                  start_time={time.start_time}
                  bind:value={time.end_time}
                  no_future={true} />
              </td>
              <td class="is-button-column">
                <button
                  class="button is-danger"
                  disabled={!time.is_active_end_time_valid}
                  on:click={() => end_time(time)}>
                  Stop Work
                </button>
              </td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</section>

<section class="section">
  <div class="container">
    <p class="title">Historical Work</p>
    <div class="field">
      <div class="control has-icons-left">
        <input
          class="input"
          type="text"
          placeholder="Search times"
          bind:value={search_text}
          on:input={on_search_text_input} />
        <span class="icon is-small is-left">
          <Icon icon={faSearch} />
        </span>
      </div>
    </div>
    <table class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("user_uuid", "historical_time")}>
                    Name
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "historical_time" || sort.column !== "user_uuid"}
                    on:click={() => on_sort_change("user_uuid", "historical_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("time_type_uuid", "historical_time")}>
                    Role
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "historical_time" || sort.column !== "time_type_uuid"}
                    on:click={() => on_sort_change("time_type_uuid", "historical_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("job_number", "historical_time")}>
                    Job
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "historical_time" || sort.column !== "job_number"}
                    on:click={() => on_sort_change("job_number", "historical_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("vehicle_uuid", "historical_time")}>
                    Vehicle
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "historical_time" || sort.column !== "vehicle_uuid"}
                    on:click={() => on_sort_change("vehicle_uuid", "historical_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("start_time", "historical_time")}>
                    Start
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "historical_time" || sort.column !== "start_time"}
                    on:click={() => on_sort_change("start_time", "historical_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th class="has-text-link">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span
                    on:click={() => on_sort_change("end_time", "historical_time")}>
                    Stop
                  </span>
                </div>
                <div class="level-item">
                  <span
                    class="mdl-sorter"
                    class:has-text-grey-lighter={sort.list !== "historical_time" || sort.column !== "end_time"}
                    on:click={() => on_sort_change("end_time", "historical_time")}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {#if loading}
          <tr>
            <td colspan="3">
              <div class="button is-fullwidth is-light is-loading" />
            </td>
          </tr>
        {:else if times && times.historical_time && times.historical_time.length}
          {#each filtered_historical_times as time (time.uuid)}
            <tr
              on:mouseenter={time.overlapping ? () => on_mouse_enter_overlapping(time.uuid) : ""}
              on:mouseleave={time.overlapping ? () => on_mouse_leave_overlapping(time.uuid) : ""}
              class={time.overlapping ? get_overlapping_uuid_classes(time.uuid, time.overlapping_time_sheet_entry_uuids) : ""}>
              <td>
                {(cache.lookups.users[time.user_uuid] && cache.lookups.users[time.user_uuid].name) || "unknown"}
              </td>
              <td>
                <div class="columns is-variable is-1 is-widescreen">
                  {#if cache.lookups.time_types[time.time_type_uuid] && cache.lookups.time_types[time.time_type_uuid].name}
                    <div class="column">
                      {cache.lookups.time_types[time.time_type_uuid].name}
                    </div>
                  {/if}
                  {#if time.safety_checklist_uuid || time.end_checklist_uuid}
                    <div class="column is-narrow">
                      <div class="columns is-variable is-1 is-mobile">
                        {#if time.safety_checklist_uuid}
                          <div class="column is-narrow">
                            <button
                              class="button is-small"
                              on:click={() => on_checklist_button_click(time, true)}>
                              <Icon
                                class="has-text-success"
                                icon={faCheckCircle} />
                            </button>
                          </div>
                        {/if}
                        {#if time.end_checklist_uuid}
                          <div class="column is-narrow">
                            <button
                              class="button is-small"
                              on:click={() => on_checklist_button_click(time, true, true)}>
                              <Icon
                                class="has-text-danger"
                                icon={faCheckCircle} />
                            </button>
                          </div>
                        {/if}
                      </div>
                    </div>
                  {/if}
                </div>
              </td>
              <td class="is-hidden-handset">{time.job_number || ""}</td>
              <td>
                {(cache.lookups.vehicles[time.vehicle_uuid] && cache.lookups.vehicles[time.vehicle_uuid].name) || ""}
              </td>
              <td>{DateTime.format_american_date_and_time(time.start_time)}</td>
              <td>
                {DateTime.format_american_date_and_time(time.end_time)}
                {#if time.overlapping}
                  <Icon
                    class="has-text-danger ml-1"
                    icon={faExclamationCircle} />
                {/if}
              </td>
              <td class="is-button-column">
                <button
                  class="button is-danger"
                  disabled={!time.editable}
                  on:click={() => on_edit_button_click(time)}>
                  Edit Work
                </button>
              </td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</section>
