import Model from "mdlui/model";

class TimeTypeModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/time_type" },
        indexed: { indexed, store: "time_type" }
      }
    });
  }
}

export default TimeTypeModel;
