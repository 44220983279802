import Model from "mdlui/model";

class SafetyChecklistModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/safety_checklist" },
        indexed: { indexed, store: "safety_checklist" }
      }
    });
  }

  async is_first_time_today(user_uuid, type) {
    const recent_checklists = await this.models.completed_safety_checklists.retrieve_all();
    const unsynced_checklists = await this.models.safety_checklist.retrieve_all();
    const duplicate_checklists = recent_checklists
      .concat(unsynced_checklists)
      .filter((rc) => {
        if (
          rc.type === type &&
          rc.user_uuid === user_uuid &&
          rc.date_completed &&
          new Date(rc.date_completed).getDate() === new Date().getDate()
        ) {
          return true;
        }
        return false;
      });
    return duplicate_checklists.length === 0;
  }
}

export default SafetyChecklistModel;
