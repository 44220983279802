<script>
  import { getContext, onMount } from "svelte";
  import DateTime from "mdlui/datetime";
  import Icon from "fa-svelte";
  import {
    faChevronCircleLeft,
    faPhoneSlash,
    faUser,
    faSignOutAlt
  } from "@fortawesome/free-solid-svg-icons";
  import GeneralTimeModal from "mdlui/modals/general-time";

  export let user;
  export let backend_metadata;

  const backend = getContext("backend");
  const page = getContext("page");
  const backnav = getContext("backnav");
  const history = getContext("history");
  const heartbeat = getContext("heartbeat");
  const models = getContext("models");

  let is_burger_selected = false;
  let is_user_dropdown_selected = false;
  let is_time_modal_selected = false;
  let date_login_iso8601 = null;
  let date_login_extended = null;

  $: date_login_iso8601 = user.date_login.toISOString();
  $: date_login_extended = DateTime.format_extended_date_and_time(
    user.date_login
  );

  onMount(on_mount);

  async function on_mount() {
    if (!backend_metadata) {
      try {
        backend_metadata = await models.metadata.retrieve("backend");
      } catch (err) {
        // We know nothing about the backend.  That's fine.
      }
    }
  }

  function on_window_click(event) {
    if (
      !event.target.classList.contains("navbar-link") &&
      !is_time_modal_selected
    ) {
      is_burger_selected = false;
      is_time_modal_selected = false;
      event.stopPropagation();
    }
  }

  function on_burger_selected(event) {
    is_burger_selected = !is_burger_selected;
    event.stopPropagation();
  }

  function on_user_dropdown_selected() {
    is_user_dropdown_selected = !is_user_dropdown_selected;
  }

  function on_time_modal_selected() {
    is_time_modal_selected = !is_time_modal_selected;
  }

  async function on_back_button_click() {
    const { path, confirm } =
      typeof $backnav === "object" ? $backnav : { path: $backnav };

    if (!confirm || (await confirm())) {
      history.update((old_history) => ({ ...old_history, backnav: true }));
      page.show(path);
      backnav.set(null);
    }
  }

  function on_logout() {
    backend.logout();
  }
</script>

<style>
.navbar {
  position: fixed;
  width: 100%;
}
.account {
  width: 30rem;
}
.account img {
  max-height: 100%;
}

@media print {
  .navbar-menu,
  .navbar-burger {
    display: none;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL25hdmlnYXRpb24uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGVBQWU7RUFDZixXQUFXO0FBQ2I7QUFDQTtFQUNFLFlBQVk7QUFDZDtBQUNBO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBO0VBQ0U7O0lBRUUsYUFBYTtFQUNmO0FBQ0YiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbmF2aWdhdGlvbi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5uYXZiYXIge1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIHdpZHRoOiAxMDAlO1xufVxuLmFjY291bnQge1xuICB3aWR0aDogMzByZW07XG59XG4uYWNjb3VudCBpbWcge1xuICBtYXgtaGVpZ2h0OiAxMDAlO1xufVxuXG5AbWVkaWEgcHJpbnQge1xuICAubmF2YmFyLW1lbnUsXG4gIC5uYXZiYXItYnVyZ2VyIHtcbiAgICBkaXNwbGF5OiBub25lO1xuICB9XG59XG4iXX0= */</style>

<svelte:window on:click={on_window_click} />

<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="is-pulled-left" class:is-hidden={!$backnav}>
    <button
      class="button is-white is-medium is-hidden-widescreen"
      on:click={on_back_button_click}>
      <Icon icon={faChevronCircleLeft} />
    </button>
  </div>

  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      <img src="/logo-black-800.png" alt="McCallum Rock Drilling" />
    </a>

    <span
      role="button"
      class="navbar-burger burger {is_burger_selected ? "is-active" : ""}"
      aria-label="menu"
      aria-expanded="false"
      data-target="mdl-navigation"
      on:click={on_burger_selected}>
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </span>
  </div>

  <div
    id="mdl-navigation"
    class="navbar-menu {is_burger_selected ? "is-active" : ""}">
    <div class="navbar-start">
      <a class="navbar-item" href="/">Dashboard</a>
      {#if user.capabilities.includes("reports:run")}
        <a class="navbar-item" href="/reports">Reports</a>
      {/if}
      {#if user.capabilities.includes("settings")}
        <a class="navbar-item" href="/settings">Settings</a>
      {/if}
      <a class="navbar-item" href="/timesheets">Time Sheets</a>
      <a
        class="navbar-item"
        on:click|stopPropagation={() => on_time_modal_selected()}>
        Log General Time
      </a>
      <a
        class="navbar-item is-hidden-desktop"
        class:is-hidden={!$heartbeat}
        on:click={on_logout}>
        Logout
      </a>
    </div>

    <div class="navbar-end is-hidden-touch">
      <div class="navbar-item" class:is-hidden={$heartbeat}>
        <div class="tags has-addons">
          <span class="tag is-danger">
            <Icon icon={faPhoneSlash} />
          </span>
          <span class="tag is-dark">
            {#if backend_metadata}
              Offline since {DateTime.format_american_date_and_time(new Date(backend_metadata.heartbeat_timestamp))}
            {:else}Offline{/if}
          </span>
        </div>
      </div>

      <div
        class="navbar-item has-dropdown {is_user_dropdown_selected ? "is-active" : ""}"
        on:click={on_user_dropdown_selected}>
        <span class="navbar-link">
          <Icon icon={faUser} />
        </span>

        <div class="navbar-dropdown is-right">
          <div class="card account">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-64x64">
                    <img src={user.photo_url} alt="User Profile Photograph" />
                  </figure>
                </div>
                <div class="media-content">
                  <p class="title is-4">{user.name}</p>
                  <p class="subtitle is-6">{user.email}</p>
                </div>
              </div>

              <div class="content">
                <p class="subtitle is-6">{user.role}</p>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque lobortis facilisis leo, eget semper justo pulvinar
                  vitae. Donec hendrerit mi et justo luctus et fermentum urna
                  mattis.
                </p>

                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag">MDL UI</span>
                      <span class="tag">{process.env.MDL_UI_VERSION}</span>
                    </div>
                  </div>

                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag">MDL API</span>
                      <span class="tag">
                        {backend_metadata ? backend_metadata.api_version : "?"}
                      </span>
                    </div>
                  </div>
                </div>

                <nav class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <p>
                        <strong>Login:</strong>
                        <time datetime={date_login_iso8601}>
                          {date_login_extended}
                        </time>
                      </p>
                    </div>
                  </div>

                  <div class="level-right">
                    <div
                      class="buttons is-right has-tooltip-left has-tooltip-arrow"
                      data-tooltip={!$heartbeat ? "Internet connectivity required to log out" : null}>
                      <button
                        class="button is-dark"
                        disabled={!$heartbeat}
                        on:click={on_logout}>
                        <span class="icon is-left">
                          <Icon icon={faSignOutAlt} />
                        </span>
                        <span>Logout</span>
                      </button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="is-flex is-hidden-desktop has-background-dark has-text-white px-3
    py-1"
    class:is-hidden={$heartbeat}>
    <span class="icon">
      <Icon icon={faPhoneSlash} />
    </span>
    <span class="ml-2">
      {#if backend_metadata}
        Offline since {DateTime.format_american_date_and_time(new Date(backend_metadata.heartbeat_timestamp))}
      {:else}Offline{/if}
    </span>
  </div>

  <GeneralTimeModal bind:active={is_time_modal_selected} />
</nav>
