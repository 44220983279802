<script>
  import defaults from "lodash/defaults";
  import {
    faCircle,
    faTh,
    faTools,
    faUser
  } from "@fortawesome/free-solid-svg-icons";

  import DateTime from "mdlui/components/forms/datetime";
  import DropDown from "mdlui/components/forms/dropdown";
  import Stepper from "mdlui/components/forms/stepper";
  import Switch from "mdlui/components/forms/switch";
  import HoleProfiles from "mdlui/components/forms/hole-profiles";

  export let drill_log;
  export let drillers = [];
  export let hole = {};
  export let show_errors = false;
  export let readonly = false;

  let patterns = [];
  let sizes = [];
  let drills = [];
  let subdrills = [];

  // Any time we see a brand new hole, the data structure will contain
  // properties common to all hole types.  We'll need to react to hole
  // assignments by setting any defaults we want for blasting holes.

  $: if (hole) {
    defaults(hole, {
      profiles: [{ start: 0 }],
      depth: 0,
      date_drilled: new Date().toISOString()
    });
  }

  // Any time we see a drill log, we need to pull available patterns,
  // sizes, drills, and subdrills out of it for populating dropdowns.

  $: if (drill_log && drill_log.patterns) {
    const sets = {
      patterns: new Set(),
      sizes: new Set(),
      drills: new Set(),
      subdrills: new Set()
    };

    drill_log.patterns.forEach((pattern_group) => {
      if (pattern_group.pattern) {
        sets.patterns.add(pattern_group.pattern);
      }
      if (pattern_group.hole_size) {
        sets.sizes.add(pattern_group.hole_size);
      }
      if (pattern_group.drill) {
        sets.drills.add(pattern_group.drill);
      }
      if (pattern_group.subdrill) {
        sets.subdrills.add(pattern_group.subdrill);
      }
    });

    const mkitem = (name) => ({ name, value: name });

    patterns = Array.from(sets.patterns).map(mkitem);
    sizes = Array.from(sets.sizes).map(mkitem);
    drills = Array.from(sets.drills).map(mkitem);
    subdrills = Array.from(sets.subdrills).map(mkitem);
  }

  export function validate() {
    show_errors = true;

    return (
      hole.pattern &&
      hole.size &&
      hole.drill &&
      Number(hole.depth) >= 0 &&
      hole.driller &&
      hole.date_drilled &&
      hole.profiles.every(
        (profile) =>
          Number(profile.start) >= 0 &&
          Number(profile.end) > Number(profile.start) &&
          Number(profile.end) <= Number(hole.depth) &&
          profile.material
      )
    );
  }
</script>

<DropDown
  label="Pattern"
  icon={faTh}
  data={patterns}
  disabled={readonly}
  placeholder="Select Pattern"
  tip={readonly ? "Hole data is currently read-only" : null}
  invalid={show_errors && !hole.pattern}
  invalid_text="Pattern is required"
  bind:value={hole.pattern} />
<DropDown
  label="Hole Size"
  icon={faCircle}
  data={sizes}
  disabled={readonly}
  placeholder="Select Hole Size"
  tip={readonly ? "Hole data is currently read-only" : null}
  invalid={show_errors && !hole.size}
  invalid_text="Hole Size is required"
  bind:value={hole.size} />
<DropDown
  label="Drill"
  icon={faTools}
  data={drills}
  disabled={readonly}
  placeholder="Select Drill"
  tip={readonly ? "Hole data is currently read-only" : null}
  invalid={show_errors && !hole.drill}
  invalid_text="Drill is required"
  bind:value={hole.drill} />
<DropDown
  label="Subdrill"
  icon={faTools}
  data={subdrills}
  disabled={readonly}
  placeholder="Select Subdrill"
  tip={readonly ? "Hole data is currently read-only" : null}
  bind:value={hole.subdrill} />
<Stepper
  label="Depth"
  min="0"
  unit="ft"
  {readonly}
  placeholder="Depth"
  tip={readonly ? "Hole data is currently read-only" : null}
  bind:value={hole.depth} />

{#if hole.type !== "Blasting"}
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Looked Out</label>
    </div>

    <div class="field-body">
      <Stepper
        min="0"
        step="1"
        unit="ft"
        {readonly}
        placeholder="Distance"
        tip={readonly ? "Hole data is currently read-only" : null}
        bind:value={hole.looked_out_distance} />
      <Stepper
        min="0"
        max="359.9"
        step="0.1"
        unit="°"
        {readonly}
        placeholder="Angle"
        tip={readonly ? "Hole data is currently read-only" : null}
        bind:value={hole.looked_out_angle} />
    </div>
  </div>
{/if}

<HoleProfiles
  {show_errors}
  {readonly}
  max_depth={hole.depth}
  bind:profiles={hole.profiles} />
<DropDown
  label="Driller"
  icon={faUser}
  data={drillers}
  data_key="uuid"
  disabled={readonly}
  placeholder="Select Driller"
  tip={readonly ? "Hole data is currently read-only" : null}
  invalid={show_errors && !hole.driller}
  invalid_text="Driller is required"
  bind:value={hole.driller} />
{#if hole.type === "Other" || hole.type === "Angle"}
  <Switch
    disabled={readonly}
    type="is-danger"
    label="Exclude from Totals"
    tip={readonly ? "Hole data is currently read-only" : null}
    bind:checked={hole.exclude_from_totals} />
{/if}
<DateTime
  {readonly}
  label="Date/Time"
  {show_errors}
  tip={readonly ? "Hole data is currently read-only" : null}
  bind:value={hole.date_drilled} />
