<script>
  import { getContext, onMount } from "svelte";
  import { Dialog } from "svelma";
  import naturalCompare from "natural-compare";
  import DateTime from "mdlui/datetime";
  import Icon from "fa-svelte";
  import {
    faSearch,
    faPlusCircle,
    faCaretSquareUp,
    faCaretSquareDown
  } from "@fortawesome/free-solid-svg-icons";

  export let type;
  export let name;

  const models = getContext("models");
  const heartbeat = getContext("heartbeat");
  const message = getContext("message");
  const list = models.list[type];
  let loading = false;
  let list_items = [];
  let filtered_list_items = [];
  let new_list_item_name = "";
  let search_list_item_name = "";
  let show_errors = false;
  let is_new_list_item_name_valid = false;
  let sort = { column: "name", direction: "asc" };

  const list_sorter = (a, b) => {
    const a_val = a[sort.column] || "";
    const b_val = b[sort.column] || "";

    return sort.direction === "asc"
      ? naturalCompare(a_val, b_val)
      : naturalCompare(b_val, a_val);
  };

  onMount(on_mount);

  async function on_mount() {
    loading = true;
    // eslint-disable-next-line no-multi-assign
    list_items = filtered_list_items = await list.retrieve_all();
    list_items.sort(list_sorter);
    loading = false;
  }

  async function on_search_list_item_input() {
    filter_items();
  }

  async function on_new_list_item_keydown(event) {
    if (event.key === "Enter") {
      add_item(new_list_item_name);
    }
  }

  async function on_new_list_item_add_button_click() {
    add_item(new_list_item_name);
  }

  function on_sort_change(column_key) {
    console.log("on_sort_change", column_key);

    if (sort.column === column_key) {
      sort = {
        column: column_key,
        direction: sort.direction === "asc" ? "desc" : "asc"
      };
    } else {
      sort = { column: column_key, direction: "asc" };
    }

    filtered_list_items = filtered_list_items.sort(list_sorter);
  }

  async function add_item(item_name) {
    validate_new_item();

    if (is_new_list_item_name_valid) {
      const list_item = await list.create(
        { name: item_name },
        { strategy: ["backend"] }
      );
      list_items = [...list_items, list_item];
      new_list_item_name = "";
      filter_items();
    }

    message.set({
      intent: "success",
      body: `${item_name} has been added to the ${type
        .charAt(0)
        .toUpperCase()}${name.slice(1)} list`
    });
  }

  async function filter_items() {
    filtered_list_items = list_items
      .filter((item) => item.name.match(new RegExp(search_list_item_name, "i")))
      .sort(list_sorter);
  }

  async function remove_item(event, item) {
    const confirm_remove = await Dialog.confirm({
      message: `Are you sure you wish to remove ${name} ${item.name}?`,
      title: "Confirm Removal",
      type: "is-danger",
      confirmText: "Yes, Remove",
      cancelText: "No, Don't Remove"
    });

    if (confirm_remove) {
      await list.delete(item.uuid, { strategy: ["backend"] });
      const index = list_items.findIndex((test_item) => test_item === item);
      list_items.splice(index, 1);
      filter_items();

      message.set({
        intent: "danger",
        body: `${item.name} has been removed from the ${type
          .charAt(0)
          .toUpperCase()}${name.slice(1)} list`
      });
    }
  }

  function validate_new_item() {
    show_errors = true;

    new_list_item_name = new_list_item_name.trim();

    is_new_list_item_name_valid =
      new_list_item_name.length > 0 &&
      !list_items.find((item) => item.name === new_list_item_name);
  }
</script>

<style>
/* Make the control bar above the list of items have the same outside
 * padding as the table containing them.  Yes, I am picky.
 */

.mdl-list-control {
  padding: 0.5em 0.75em;
}

th:nth-child(1) {
  width: 40%;
}

th:nth-child(2) {
  width: 30%;
}

th:nth-child(3) {
  width: 30%;
}

/* Our table headers are clickable, so let's indicate as such. */

th {
  cursor: pointer;
}

/* We're using the level component from Bulma to align table headers
 * with the sorting arrows, but it only applies to block objects, so
 * we need sort links to inherit the display model from their level
 * item containers.
 */

.level-item span {
  display: inherit;
}

/* And yet the icons still don't align with the text quite right, so
 * let's push them down a tiny bit.  I hate to do this, but it looks
 * better this way.
 */

span.mdl-sorter {
  position: relative;
  top: 0.075em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2xpc3Quc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTs7RUFFRTs7QUFFRjtFQUNFLHFCQUFxQjtBQUN2Qjs7QUFFQTtFQUNFLFVBQVU7QUFDWjs7QUFFQTtFQUNFLFVBQVU7QUFDWjs7QUFFQTtFQUNFLFVBQVU7QUFDWjs7QUFFQSxnRUFBZ0U7O0FBRWhFO0VBQ0UsZUFBZTtBQUNqQjs7QUFFQTs7OztFQUlFOztBQUVGO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBOzs7RUFHRTs7QUFFRjtFQUNFLGtCQUFrQjtFQUNsQixZQUFZO0FBQ2QiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbGlzdC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi8qIE1ha2UgdGhlIGNvbnRyb2wgYmFyIGFib3ZlIHRoZSBsaXN0IG9mIGl0ZW1zIGhhdmUgdGhlIHNhbWUgb3V0c2lkZVxuICogcGFkZGluZyBhcyB0aGUgdGFibGUgY29udGFpbmluZyB0aGVtLiAgWWVzLCBJIGFtIHBpY2t5LlxuICovXG5cbi5tZGwtbGlzdC1jb250cm9sIHtcbiAgcGFkZGluZzogMC41ZW0gMC43NWVtO1xufVxuXG50aDpudGgtY2hpbGQoMSkge1xuICB3aWR0aDogNDAlO1xufVxuXG50aDpudGgtY2hpbGQoMikge1xuICB3aWR0aDogMzAlO1xufVxuXG50aDpudGgtY2hpbGQoMykge1xuICB3aWR0aDogMzAlO1xufVxuXG4vKiBPdXIgdGFibGUgaGVhZGVycyBhcmUgY2xpY2thYmxlLCBzbyBsZXQncyBpbmRpY2F0ZSBhcyBzdWNoLiAqL1xuXG50aCB7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cblxuLyogV2UncmUgdXNpbmcgdGhlIGxldmVsIGNvbXBvbmVudCBmcm9tIEJ1bG1hIHRvIGFsaWduIHRhYmxlIGhlYWRlcnNcbiAqIHdpdGggdGhlIHNvcnRpbmcgYXJyb3dzLCBidXQgaXQgb25seSBhcHBsaWVzIHRvIGJsb2NrIG9iamVjdHMsIHNvXG4gKiB3ZSBuZWVkIHNvcnQgbGlua3MgdG8gaW5oZXJpdCB0aGUgZGlzcGxheSBtb2RlbCBmcm9tIHRoZWlyIGxldmVsXG4gKiBpdGVtIGNvbnRhaW5lcnMuXG4gKi9cblxuLmxldmVsLWl0ZW0gc3BhbiB7XG4gIGRpc3BsYXk6IGluaGVyaXQ7XG59XG5cbi8qIEFuZCB5ZXQgdGhlIGljb25zIHN0aWxsIGRvbid0IGFsaWduIHdpdGggdGhlIHRleHQgcXVpdGUgcmlnaHQsIHNvXG4gKiBsZXQncyBwdXNoIHRoZW0gZG93biBhIHRpbnkgYml0LiAgSSBoYXRlIHRvIGRvIHRoaXMsIGJ1dCBpdCBsb29rc1xuICogYmV0dGVyIHRoaXMgd2F5LlxuICovXG5cbnNwYW4ubWRsLXNvcnRlciB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgdG9wOiAwLjA3NWVtO1xufVxuIl19 */</style>

<div class="columns mdl-list-control">
  <div class="column is-half">
    <div class="field">
      <div class="control has-icons-left">
        <input
          class="input"
          type="text"
          placeholder="Search {name}s by name"
          bind:value={search_list_item_name}
          on:input={on_search_list_item_input} />
        <span class="icon is-small is-left">
          <Icon icon={faSearch} />
        </span>
      </div>
    </div>
  </div>

  <div class="column is-half">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field has-addons">
          <div class="control has-icons-left is-expanded">
            <input
              class="input is-fullwidth"
              type="text"
              placeholder="New {name} name"
              disabled={!$heartbeat}
              bind:value={new_list_item_name}
              on:keydown={on_new_list_item_keydown} />
            <span class="icon is-small is-left">
              <Icon icon={faPlusCircle} />
            </span>
            <p
              class="help is-danger"
              class:is-invisible={!show_errors || is_new_list_item_name_valid}>
              Name is required and must be unique
            </p>
          </div>
          <div class="control">
            <button
              class="button is-primary"
              disabled={!$heartbeat}
              on:click={on_new_list_item_add_button_click}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table class="table is-fullwidth is-hoverable">
  <thead>
    <tr>
      <th class="has-text-link">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <span on:click={() => on_sort_change("name")}>Name</span>
            </div>
            <div class="level-item">
              <span
                class="mdl-sorter"
                class:has-text-grey-lighter={sort.column !== "name"}
                on:click={() => on_sort_change("name")}>
                <Icon
                  icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
              </span>
            </div>
          </div>
        </div>
      </th>
      <th class="has-text-link is-hidden-handset">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <span on:click={() => on_sort_change("date_created")}>
                Date/Time Added
              </span>
            </div>
            <div class="level-item">
              <span
                class="mdl-sorter"
                class:has-text-grey-lighter={sort.column !== "date_created"}
                on:click={() => on_sort_change("date_created")}>
                <Icon
                  icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
              </span>
            </div>
          </div>
        </div>
      </th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#if loading}
      <tr>
        <td colspan="3">
          <div class="button is-fullwidth is-light is-loading" />
        </td>
      </tr>
    {:else}
      {#each filtered_list_items as item (item.uuid)}
        <tr>
          <td>{item.name}</td>
          <td class="is-hidden-handset">
            {DateTime.format_american_date_and_time(item.date_created)}
          </td>
          <td class="is-button-column">
            <button
              class="button is-danger"
              disabled={!$heartbeat}
              on:click={(event) => remove_item(event, item)}>
              Remove
            </button>
          </td>
        </tr>
      {/each}
    {/if}
  </tbody>
</table>
