const section = {
  name: "Personal Safety",
  id: "ps1",
  items: [
    ["Hard Hat", 10],
    ["Safety Glasses", 20],
    ["Hearing Protection", 30],
    ["Gloves", 40],
    ["Safety Footwear", 50],
    ["Harness", 60],
    ["Lanyard", 70],
    ["Anchor", 80]
  ]
};
exports.section = section;
