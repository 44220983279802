<script>
  import { getContext, onMount } from "svelte";
  import { Tabs, Tab } from "svelma";
  import List from "mdlui/components/list";
  import MaterialManagement from "mdlui/components/material-management";
  import TimeTypeManagement from "mdlui/components/time-type-management";
  import UserManagement from "mdlui/components/user-management";

  const backnav = getContext("backnav");
  const history = getContext("history");
  const heartbeat = getContext("heartbeat");

  onMount(on_mount);

  function on_mount() {
    backnav.set($history.previous ? $history.previous.path : "/");
  }
</script>

<h1 class="title">Settings</h1>

<div class="message is-danger" class:is-hidden={$heartbeat}>
  <div class="message-body">
    You must be connected to the Internet to change Settings.
  </div>
</div>

<Tabs>
  <Tab label="Drills">
    <List type="drill" name="drill" />
  </Tab>
  <Tab label="Subdrills">
    <List type="subdrill" name="subdrill" />
  </Tab>
  <Tab label="Hole Sizes">
    <List type="hole_size" name="hole size" />
  </Tab>
  <Tab label="Materials">
    <MaterialManagement />
  </Tab>
  <Tab label="Time Types">
    <TimeTypeManagement />
  </Tab>
  <Tab label="Users">
    <UserManagement />
  </Tab>
  <Tab label="Vehicles">
    <List type="vehicle" name="vehicle" />
  </Tab>
</Tabs>
