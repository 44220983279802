<script>
  import Icon from "fa-svelte";

  export let label;
  export let icon;
  export let maxlength;
  export let characters;
  export let placeholder;
  export let invalid = false;
  export let invalid_text = false;
  export let readonly = false;
  export let value = "";

  let acceptable_character_list;

  $: acceptable_character_list = characters ? Array.from(characters) : null;

  function on_input_text_keydown(event) {
    if (
      characters &&
      !event.ctrlKey &&
      !event.metaKey &&
      event.keyCode >= 32 &&
      event.keyCode !== 46 &&
      (event.keyCode < 35 || event.keyCode > 40) &&
      !characters.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  function on_input_text_paste(event) {
    if (acceptable_character_list) {
      const text = event.clipboardData.getData("text");
      const index_of_first_bad_character = Array.from(text).findIndex(
        (c) => !acceptable_character_list.includes(c)
      );

      if (index_of_first_bad_character >= 0) {
        value = text.slice(0, index_of_first_bad_character);

        event.preventDefault();
      }
    }
  }
</script>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field">
      <div class="control {icon ? "has-icons-left" : ""}">
        <input
          class="input"
          type="text"
          {maxlength}
          {placeholder}
          {readonly}
          on:keydown={on_input_text_keydown}
          on:paste={on_input_text_paste}
          bind:value />
        {#if icon}
          <span class="icon is-small is-left">
            <Icon {icon} />
          </span>
        {/if}
        <p class="help is-danger" class:is-hidden={!invalid || !invalid_text}>
          {invalid_text}
        </p>
      </div>
    </div>
  </div>
</div>
