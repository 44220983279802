const moment = require("moment");

const basic = require("./safety/basic");
const blaster_after = require("./safety/blaster_after");
const driller = require("./safety/driller");
const driller_after = require("./safety/driller_after");
const driver = require("./safety/driver");
const trucker = require("./safety/trucker");
const powder = require("./safety/powder");

class SafetyChecklist {
  constructor() {
    const self = this;
    return self;
  }

  static sheet_types() {
    const types = [
      {
        type: "",
        name: "None"
      },
      {
        type: "driller",
        name: "Driller"
      },
      {
        type: "driller_after",
        name: "Driller Clock Out"
      },
      {
        type: "trucker",
        name: "Trucker"
      },
      {
        type: "driver",
        name: "Driver"
      },
      {
        type: "blaster_after",
        name: "Blaster Clock Out"
      },
      {
        type: "basic",
        name: "Basic"
      },
      {
        type: "powder",
        name: "Powder Truck Driver"
      }
    ];
    return types;
  }

  static raw(type) {
    let sheet = null;
    if (type === "driver") {
      sheet = driver.sheet;
    } else if (type === "trucker") {
      sheet = trucker.sheet;
    } else if (type === "driller") {
      sheet = driller.sheet;
    } else if (type === "blaster_after") {
      sheet = blaster_after.sheet;
    } else if (type === "driller_after") {
      sheet = driller_after.sheet;
    } else if (type === "basic") {
      sheet = basic.sheet;
    } else if (type === "powder") {
      sheet = powder.sheet;
    }
    return sheet;
  }

  static question_type(id = "", _qid = "") {
    let qid = _qid;
    if (!qid) {
      [, , qid] = id.split("-");
    }
    let type = null;
    if (typeof qid === "number") {
      type = "std";
    } else if (qid.match(/^\d/)) {
      type = "std";
    } else if (qid.match("^t")) {
      type = "text";
    } else if (qid.match("^n")) {
      type = "id";
    }
    return type;
  }

  static entries(type, return_indexed = false) {
    const self = this;
    const sheet = self.raw(type);
    if (sheet === null) {
      return null;
    }
    const sheet_id = sheet.id;
    const items = [];
    const sections = {};
    const indexed = {};
    const json = {
      id: sheet_id,
      name: sheet.name,
      sections,
      items,
      indexed
    };

    sheet.sections.forEach((section) => {
      const section_id = section.id;
      json.sections[section_id] = section.name;
      const base_id = `${sheet_id}-${section_id}`;
      section.items.forEach((item_array) => {
        const [question, question_id] = item_array;
        const item_id = `${base_id}-${question_id}`;
        indexed[item_id] = null;
        const _question_type = self.question_type(null, question_id);
        items[item_id] = {
          section_name: section.name,
          item: question,
          type: _question_type
        };
      });
    });
    if (return_indexed) {
      return indexed;
    }
    return json;
  }

  static indexed(type) {
    const self = this;
    const indexed = true;
    return self.entries(type, indexed);
  }

  static now() {
    return moment().toISOString();
  }

  static set_answer(in_item, in_answer) {
    const self = this;
    const item = { ...in_item } || {}; // lets at least make a copy
    const now = self.now();

    let date_created = now;
    let answer = null;
    if (in_answer) {
      answer = in_answer.answer || in_answer || null;
      date_created = in_answer.date_created || now;
    }

    if (item.date_created) {
      ({ date_created } = item);
    }
    return { answer, date_updated: now, date_created };
  }
}

module.exports = SafetyChecklist;
