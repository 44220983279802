import Model from "mdlui/model";

class DrillerListModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/driller" },
        indexed: { indexed, store: "list.driller" }
      }
    });
  }
}

export default DrillerListModel;
