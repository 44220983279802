<script>
  import { getContext, onMount, onDestroy } from "svelte";
  import naturalCompare from "natural-compare";
  import Icon from "fa-svelte";
  import DateTime from "mdlui/datetime";
  import Radio from "mdlui/components/forms/radio";
  import {
    faEdit,
    faFilter,
    faFlag,
    faSearch,
    faCaretSquareUp,
    faCaretSquareDown,
    faClock,
    faTh
  } from "@fortawesome/free-solid-svg-icons";

  import LogViewModal from "mdlui/modals/logs/view";
  import LogEditModal from "mdlui/modals/logs/edit";

  const models = getContext("models");
  const message = getContext("message");
  const heartbeat = getContext("heartbeat");
  const list_is_outdated = getContext("list_is_outdated");
  const page = getContext("page");

  let is_log_view_modal_active = false;
  let is_log_edit_modal_active = false;
  let heartbeat_unsubscribe;
  let list_is_outdated_unsubscribe;
  let drill_log;
  let logs = [];
  let filtered_logs = [];
  let loading;
  let search_text = "";
  let search_regex;
  let filter;
  let sort = { column: "job_number", direction: "asc" };

  const filters = [
    {
      name: "All",
      value: "All",
      predicate: () => true
    },
    {
      name: "Active",
      value: "Active",
      predicate: (log) =>
        log.suspended !== true &&
        ["Draft", "Ready for Job", "In Progress", "Requires Rework"].includes(
          log.status
        )
    },
    {
      name: "Complete",
      value: "Complete",
      predicate: (log) => log.status === "Complete"
    },
    {
      name: "Billed",
      value: "Billed",
      predicate: (log) => log.status === "Billed"
    },
    {
      name: "Suspended",
      value: "Suspended",
      predicate: (log) => log.suspended
    }
  ];

  const columns = [
    ["Job Number", "job_number", false],
    ["Site Name", "site_name", false],
    ["Status", "status", true],
    ["Date Created", "date_created", true]
  ];

  const log_sorter = (a, b) => {
    const a_val = a[sort.column] || "";
    const b_val = b[sort.column] || "";

    return sort.direction === "asc"
      ? naturalCompare(a_val, b_val)
      : naturalCompare(b_val, a_val);
  };

  onMount(on_mount);
  onDestroy(on_destroy);

  function on_mount() {
    heartbeat_unsubscribe = heartbeat.subscribe(load);
    list_is_outdated_unsubscribe = list_is_outdated.subscribe((reload) => {
      if (reload) {
        load();
      }
    });
  }

  function on_destroy() {
    heartbeat_unsubscribe();
    list_is_outdated_unsubscribe();
  }

  function on_filter_change({ detail: { item } }) {
    filter = item;
    filter_logs();
  }

  function on_sort_change(column_key) {
    console.log("on_sort_change", column_key);

    if (sort.column === column_key) {
      sort = {
        column: column_key,
        direction: sort.direction === "asc" ? "desc" : "asc"
      };
    } else {
      sort = { column: column_key, direction: "asc" };
    }

    filtered_logs = filtered_logs.sort(log_sorter);
  }

  function on_search_text_input() {
    search_regex = new RegExp(search_text, "i");
    filter_logs();
  }

  function on_drill_log_row_click(selected_drill_log) {
    drill_log = selected_drill_log;
    is_log_view_modal_active = true;
  }

  function on_drill_log_graph_button_click(selected_drill_log) {
    page.show(
      `/logs/${selected_drill_log.uuid}/graph/${selected_drill_log.graphs[0]}`
    );
  }

  function on_drill_log_edit_button_click(selected_drill_log) {
    drill_log = selected_drill_log;
    is_log_edit_modal_active = true;
  }

  function on_drill_log_time_button_click(selected_drill_log) {
    page.show(`/logs/${selected_drill_log.uuid}/time`);
  }

  function on_log_modal_closed({ detail: { modified } }) {
    if (modified) {
      load();
    } else {
      message.set(null);
    }
  }

  async function load() {
    loading = true;
    // eslint-disable-next-line no-multi-assign
    logs = filtered_logs = await models.drill_log.retrieve_all();
    logs.sort(log_sorter);
    loading = false;
    list_is_outdated.set(false);
  }

  function filter_logs() {
    filtered_logs = logs
      .filter(
        (log) =>
          (log.job_number && log.job_number.match(search_regex)) ||
          (log.site_name && log.site_name.match(search_regex))
      )
      .filter((log) => (filter ? filter.predicate(log) : true))
      .sort(log_sorter);
  }
</script>

<style>
/* Our table headers and rows are clickable, so let's indicate as such. */

button,
th,
td {
  cursor: pointer;
}

/* We're using the level component from Bulma to align table headers
 * with the sorting arrows, but it only applies to block objects, so
 * we need sort links to inherit the display model from their level
 * item containers.
 */

.level-item span {
  display: inherit;
}

/* And yet the icons still don't align with the text quite right, so
 * let's push them down a tiny bit.  I hate to do this, but it looks
 * better this way.
 */

span.mdl-sorter {
  position: relative;
  top: 0.075em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2xvZ3MvbGlzdC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBLHlFQUF5RTs7QUFFekU7OztFQUdFLGVBQWU7QUFDakI7O0FBRUE7Ozs7RUFJRTs7QUFFRjtFQUNFLGdCQUFnQjtBQUNsQjs7QUFFQTs7O0VBR0U7O0FBRUY7RUFDRSxrQkFBa0I7RUFDbEIsWUFBWTtBQUNkIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2xvZ3MvbGlzdC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi8qIE91ciB0YWJsZSBoZWFkZXJzIGFuZCByb3dzIGFyZSBjbGlja2FibGUsIHNvIGxldCdzIGluZGljYXRlIGFzIHN1Y2guICovXG5cbmJ1dHRvbixcbnRoLFxudGQge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi8qIFdlJ3JlIHVzaW5nIHRoZSBsZXZlbCBjb21wb25lbnQgZnJvbSBCdWxtYSB0byBhbGlnbiB0YWJsZSBoZWFkZXJzXG4gKiB3aXRoIHRoZSBzb3J0aW5nIGFycm93cywgYnV0IGl0IG9ubHkgYXBwbGllcyB0byBibG9jayBvYmplY3RzLCBzb1xuICogd2UgbmVlZCBzb3J0IGxpbmtzIHRvIGluaGVyaXQgdGhlIGRpc3BsYXkgbW9kZWwgZnJvbSB0aGVpciBsZXZlbFxuICogaXRlbSBjb250YWluZXJzLlxuICovXG5cbi5sZXZlbC1pdGVtIHNwYW4ge1xuICBkaXNwbGF5OiBpbmhlcml0O1xufVxuXG4vKiBBbmQgeWV0IHRoZSBpY29ucyBzdGlsbCBkb24ndCBhbGlnbiB3aXRoIHRoZSB0ZXh0IHF1aXRlIHJpZ2h0LCBzb1xuICogbGV0J3MgcHVzaCB0aGVtIGRvd24gYSB0aW55IGJpdC4gIEkgaGF0ZSB0byBkbyB0aGlzLCBidXQgaXQgbG9va3NcbiAqIGJldHRlciB0aGlzIHdheS5cbiAqL1xuXG5zcGFuLm1kbC1zb3J0ZXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHRvcDogMC4wNzVlbTtcbn1cbiJdfQ== */</style>

<LogViewModal
  {drill_log}
  bind:active={is_log_view_modal_active}
  on:close={on_log_modal_closed} />

<LogEditModal
  {drill_log}
  bind:active={is_log_edit_modal_active}
  on:close={on_log_modal_closed} />

<div class="field">
  <div class="field-body">
    <div class="field has-addons">
      <div class="control">
        <div class="buttons">
          <div class="button is-static is-light has-icons-left">
            <span class="icon is-small is-left">
              <Icon icon={faFilter} />
            </span>
            <span>Filters</span>
          </div>
        </div>
      </div>
      <div class="control">
        <Radio data={filters} on:change={on_filter_change} />
      </div>
    </div>
  </div>
</div>

<div class="field">
  <div class="field-body">
    <div class="field has-addons">
      <div class="control is-expanded has-icons-left">
        <input
          class="input"
          type="text"
          placeholder={"Enter the job number or site name (or part of one) you'd like to search for…"}
          bind:value={search_text}
          on:input={on_search_text_input} />
        <span class="icon is-small is-left">
          <Icon icon={faSearch} />
        </span>
      </div>
    </div>
  </div>
</div>

<table class="table is-fullwidth is-hoverable">
  <thead>
    <tr>
      {#each columns as [column_name, column_key, hidden_on_handset]}
        <th class="has-text-link" class:is-hidden-handset={hidden_on_handset}>
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <span on:click={() => on_sort_change(column_key)}>
                  {column_name}
                </span>
              </div>
              <div class="level-item">
                {#if sort.column === column_key}
                  <span
                    class="mdl-sorter"
                    on:click={() => on_sort_change(column_key)}>
                    <Icon
                      icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
                  </span>
                {:else}
                  <span
                    class="mdl-sorter has-text-grey-lighter"
                    on:click={() => on_sort_change(column_key)}>
                    <Icon class="is-disabled" icon={faCaretSquareUp} />
                  </span>
                {/if}
              </div>
            </div>
          </div>
        </th>
      {/each}
      <td />
    </tr>
  </thead>
  <tbody>
    {#if loading}
      <tr>
        <td colspan={columns.length + 1}>
          <div class="button is-fullwidth is-light is-loading" />
        </td>
      </tr>
    {:else}
      {#each filtered_logs as log}
        <tr
          class:has-background-light={log.suspended}
          on:click={() => on_drill_log_row_click(log)}>
          <td>
            <a on:click={() => on_drill_log_row_click(log)}>
              {log.job_number || ""}
            </a>
          </td>
          <td>{log.site_name || ""}</td>
          <td class="is-hidden-handset">
            <div class="level">
              <div class="level-left">{log.status || ""}</div>
              {#if log.suspended}
                <div
                  class="level-right has-tooltip-top has-tooltip-arrow"
                  data-tooltip="Job has been suspended.">
                  <Icon class="has-text-danger" icon={faFlag} />
                </div>
              {/if}
            </div>
          </td>
          <td class="is-hidden-handset">
            {DateTime.format_american_date(log.date_created)}
          </td>
          <td class="is-button-column">
            <div class="buttons is-right">
              <div class="field is-grouped">
                {#if log.graphs && log.graphs[0]}
                  <button
                    class="button is-small"
                    on:click={() => on_drill_log_graph_button_click(log)}>
                    <Icon icon={faTh} />
                  </button>
                {/if}
                <div
                  class="has-tooltip-left has-tooltip-arrow"
                  data-tooltip={!$heartbeat ? "Internet connectivity required to edit job information" : null}>
                  <button
                    class="button is-small"
                    disabled={!$heartbeat}
                    on:click|stopPropagation={() => on_drill_log_edit_button_click(log)}>
                    <Icon icon={faEdit} />
                  </button>
                </div>
                <button
                  class="button is-small"
                  on:click|stopPropagation={() => on_drill_log_time_button_click(log)}>
                  <Icon icon={faClock} />
                </button>
              </div>
            </div>
          </td>
        </tr>
      {/each}
    {/if}
  </tbody>
</table>
