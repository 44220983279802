<script>
  import { getContext, createEventDispatcher, tick, onDestroy } from "svelte";
  import CancelButton from "mdlui/components/forms/cancel-button";
  import DrillLogForm from "mdlui/components/logs/form";

  export let active;
  export let drill_log;

  $: if (active === true) {
    on_open();
  }

  const message = getContext("message");
  const models = getContext("models");
  const heartbeat = getContext("heartbeat");
  const sync = getContext("sync");
  const dispatch = createEventDispatcher();

  const cancel_prompt_message =
    "Are you sure you want to cancel the drill log edit?  Any changes you have made will not be saved.";
  // const log_uuid = params.get("log_uuid");

  let content;
  let modified = false;
  let is_saving = false;
  let validate;
  let initial_drill_log_value;

  async function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    initial_drill_log_value = JSON.stringify(drill_log);
    content.scrollTo(0, 0);
  }

  function on_save_button_click() {
    // Validation isn't required when saving a draft.

    is_saving = true;
    if (drill_log.status === "Draft" || validate()) {
      modified = true;
      save({ via_button: 1 });
    }
  }

  function on_save_and_send_to_drill_team_button_click() {
    if (validate()) {
      modified = true;
      save({ via_button: 2, status: "Ready for Job" });
    }
  }

  async function save({ via_button, status = drill_log.status }) {
    is_saving = { via_button };

    // Don't save blank patterns.

    const patterns = drill_log.patterns.filter(
      (pattern) =>
        pattern.pattern ||
        pattern.hole_size ||
        pattern.drill ||
        pattern.subdrill
    );

    await models.drill_log.update(
      drill_log.uuid,
      { ...drill_log, status, patterns },
      { strategy: ["backend"] }
    );

    await sync.synchronize_drill_log(drill_log);
    await sync.update_sync_data();

    message.set({
      intent: "success",
      body: `Changes successfully saved for Job: ${drill_log.job_number} at Site: ${drill_log.site_name}`
    });

    close();

    is_saving = false;
  }

  const is_modified = () =>
    JSON.stringify(drill_log) !== initial_drill_log_value;

  function reset() {
    modified = is_modified();
    close();
  }

  function close() {
    active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close", { modified });
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<style>
.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvbG9ncy9lZGl0LnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSxlQUFlO0VBQ2YsV0FBVztFQUNYLFlBQVk7RUFDWixNQUFNO0FBQ1I7O0FBRUE7RUFDRSxhQUFhO0VBQ2IsWUFBWTtFQUNaLG1CQUFtQjtFQUNuQix1QkFBdUI7RUFDdkIsbUJBQW1CO0FBQ3JCIiwiZmlsZSI6InNyYy9tb2RhbHMvbG9ncy9lZGl0LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLm1kbC1tb2RhbC1uYXZpZ2F0aW9uIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB6LWluZGV4OiA1MDtcbiAgd2lkdGg6IDEwMHZ3O1xuICB0b3A6IDA7XG59XG5cbi5tZGwtbmF2YmFyLXRpdGxlIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1ncm93OiAxO1xuICBtaW4taGVpZ2h0OiAzLjI1cmVtO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cbiJdfQ== */</style>

<div class="modal mdl-modal" class:is-active={active}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <CancelButton
          do_we_prompt={is_modified}
          close_callback={reset}
          message={cancel_prompt_message}
          bind:disabled={is_saving}
          button_type="back" />
        <div class="mdl-navbar-title">
          <h1 class="subtitle">Edit Drill Log</h1>
        </div>

      </div>

    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">Edit Drill Log</h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      {#if drill_log === undefined}
        <div class="button is-fullwidth is-light is-loading" />
      {/if}

      {#if drill_log === null}
        <div class="message is-danger">
          <div class="message-body">Invalid Drill Log ID.</div>
        </div>
      {/if}

      {#if drill_log}
        <DrillLogForm bind:drill_log_info={drill_log} bind:validate />
      {/if}

      <div class="field is-horizontal">
        <div class="field-label" />

        <div class="field-body">
          <div class="field is-grouped">
            <div class="control">
              <button
                class="button"
                class:is-loading={is_saving && is_saving.via_button === 1}
                disabled={!$heartbeat || is_saving}
                on:click={on_save_button_click}>
                Save
              </button>
              {#if drill_log}
                {#if drill_log.status === "Draft"}
                  <button
                    class="button"
                    class:is-loading={is_saving && is_saving.via_button === 2}
                    disabled={!$heartbeat || is_saving}
                    on:click={on_save_and_send_to_drill_team_button_click}>
                    Save and Send to Drill Team
                  </button>
                {/if}
                <CancelButton
                  do_we_prompt={is_modified}
                  close_callback={reset}
                  message={cancel_prompt_message}
                  bind:disabled={is_saving}
                  classes="is-danger" />
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {#if drill_log}
    <CancelButton
      do_we_prompt={is_modified}
      close_callback={reset}
      message={cancel_prompt_message}
      bind:disabled={is_saving}
      button_type="close" />
  {/if}
</div>
