import Model from "mdlui/model";

class GraphTimestampModel extends Model {
  constructor({ models, indexed }) {
    super({
      models,
      storage: {
        indexed: { indexed, store: "timestamp.graph" }
      }
    });
  }
}

export default GraphTimestampModel;
