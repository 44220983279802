<script>
  import { onMount, afterUpdate } from "svelte";
  import BulmaTagInput from "@creativebulma/bulma-tagsinput";

  export let label;
  export let placeholder;
  export let no_results_text;
  export let data;
  export let invalid = false;
  export let invalid_text = false;
  export let selections = [];

  // Generate a short random ID to use as the element ID of the target
  // input element.  We need a unique identifier when instantiating the
  // BulmaTagInput class.

  const id = new Array(8)
    .fill(0)
    .map(() => String.fromCharCode(97 + parseInt(Math.random() * 26, 10)))
    .join("");

  // The BulmaTagInput component does not show the entire list of items
  // when no input is provided.  I think it's expecting to be used as a
  // dynamic autocomplete search for large server-side data sets rather
  // than smaller client-side data sets.  I tried to get it to populate
  // the drop-down with the full list of possibilities upon first click
  // and it refuses to do so unless input is provided.
  //
  // The class doesn't provide any public methods for manipulating list
  // items, so calling these internal method was the only way I had any
  // success getting it functioning.
  //
  // It does work out of the box if you use a select box as the original
  // form element, but there's an ugly flicker in the UI as the browser
  // renders the original select box before BulmaTagInput takes over and
  // hides it.  I wasn't happy with the way it looked.  I tried to hide
  // the select box with CSS, but Svelte's CSS compiler fights with the
  // BulmaTagInput component over control of the class list.  The input
  // box is better, IMNSHO, even if I am (ab)using the interface.

  let bti;
  let set;

  $: set = new Set(selections);

  $: if (bti && Array.isArray(data)) {
    on_data_change();
  }

  onMount(on_mount);

  afterUpdate(after_update);

  function after_update() {
    let clear_action;
    if (selections.length === 0) {
      clear_action = bti && bti.removeAll();
    }
    return clear_action;
  }

  async function on_mount() {
    bti = new BulmaTagInput(`#${id}`, {
      source: () => data,
      itemText: "name",
      itemValue: "uuid",
      tagClass: "is-info",
      noResultsLabel: no_results_text,
      caseSensitive: false,
      searchMinChars: 0,
      selectable: false
    });

    bti.on("after.add", on_item_added);
    bti.on("after.remove", on_item_removed);
  }

  function on_data_change() {
    // eslint-disable-next-line no-underscore-dangle
    bti._emptyDropdown();
    data
      .filter((item) => item.available)
      .forEach((item) => {
        // eslint-disable-next-line no-underscore-dangle
        bti._createDropdownItem({ text: item.name, value: item.uuid });
      });
    selections
      .map((uuid) => data.find((item) => item.uuid === uuid))
      .filter((item) => item)
      .forEach((item) => bti.add(item, true));
  }

  function on_item_added({ item }) {
    set.add(item.uuid);
    selections = [...set.values()];
  }

  function on_item_removed(item) {
    set.delete(item.uuid);
    selections = [...set.values()];
  }
</script>

<style type="text/scss">@import url(~@creativebulma/bulma-tagsinput/dist/css/bulma-tagsinput.min.css);
/* The typical padding for a field is 0.375em, but we need a bit more
 * since we're putting some additional padding around the tags. */
div.field-label {
  padding-top: 0.625em; }

/* Svelte's compiler will remove any CSS selectors that it determines
 * don't apply to the scoped component.  This makes it impossible to
 * style external controls like BulmaTagsInput.  Fortunately, we can
 * trick Svelte into thinking the style applies by using the :global()
 * selector.  Gross, but it looks nicer.
 */
div.field :global(.tag) {
  margin: 0.5em 0 0.5em 0.5em; }

/* The BulmaTagInput component doesn't include styles for is-danger,
 * so we need to provide them ourselves.  We need the color variable
 * from Bulma'a sass sources.
 *
 * The eslint-plugin-svelte3 plugin isn't capable of linting non-CSS
 * styles, so nothing in this style block is linted.  Svelte doesn't
 * allow more than one style block per component, either, so we can't
 * isolate this style.
 *
 * We're using the svelte3/ignore-styles configuration directive in
 * .eslintrc.js to skip linting of text/scss style blocks.  I'd like
 * to move this style out of this component, though.  Ideally, this
 * happens via an updated version of the BulmaTagInput package that
 * includes the is-danger styles.
 */
.field.is-danger :global(.tags-input) {
  border-color: #f14668; }
/*# sourceMappingURL=src/components/forms/tag-select.svelte.map */</style>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field" class:is-danger={invalid}>
      <input {id} class="input" {placeholder} />
      <p class="help is-danger" class:is-hidden={!invalid || !invalid_text}>
        {invalid_text}
      </p>
    </div>
  </div>
</div>
