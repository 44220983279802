<script>
  import cond from "lodash/cond";
  import stubTrue from "lodash/stubTrue";
  import constant from "lodash/constant";
  import { getContext, createEventDispatcher, tick, onDestroy } from "svelte";
  import { Dialog, Switch } from "svelma";
  import Icon from "fa-svelte";
  import LogInfo from "mdlui/components/logs/info";
  import {
    faCheckCircle,
    faChevronCircleLeft,
    faClock,
    faExclamationCircle,
    faPrint,
    faSync
  } from "@fortawesome/free-solid-svg-icons";

  export let active;
  export let drill_log;

  $: if (active === true) {
    on_open();
  }

  const models = getContext("models");
  const message = getContext("message");
  const heartbeat = getContext("heartbeat");
  const workflow = getContext("workflow");
  const page = getContext("page");
  const sync = getContext("sync");
  const user = getContext("get_user")();
  const dispatch = createEventDispatcher();

  const SYNC_RUNNING = 1;
  const SYNC_FAILED = 2;
  const SYNC_SUCCEEDED = 3;

  let content;
  let is_burger_selected = false;
  let status_transitions = [];
  let modified = false;
  let sync_status = null;
  let timestamp;

  // lodash.cond() and predicate-function pairs
  //
  // Our style guide and ESLint rules forbid nested ternaries and for
  // good reason: they're difficult to reason about due to the twisty
  // branching of flow.  I tried to disable the rule just to get this
  // change in, but I didn't have much success: eslint-plugin-svelte3
  // doesn't appear to have a way to disable rules in the markup.
  //
  // I could have written a function that implements the nested logic
  // with the typical control flow statements, but I wondered if lodash
  // offered some sort of coalescing function out of the box that was
  // easier to reason about than nested if/else blocks.  It turns out
  // that lodash.cond will do exactly what I want.
  //
  // The cond() function returns a function that evaluates an arbitrary
  // number of predicates over its argument.  The first predicate that
  // evaluates true will cause the entire function to return the value
  // of the paired function.  It's a functional programming equivalent
  // of switch/case.

  const tooltip_for_suspend_switch = cond([
    [
      ({ log }) => log.status === "Billed" && !log.suspended,
      constant("Suspend unavailable while in Billed status")
    ],
    [
      ({ heartbeat_ok }) => !heartbeat_ok,
      ({ log }) =>
        `Internet connectivity required to ${
          log.suspended ? "unsuspend" : "suspend"
        }`
    ],
    [stubTrue, constant(null)]
  ]);

  const tooltip_for_status_transition_button = cond([
    [
      ({ transition }) => !transition.available,
      ({ transition }) =>
        `Conditions required to change status: ${transition.available_reason}`
    ],
    [
      ({ heartbeat_ok }) => !heartbeat_ok,
      constant("Internet connectivity required to change status")
    ],
    [stubTrue, constant(null)]
  ]);

  async function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    sync_status = null;
    status_transitions = user.capabilities.includes("logs:workflow")
      ? workflow.get_status_transitions(drill_log)
      : [];

    try {
      timestamp = await models.timestamp.drill_log.retrieve(drill_log.uuid);
    } catch {
      timestamp = null;
    }

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    content.scrollTo(0, 0);
  }

  async function on_burger_selected() {
    is_burger_selected = !is_burger_selected;
  }

  async function on_modal_click() {
    is_burger_selected = false;
  }

  async function on_print_button_click() {
    page.show(`/logs/${drill_log.uuid}/print`);
  }

  async function on_suspend_toggle() {
    drill_log.suspended = !drill_log.suspended;

    const updated_drill_log = await models.drill_log.update(drill_log.uuid, {
      suspended: drill_log.suspended,
      date_updated: drill_log.date_updated
    });

    modified = true;
    drill_log = updated_drill_log;

    status_transitions = user.capabilities.includes("logs:workflow")
      ? workflow.get_status_transitions(drill_log)
      : [];
  }

  async function on_status_transition_button_click({ status }) {
    const confirm_status_change = await Dialog.confirm({
      message: `Are you sure you wish to move this job into ${status}?`,
      title: "Confirm Status Change",
      type: "is-danger",
      confirmText: "Yes",
      cancelText: "No"
    });

    if (confirm_status_change) {
      await models.drill_log.transition({ drill_log, status });
    }

    message.set({
      intent: "success",
      body: `Status changed for Job: ${drill_log.job_number} at Site: ${drill_log.site_name}`
    });

    modified = true;

    close();
  }

  function on_drill_log_time_button_click() {
    page.show(`/logs/${drill_log.uuid}/time`);
  }

  function on_back_button_click() {
    close();
  }

  function on_close_button_click() {
    close();
  }

  function on_modal_close_button_click() {
    close();
  }

  async function on_sync_button_click() {
    sync_status = SYNC_RUNNING;

    try {
      await sync.update_sync_data();

      const sync_result = await sync.synchronize_drill_log(drill_log);

      drill_log = sync_result.drill_log;
      timestamp = sync_result.timestamps.drill_log;
      sync_status = SYNC_SUCCEEDED;
    } catch {
      sync_status = SYNC_FAILED;
    }
  }

  function close() {
    active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close", { modified });
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<style>
.navbar-brand .button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvbG9ncy92aWV3LnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSxnQ0FBZ0M7RUFDaEMsaUNBQWlDO0FBQ25DOztBQUVBO0VBQ0UsZUFBZTtFQUNmLFdBQVc7RUFDWCxZQUFZO0VBQ1osTUFBTTtBQUNSOztBQUVBO0VBQ0UsYUFBYTtFQUNiLFlBQVk7RUFDWixtQkFBbUI7RUFDbkIsdUJBQXVCO0VBQ3ZCLG1CQUFtQjtBQUNyQiIsImZpbGUiOiJzcmMvbW9kYWxzL2xvZ3Mvdmlldy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5uYXZiYXItYnJhbmQgLmJ1dHRvbiB7XG4gIHBhZGRpbmctbGVmdDogY2FsYygwLjc1ZW0gLSAxcHgpO1xuICBwYWRkaW5nLXJpZ2h0OiBjYWxjKDAuNzVlbSAtIDFweCk7XG59XG5cbi5tZGwtbW9kYWwtbmF2aWdhdGlvbiB7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgei1pbmRleDogNTA7XG4gIHdpZHRoOiAxMDB2dztcbiAgdG9wOiAwO1xufVxuXG4ubWRsLW5hdmJhci10aXRsZSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZ3JvdzogMTtcbiAgbWluLWhlaWdodDogMy4yNXJlbTtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG4iXX0= */</style>

<div class="modal mdl-modal" class:is-active={active} on:click={on_modal_click}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <button
          class="button is-white is-medium"
          on:click={on_back_button_click}>
          <Icon icon={faChevronCircleLeft} />
        </button>

        <div class="mdl-navbar-title">
          <h1 class="subtitle">Drill Log</h1>
        </div>

        <span
          role="button"
          class="navbar-burger burger"
          class:is-active={is_burger_selected}
          class:is-invisible={status_transitions.filter((transition) => transition.available).length === 0}
          aria-label="menu"
          aria-expanded="false"
          data-target="mdl-log-view-navigation"
          on:click|stopPropagation={on_burger_selected}>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>

      <div
        id="mdl-log-view-navigation"
        class="navbar-menu"
        class:is-active={is_burger_selected}>
        <div class="navbar-end">
          {#each status_transitions as transition}
            {#if transition.available}
              <a
                class="navbar-item"
                on:click={() => on_status_transition_button_click(transition)}>
                {transition.name}
              </a>
            {/if}
          {/each}
        </div>
      </div>
    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">Drill Log</h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      {#if drill_log === undefined}
        <div class="button is-fullwidth is-light is-loading" />
      {/if}

      {#if drill_log === null}
        <div class="message is-danger">
          <div class="message-body">Invalid Drill Log ID.</div>
        </div>
      {/if}

      {#if drill_log}
        <LogInfo {drill_log} {timestamp} />
      {/if}

      <hr />

      <div class="level">
        <div class="level-left">
          {#if drill_log}
            {#if user.capabilities.includes("logs:update")}
              <div
                class="is-flex has-tooltip-right has-tooltip-arrow"
                data-tooltip={tooltip_for_suspend_switch({
                  log: drill_log,
                  heartbeat_ok: $heartbeat
                })}>
                <Switch
                  type="is-danger"
                  disabled={(drill_log.status === "Billed" && !drill_log.suspended) || !$heartbeat}
                  checked={drill_log.suspended}
                  on:click={on_suspend_toggle} />
              </div>
              {#if !drill_log.suspended}
                <span class="ml-1">Suspend Job</span>
              {/if}
            {/if}
            {#if drill_log.suspended}
              <div class="field is-horizontal">
                <span class="tag is-danger">Job Suspended</span>
              </div>
            {/if}
          {/if}
        </div>
        <div class="level-right">
          <div class="buttons is-right">
            <button class="button" on:click={on_print_button_click}>
              <span class="icon is-small is-left">
                <Icon icon={faPrint} />
              </span>
              <span>Print</span>
            </button>

            <div
              class="is-flex has-tooltip-top has-tooltip-arrow"
              data-tooltip={!$heartbeat ? "Internet connectivity required to sync" : null}>
              <button
                class="button"
                class:is-loading={sync_status === SYNC_RUNNING}
                class:is-success={sync_status === SYNC_SUCCEEDED}
                class:is-danger={sync_status === SYNC_FAILED}
                disabled={!$heartbeat || sync_status}
                on:click={on_sync_button_click}>
                <span class="icon is-small is-left">
                  {#if sync_status === SYNC_SUCCEEDED}
                    <Icon icon={faCheckCircle} />
                  {:else if sync_status === SYNC_FAILED}
                    <Icon icon={faExclamationCircle} />
                  {:else}
                    <Icon icon={faSync} />
                  {/if}
                </span>
                <span>Sync</span>
              </button>
            </div>

            {#each status_transitions as transition}
              <div
                class="is-flex has-tooltip-top has-tooltip-arrow
                has-tooltip-multiline"
                data-tooltip={tooltip_for_status_transition_button({
                  transition,
                  heartbeat_ok: $heartbeat
                })}>
                <button
                  class="button"
                  disabled={!(transition.available && $heartbeat)}
                  on:click={() => on_status_transition_button_click(transition)}>
                  {transition.name}
                </button>
              </div>
            {/each}

            <button
              class="button"
              on:click|stopPropagation={() => on_drill_log_time_button_click()}>
              <span class="icon is-small is-left">
                <Icon icon={faClock} />
              </span>
              <span>Log Time</span>
            </button>

            <button class="button" on:click={on_close_button_click}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    class="modal-close is-large"
    aria-label="close"
    on:click={on_modal_close_button_click} />
</div>
