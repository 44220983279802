<script>
  import { getContext } from "svelte";
  import DateTime from "mdlui/datetime";
  import Info from "mdlui/components/forms/info";

  export let drill_log;
  export let timestamp;

  const cache = getContext("cache");
</script>

<Info label="Job Number">{drill_log.job_number}</Info>
<Info label="Site Name">{drill_log.site_name}</Info>
<Info label="GPS Coordinates">{drill_log.gps_coordinates}</Info>
<Info label="Patterns">
  <ul>
    {#each drill_log.patterns as pattern}
      <li>
        {pattern.pattern} / {pattern.hole_size} / {pattern.drill}
        {#if pattern.subdrill}/ {pattern.subdrill}{/if}
      </li>
    {/each}
  </ul>
</Info>
<Info label="Drillers">
  {#if drill_log.assigned}
    <ul>
      {#each drill_log.assigned as driller_uuid}
        <li>{cache.lookups.drillers[driller_uuid].name}</li>
      {/each}
    </ul>
  {/if}
</Info>
<Info label="Status">{drill_log.status}</Info>
<Info label="Graphs">
  {#if drill_log.graphs}
    <ul>
      {#each drill_log.graphs as graph_uuid, graph_index}
        <li>
          <a href="/logs/{drill_log.uuid}/graph/{graph_uuid}">
            Graph {graph_index + 1} of {drill_log.graphs.length}
          </a>
        </li>
      {/each}
    </ul>
  {/if}
</Info>
<Info label="Date Created">
  {DateTime.format_american_date_and_time(drill_log.date_created)}
</Info>
<Info label="Created By">{drill_log.creator}</Info>
<Info label="Last Updated">
  {DateTime.format_american_date_and_time(drill_log.date_updated)}
</Info>
<Info label="Last Updated By">{drill_log.updator || drill_log.creator}</Info>
<Info label="Last Sync">
  {timestamp && timestamp.synced ? DateTime.format_american_date_and_time(timestamp.synced) : "Never"}
</Info>
<Info label="Notes">
  {#if drill_log.notes}
    <pre>{drill_log.notes}</pre>
  {/if}
</Info>
