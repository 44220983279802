<script>
  import CancelButton from "mdlui/components/forms/cancel-button";
  import { getContext, createEventDispatcher, tick, onDestroy } from "svelte";
  import DrillLogForm from "mdlui/components/logs/form";

  export let active;

  $: if (active === true) {
    on_open();
  }

  const message = getContext("message");
  const models = getContext("models");
  const heartbeat = getContext("heartbeat");
  const sync = getContext("sync");
  const dispatch = createEventDispatcher();

  const cancel_prompt_message =
    "Are you sure you want to cancel the drill log creation?  Your changes will not be saved.";

  // const log_uuid = params.get("log_uuid");

  let content;
  let modified = false;
  let drill_log_info = { patterns: [{}] };
  let is_saving = false;
  let show_errors = false;
  let validate;

  const do_we_prompt_on_cancel = () => true;

  async function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    content.scrollTo(0, 0);
  }

  function on_save_draft_button_click() {
    // Validation isn't required when saving a draft.

    drill_log_info.status = "Draft";
    modified = true;

    save({ via_button: 1 });
  }

  function on_send_to_drill_team_button_click() {
    if (validate()) {
      drill_log_info.status = "Ready for Job";
      modified = true;

      save({ via_button: 2 });
    }
  }

  async function save({ via_button }) {
    is_saving = { via_button };

    // Don't save blank patterns.

    const patterns = drill_log_info.patterns.filter(
      (pattern) =>
        pattern.pattern ||
        pattern.hole_size ||
        pattern.drill ||
        pattern.subdrill
    );

    const drill_log = await models.drill_log.create(
      { ...drill_log_info, patterns },
      { strategy: ["backend"], sync: { to: "indexed" } }
    );

    await Promise.all(
      drill_log.graphs.map((graph_uuid) =>
        models.graph.retrieve(graph_uuid, {
          strategy: ["backend"],
          sync: { to: "indexed" }
        })
      )
    );

    await sync.update_sync_data();

    message.set({
      intent: "success",
      body: `A new drill log for ${drill_log.site_name} was successfully created.`
    });

    close();

    is_saving = false;
  }

  function close() {
    active = false;
    show_errors = false;
    drill_log_info = { patterns: [{}], assigned: [] };

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close", { modified });
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<style>
.navbar-brand .button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvbG9ncy9jcmVhdGUuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGdDQUFnQztFQUNoQyxpQ0FBaUM7QUFDbkM7O0FBRUE7RUFDRSxlQUFlO0VBQ2YsV0FBVztFQUNYLFlBQVk7RUFDWixNQUFNO0FBQ1I7O0FBRUE7RUFDRSxhQUFhO0VBQ2IsWUFBWTtFQUNaLG1CQUFtQjtFQUNuQix1QkFBdUI7RUFDdkIsbUJBQW1CO0FBQ3JCIiwiZmlsZSI6InNyYy9tb2RhbHMvbG9ncy9jcmVhdGUuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4ubmF2YmFyLWJyYW5kIC5idXR0b24ge1xuICBwYWRkaW5nLWxlZnQ6IGNhbGMoMC43NWVtIC0gMXB4KTtcbiAgcGFkZGluZy1yaWdodDogY2FsYygwLjc1ZW0gLSAxcHgpO1xufVxuXG4ubWRsLW1vZGFsLW5hdmlnYXRpb24ge1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIHotaW5kZXg6IDUwO1xuICB3aWR0aDogMTAwdnc7XG4gIHRvcDogMDtcbn1cblxuLm1kbC1uYXZiYXItdGl0bGUge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWdyb3c6IDE7XG4gIG1pbi1oZWlnaHQ6IDMuMjVyZW07XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xufVxuIl19 */</style>

<div class="modal mdl-modal" class:is-active={active}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <CancelButton
          do_we_prompt={do_we_prompt_on_cancel}
          message={cancel_prompt_message}
          close_callback={close}
          bind:disabled={is_saving}
          button_type="back" />
        <div class="mdl-navbar-title">
          <h1 class="subtitle">Create New Drill Log</h1>
        </div>

      </div>

    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">Create New Drill Log</h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      <DrillLogForm bind:drill_log_info bind:validate bind:show_errors />

      <div class="field is-horizontal">
        <div class="field-label" />

        <div class="field-body">
          <div class="field is-grouped">
            <div class="control">
              <button
                class="button"
                class:is-loading={is_saving && is_saving.via_button === 1}
                disabled={!$heartbeat || is_saving}
                on:click={on_save_draft_button_click}>
                Save Draft
              </button>
              <button
                class="button"
                class:is-loading={is_saving && is_saving.via_button === 2}
                disabled={!$heartbeat || is_saving}
                on:click={on_send_to_drill_team_button_click}>
                Save and Send to Drill Team
              </button>
              <CancelButton
                do_we_prompt={do_we_prompt_on_cancel}
                message={cancel_prompt_message}
                close_callback={close}
                classes="is-danger"
                button_text="Cancel"
                bind:disabled={is_saving} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CancelButton
    do_we_prompt={do_we_prompt_on_cancel}
    message={cancel_prompt_message}
    close_callback={close}
    bind:disabled={is_saving}
    button_type="close" />
</div>
