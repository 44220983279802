import Model from "mdlui/model";

class CompletedSafetyChecklistsModel extends Model {
  constructor({ models, indexed }) {
    super({
      models,
      storage: {
        indexed: { indexed, store: "completed_safety_checklists" }
      }
    });
  }
}

export default CompletedSafetyChecklistsModel;
