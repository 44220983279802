<script>
  import { createEventDispatcher, onDestroy } from "svelte";
  import Icon from "fa-svelte";
  import DateTime from "mdlui/datetime";
  import { faCalculator } from "@fortawesome/free-solid-svg-icons";
  import PrintButton from "mdlui/components/print-button";

  export let active;

  const num = (x, y) =>
    Number(x || 0).toLocaleString("en-US", { maximumFractionDigits: y });

  $: if (active === true) {
    on_open();
  }

  export let report_data = {
    report_output: {
      report_results: {}
    }
  };

  export let start_date;
  export let end_date;

  const dispatch = createEventDispatcher();

  function close() {
    active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close");
  }

  function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");
  }

  async function on_close() {
    close();
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<style>
/* Svelma's Tabs component toggles the display attribute to control
 * visibilty; unfortunately, this allows the enclosing container to
 * reflow, which can cause the modal dialog to resize upon switching
 * tabs.  This is suboptimal UX, so force a minimum height. */

:global(.box .tabs-wrapper) {
  min-height: 50vh;
}

/* The content within the tabs is overflowing for some reason and the
 * default is to scroll; it's a bit clumsy on desktop and mobile.  The
 * modal already scrolls on overflow, so we don't need or want the tab
 * content scrolling as well.
 *
 * Setting explicit overflow attributes doesn't help.  Adding padding
 * at the bottom does.  I don't know why.  I don't have the available
 * time to care why, so here's a comment instead. */

:global(.box .tabs-wrapper .tab-content) {
  padding-bottom: 1em;
}

/* If we have too much content in the tabs, permit them to scroll. */

:global(.tab) {
  overflow-x: scroll;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvcmVwb3J0cy9jb21wYW55X3RvdGFsLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7Ozs2REFHNkQ7O0FBRTdEO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBOzs7Ozs7O21EQU9tRDs7QUFFbkQ7RUFDRSxtQkFBbUI7QUFDckI7O0FBRUEsb0VBQW9FOztBQUVwRTtFQUNFLGtCQUFrQjtBQUNwQiIsImZpbGUiOiJzcmMvbW9kYWxzL3JlcG9ydHMvY29tcGFueV90b3RhbC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi8qIFN2ZWxtYSdzIFRhYnMgY29tcG9uZW50IHRvZ2dsZXMgdGhlIGRpc3BsYXkgYXR0cmlidXRlIHRvIGNvbnRyb2xcbiAqIHZpc2liaWx0eTsgdW5mb3J0dW5hdGVseSwgdGhpcyBhbGxvd3MgdGhlIGVuY2xvc2luZyBjb250YWluZXIgdG9cbiAqIHJlZmxvdywgd2hpY2ggY2FuIGNhdXNlIHRoZSBtb2RhbCBkaWFsb2cgdG8gcmVzaXplIHVwb24gc3dpdGNoaW5nXG4gKiB0YWJzLiAgVGhpcyBpcyBzdWJvcHRpbWFsIFVYLCBzbyBmb3JjZSBhIG1pbmltdW0gaGVpZ2h0LiAqL1xuXG46Z2xvYmFsKC5ib3ggLnRhYnMtd3JhcHBlcikge1xuICBtaW4taGVpZ2h0OiA1MHZoO1xufVxuXG4vKiBUaGUgY29udGVudCB3aXRoaW4gdGhlIHRhYnMgaXMgb3ZlcmZsb3dpbmcgZm9yIHNvbWUgcmVhc29uIGFuZCB0aGVcbiAqIGRlZmF1bHQgaXMgdG8gc2Nyb2xsOyBpdCdzIGEgYml0IGNsdW1zeSBvbiBkZXNrdG9wIGFuZCBtb2JpbGUuICBUaGVcbiAqIG1vZGFsIGFscmVhZHkgc2Nyb2xscyBvbiBvdmVyZmxvdywgc28gd2UgZG9uJ3QgbmVlZCBvciB3YW50IHRoZSB0YWJcbiAqIGNvbnRlbnQgc2Nyb2xsaW5nIGFzIHdlbGwuXG4gKlxuICogU2V0dGluZyBleHBsaWNpdCBvdmVyZmxvdyBhdHRyaWJ1dGVzIGRvZXNuJ3QgaGVscC4gIEFkZGluZyBwYWRkaW5nXG4gKiBhdCB0aGUgYm90dG9tIGRvZXMuICBJIGRvbid0IGtub3cgd2h5LiAgSSBkb24ndCBoYXZlIHRoZSBhdmFpbGFibGVcbiAqIHRpbWUgdG8gY2FyZSB3aHksIHNvIGhlcmUncyBhIGNvbW1lbnQgaW5zdGVhZC4gKi9cblxuOmdsb2JhbCguYm94IC50YWJzLXdyYXBwZXIgLnRhYi1jb250ZW50KSB7XG4gIHBhZGRpbmctYm90dG9tOiAxZW07XG59XG5cbi8qIElmIHdlIGhhdmUgdG9vIG11Y2ggY29udGVudCBpbiB0aGUgdGFicywgcGVybWl0IHRoZW0gdG8gc2Nyb2xsLiAqL1xuXG46Z2xvYmFsKC50YWIpIHtcbiAgb3ZlcmZsb3cteDogc2Nyb2xsO1xufVxuIl19 */</style>

<div class="modal mdl-modal" class:is-active={active}>
  <div class="modal-background" />
  <div class="modal-content">
    <div class="box table-container">
      <div id="company-total-report-print-box">
        <h1 class="title">
          <Icon icon={faCalculator} />
          <span>Company Totals</span>
        </h1>

        <nav class="level">
          <nav class="level-item has-text-centered">
            <div>
              <p class="heading">Report Period</p>
              <p class="subtitle">
                From {DateTime.format_american_date(start_date)} through {DateTime.format_american_date(end_date)}
              </p>
            </div>
          </nav>
          <nav class="level-item has-text-centered">
            <div>
              <p class="heading">Generated on</p>
              <p class="subtitle">
                {DateTime.format_american_date(report_data.date_created)}
              </p>
            </div>
          </nav>
          <nav class="level-item has-text-centered">
            <div>
              <p class="heading">Created By</p>
              <p class="subtitle">{report_data.run_by}</p>
            </div>
          </nav>
        </nav>

        <hr />

        {#if report_data && report_data.report_output && report_data.report_output.report_results}
          {#if report_data.report_output.report_results.footage || report_data.report_output.report_results.hole_count || report_data.report_output.report_results.yardage}
            <div>
              Total Footage: {report_data.report_output.report_results.footage ? num(report_data.report_output.report_results.footage, 0) : "N/A"}
            </div>
            <div>
              Total Holes: {report_data.report_output.report_results.hole_count ? num(report_data.report_output.report_results.hole_count, 0) : "N/A"}
            </div>
            <div>
              Total Yardage: {report_data.report_output.report_results.yardage ? num(report_data.report_output.report_results.yardage.toFixed(1), 1) : "N/A"}
            </div>
          {:else}
            <div class="has-text-centered">
              Your report selections did not return any data. Please try again.
            </div>
          {/if}
        {:else}
          <div class="has-text-centered">
            Your report selections did not return any data. Please try again.
          </div>
        {/if}

      </div>
      <hr />

      <div class="buttons is-right">
          <PrintButton />
          <button class="button is-danger" on:click={on_close}>Close</button>
      </div>
    </div>
  </div>

  <button class="modal-close is-large" aria-label="close" on:click={on_close} />
</div>
