<script>
  import { onMount } from "svelte";
  import Icon from "fa-svelte";
  import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

  export let drill_log = {};
  export let job_totals = [];
  export let pattern_totals = [];

  const RS = String.fromCharCode(30);

  let pattern_totals_tabular = [];
  let bentonite_totals = { all: 0, by_pattern: {} };

  const num = (x) =>
    Number(x || 0).toLocaleString("en-US", { maximumFractionDigits: 0 });

  onMount(on_mount);

  function on_mount() {
    bentonite_totals = calculate_bentonite_totals(drill_log.bentonite);
    pattern_totals_tabular = tabulate_pattern_totals(
      pattern_totals,
      bentonite_totals
    );
  }

  function tabulate_pattern_totals(patterns, bentonite) {
    const keys = Object.keys(patterns).sort();

    return [
      { stat: "Pattern", data: keys },
      {
        stat: "Hole Size",
        data: keys.map((key) => {
          const pattern_group = drill_log.patterns.find(
            (test_pattern_group) => test_pattern_group.pattern === key
          );

          return pattern_group ? pattern_group.hole_size : "?";
        })
      },
      { stat: "Holes", data: keys.map((key) => num(patterns[key].hole_count)) },
      {
        stat: "Footage",
        data: keys.map((key) => num(patterns[key].footage_drilled))
      },
      {
        stat: "Yardage",
        data: keys.map((key) => num(patterns[key].yardage))
      },
      {
        stat: "Bags of Bentonite",
        data: keys.map((pattern) => bentonite.by_pattern[pattern] || 0)
      }
    ];
  }

  function calculate_bentonite_totals(bentonite = {}) {
    const by_pattern = {};
    let all = 0;

    Object.entries(bentonite).forEach(([key, bags]) => {
      const [, , , pattern] = key.split(RS);

      all += Number(bags);
      by_pattern[pattern] = Number(by_pattern[pattern] || 0) + Number(bags);
    });

    return { all, by_pattern };
  }
</script>

<div class="columns">
  <div class="column">
    <h2 class="subtitle">Job Totals</h2>

    <table class="table is-fullwidth is-hoverable">
      <tbody>
        <tr>
          <th>Footage</th>
          <td>{num(job_totals[drill_log.job_number].footage_drilled)}</td>
        </tr>
        <tr>
          <th>Holes</th>
          <td>{num(job_totals[drill_log.job_number].hole_count)}</td>
        </tr>
        <tr>
          <th>Yardage</th>
          <td>{num(job_totals[drill_log.job_number].yardage)}</td>
        </tr>
        <tr>
          <th>Bags of Bentonite</th>
          <td>{num(bentonite_totals.all)}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="column">
    <h2 class="subtitle">Pattern Totals</h2>

    <div class="columns is-mobile is-gapless">
      <div class="column">
        <table class="table is-fullwidth is-hoverable ">
          {#each pattern_totals_tabular as { stat, data }}
            <tr>
              <th>{stat}</th>
            </tr>
          {/each}
        </table>
      </div>

      <div class="column mdl-scroll-x">
        <table class="table is-fullwidth is-hoverable ">
          <tbody>
            {#each pattern_totals_tabular as { stat, data }}
              <tr>
                {#each data as data_point}
                  <td>{data_point === undefined ? "" : data_point}</td>
                {/each}
              </tr>
            {:else}
              <tr>
                <td class="mdl-no-data">
                  <Icon
                    class="mdl-icon-large has-text-grey-lighter"
                    icon={faExclamationCircle} />
                  <p>
                    No totals were available for calculation. Please enter hole
                    data and try again.
                  </p>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
