<script>
  import { getContext } from "svelte";
  import Icon from "fa-svelte";
  import LogList from "mdlui/components/logs/list";
  import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
  import LogCreateModal from "mdlui/modals/logs/create";

  const heartbeat = getContext("heartbeat");
  const list_is_outdated = getContext("list_is_outdated");

  let is_log_create_modal_active = false;

  function on_drill_log_create_button_click() {
    is_log_create_modal_active = true;
  }

  async function on_log_create_modal_closed({ detail: { modified } }) {
    // If there's a new job, we need to force the list to refresh
    if (modified) {
      list_is_outdated.set(true);
    }
  }
</script>

<LogCreateModal
  bind:active={is_log_create_modal_active}
  on:close={on_log_create_modal_closed} />

<div class="level is-mobile">
  <div class="level-left">
    <div class="level-item">
      <h1 class="title">Dashboard</h1>
    </div>
  </div>

  <div class="level-right">
    <div class="level-item">
      <div class="buttons">
        {#if $heartbeat}
          <button
            class="button"
            on:click={() => on_drill_log_create_button_click()}>
            <span class="icon is-left">
              <Icon icon={faPlusCircle} />
            </span>
            <span>Create Job</span>
          </button>
        {:else}
          <div
            class="has-tooltip-left has-tooltip-arrow"
            data-tooltip="Internet connectivity required to create new job">
            <button
              class="button"
              on:click={() => on_drill_log_create_button_click()}
              disabled>
              <span class="icon is-left">
                <Icon icon={faPlusCircle} />
              </span>
              <span>Create Job</span>
            </button>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<LogList />
