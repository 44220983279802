<script>
  import { getContext, onMount } from "svelte";
  import { Dialog } from "svelma";
  import naturalCompare from "natural-compare";
  import DateTime from "mdlui/datetime";
  import Icon from "fa-svelte";
  import {
    faSearch,
    faPlusCircle,
    faCaretSquareUp,
    faCaretSquareDown
  } from "@fortawesome/free-solid-svg-icons";

  const models = getContext("models");
  const heartbeat = getContext("heartbeat");
  const message = getContext("message");

  let loading = false;
  let materials = [];
  let filtered_materials = [];
  let new_material = {};
  let search_material_text = "";
  let show_errors = false;
  let is_new_material_name_valid = false;
  let is_new_material_code_valid = false;
  let sort = { column: "name", direction: "asc" };

  const list_sorter = (a, b) => {
    const a_val = a[sort.column] || "";
    const b_val = b[sort.column] || "";

    return sort.direction === "asc"
      ? naturalCompare(a_val, b_val)
      : naturalCompare(b_val, a_val);
  };

  onMount(on_mount);

  async function on_mount() {
    loading = true;
    // eslint-disable-next-line no-multi-assign
    materials = filtered_materials = await models.list.hole_material.retrieve_all();
    materials.sort(list_sorter);
    loading = false;
  }

  async function on_search_material_text_input() {
    filter_materials();
  }

  async function on_new_material_keydown(event) {
    if (event.key === "Enter") {
      add_material();
    }
  }

  async function on_new_material_add_button_click() {
    add_material();
  }

  function on_sort_change(column_key) {
    console.log("on_sort_change", column_key);

    if (sort.column === column_key) {
      sort = {
        column: column_key,
        direction: sort.direction === "asc" ? "desc" : "asc"
      };
    } else {
      sort = { column: column_key, direction: "asc" };
    }

    filtered_materials = filtered_materials.sort(list_sorter);
  }

  async function add_material() {
    validate_new_material();

    if (is_new_material_name_valid && is_new_material_code_valid) {
      const material = await models.list.hole_material.create(new_material, {
        strategy: ["backend"]
      });
      materials = [...materials, material];
      new_material = {};
      show_errors = false;
      filter_materials();
      message.set({
        intent: "success",
        body: `${material.name} has been added to the Material list`
      });
    }
  }

  async function filter_materials() {
    filtered_materials = materials
      .filter(
        (material) =>
          material.name.match(new RegExp(search_material_text, "i")) ||
          material.code.match(new RegExp(search_material_text, "i"))
      )
      .sort(list_sorter);
  }

  async function remove_material(event, material) {
    const confirm_remove = await Dialog.confirm({
      message: `Are you sure you wish to remove material ${material.name} (${material.code})?`,
      title: "Confirm Removal",
      type: "is-danger",
      confirmText: "Yes, Remove",
      cancelText: "No, Don't Remove"
    });

    if (confirm_remove) {
      await models.list.hole_material.delete(material.uuid, {
        strategy: ["backend"]
      });
      const index = materials.findIndex(
        (test_material) => test_material === material
      );
      materials.splice(index, 1);
      filter_materials();

      message.set({
        intent: "danger",
        body: `${material.name} has been removed from the Material list`
      });
    }
  }

  function validate_new_material() {
    show_errors = true;

    new_material.name = new_material.name.trim();
    new_material.code = new_material.code.trim().toUpperCase();

    is_new_material_name_valid = new_material.name.length > 0;
    is_new_material_code_valid =
      new_material.code.length > 0 &&
      !materials.find((material) => material.code === new_material.code);
  }
</script>

<style>
/* Make the control bar above the list of materials have the same
 * outside padding as the table containing them.  Yes, I am picky.
 */

.mdl-list-control {
  padding: 0.5em 0.75em;
}

th:nth-child(1) {
  width: 40%;
}

th:nth-child(2) {
  width: 30%;
}

th:nth-child(3) {
  width: 30%;
}

/* Our table headers are clickable, so let's indicate as such. */

th {
  cursor: pointer;
}

/* We're using the level component from Bulma to align table headers
 * with the sorting arrows, but it only applies to block objects, so
 * we need sort links to inherit the display model from their level
 * item containers.
 */

.level-item span {
  display: inherit;
}

/* And yet the icons still don't align with the text quite right, so
 * let's push them down a tiny bit.  I hate to do this, but it looks
 * better this way.
 */

span.mdl-sorter {
  position: relative;
  top: 0.075em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21hdGVyaWFsLW1hbmFnZW1lbnQuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTs7RUFFRTs7QUFFRjtFQUNFLHFCQUFxQjtBQUN2Qjs7QUFFQTtFQUNFLFVBQVU7QUFDWjs7QUFFQTtFQUNFLFVBQVU7QUFDWjs7QUFFQTtFQUNFLFVBQVU7QUFDWjs7QUFFQSxnRUFBZ0U7O0FBRWhFO0VBQ0UsZUFBZTtBQUNqQjs7QUFFQTs7OztFQUlFOztBQUVGO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBOzs7RUFHRTs7QUFFRjtFQUNFLGtCQUFrQjtFQUNsQixZQUFZO0FBQ2QiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbWF0ZXJpYWwtbWFuYWdlbWVudC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi8qIE1ha2UgdGhlIGNvbnRyb2wgYmFyIGFib3ZlIHRoZSBsaXN0IG9mIG1hdGVyaWFscyBoYXZlIHRoZSBzYW1lXG4gKiBvdXRzaWRlIHBhZGRpbmcgYXMgdGhlIHRhYmxlIGNvbnRhaW5pbmcgdGhlbS4gIFllcywgSSBhbSBwaWNreS5cbiAqL1xuXG4ubWRsLWxpc3QtY29udHJvbCB7XG4gIHBhZGRpbmc6IDAuNWVtIDAuNzVlbTtcbn1cblxudGg6bnRoLWNoaWxkKDEpIHtcbiAgd2lkdGg6IDQwJTtcbn1cblxudGg6bnRoLWNoaWxkKDIpIHtcbiAgd2lkdGg6IDMwJTtcbn1cblxudGg6bnRoLWNoaWxkKDMpIHtcbiAgd2lkdGg6IDMwJTtcbn1cblxuLyogT3VyIHRhYmxlIGhlYWRlcnMgYXJlIGNsaWNrYWJsZSwgc28gbGV0J3MgaW5kaWNhdGUgYXMgc3VjaC4gKi9cblxudGgge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi8qIFdlJ3JlIHVzaW5nIHRoZSBsZXZlbCBjb21wb25lbnQgZnJvbSBCdWxtYSB0byBhbGlnbiB0YWJsZSBoZWFkZXJzXG4gKiB3aXRoIHRoZSBzb3J0aW5nIGFycm93cywgYnV0IGl0IG9ubHkgYXBwbGllcyB0byBibG9jayBvYmplY3RzLCBzb1xuICogd2UgbmVlZCBzb3J0IGxpbmtzIHRvIGluaGVyaXQgdGhlIGRpc3BsYXkgbW9kZWwgZnJvbSB0aGVpciBsZXZlbFxuICogaXRlbSBjb250YWluZXJzLlxuICovXG5cbi5sZXZlbC1pdGVtIHNwYW4ge1xuICBkaXNwbGF5OiBpbmhlcml0O1xufVxuXG4vKiBBbmQgeWV0IHRoZSBpY29ucyBzdGlsbCBkb24ndCBhbGlnbiB3aXRoIHRoZSB0ZXh0IHF1aXRlIHJpZ2h0LCBzb1xuICogbGV0J3MgcHVzaCB0aGVtIGRvd24gYSB0aW55IGJpdC4gIEkgaGF0ZSB0byBkbyB0aGlzLCBidXQgaXQgbG9va3NcbiAqIGJldHRlciB0aGlzIHdheS5cbiAqL1xuXG5zcGFuLm1kbC1zb3J0ZXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHRvcDogMC4wNzVlbTtcbn1cbiJdfQ== */</style>

<div class="columns mdl-list-control">
  <div class="column is-half">
    <div class="field">
      <div class="control has-icons-left">
        <input
          class="input"
          type="text"
          placeholder="Search materials by name or code"
          bind:value={search_material_text}
          on:input={on_search_material_text_input} />
        <span class="icon is-small is-left">
          <Icon icon={faSearch} />
        </span>
      </div>
    </div>
  </div>

  <div class="column is-half">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field has-addons">
          <div class="control has-icons-left is-expanded">
            <input
              class="input is-fullwidth"
              class:is-danger={show_errors && !is_new_material_name_valid}
              type="text"
              placeholder="New material name"
              disabled={!$heartbeat}
              bind:value={new_material.name}
              on:keydown={on_new_material_keydown} />
            <span class="icon is-small is-left">
              <Icon icon={faPlusCircle} />
            </span>
            <p
              class="help is-danger"
              class:is-invisible={!show_errors || is_new_material_name_valid}>
              Material name is required
            </p>
          </div>
          <div class="control is-expanded">
            <input
              class="input is-fullwidth"
              class:is-danger={show_errors && !is_new_material_code_valid}
              type="text"
              placeholder="New material code"
              disabled={!$heartbeat}
              bind:value={new_material.code}
              on:keydown={on_new_material_keydown} />
            <p
              class="help is-danger"
              class:is-invisible={!show_errors || is_new_material_code_valid}>
              Code is required and must be unique
            </p>
          </div>
          <div class="control">
            <button
              class="button is-primary"
              disabled={!$heartbeat}
              on:click={on_new_material_add_button_click}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table class="table is-fullwidth is-hoverable">
  <thead>
    <tr>
      <th class="has-text-link">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <span on:click={() => on_sort_change("name")}>Name</span>
            </div>
            <div class="level-item">
              <span
                class="mdl-sorter"
                class:has-text-grey-lighter={sort.column !== "name"}
                on:click={() => on_sort_change("name")}>
                <Icon
                  icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
              </span>
            </div>
          </div>
        </div>
      </th>
      <th class="has-text-link">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <span on:click={() => on_sort_change("code")}>Code</span>
            </div>
            <div class="level-item">
              <span
                class="mdl-sorter"
                class:has-text-grey-lighter={sort.column !== "code"}
                on:click={() => on_sort_change("code")}>
                <Icon
                  icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
              </span>
            </div>
          </div>
        </div>
      </th>
      <th class="has-text-link is-hidden-handset">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <span on:click={() => on_sort_change("date_created")}>
                Date/Time Added
              </span>
            </div>
            <div class="level-item">
              <span
                class="mdl-sorter"
                class:has-text-grey-lighter={sort.column !== "date_created"}
                on:click={() => on_sort_change("date_created")}>
                <Icon
                  icon={sort.direction === "asc" ? faCaretSquareUp : faCaretSquareDown} />
              </span>
            </div>
          </div>
        </div>
      </th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#if loading}
      <tr>
        <td colspan="3">
          <div class="button is-fullwidth is-light is-loading" />
        </td>
      </tr>
    {:else}
      {#each filtered_materials as material (material.uuid)}
        <tr>
          <td>{material.name}</td>
          <td>{material.code}</td>
          <td class="is-hidden-handset">
            {DateTime.format_american_date_and_time(material.date_created)}
          </td>
          <td class="is-button-column">
            <button
              class="button is-danger"
              disabled={!$heartbeat}
              on:click={(event) => remove_material(event, material)}>
              Remove
            </button>
          </td>
        </tr>
      {/each}
    {/if}
  </tbody>
</table>
