import Model from "mdlui/model";

class SafetyChecklistTimestampModel extends Model {
  constructor({ models, indexed }) {
    super({
      models,
      storage: {
        indexed: { indexed, store: "timestamp.safety_checklist" }
      }
    });
  }
}

export default SafetyChecklistTimestampModel;
