<script>
  import { getContext, onMount } from "svelte";
  import naturalCompare from "natural-compare";
  import DateTime from "mdlui/datetime";
  import Icon from "fa-svelte";
  import {
    faSearch,
    faUser,
    faPlusCircle
  } from "@fortawesome/free-solid-svg-icons";
  import DropDown from "mdlui/components/forms/dropdown";

  const models = getContext("models");
  const heartbeat = getContext("heartbeat");
  const message = getContext("message");

  let loading = true;
  let users = [];
  let filtered_users = [];
  let search_text = "";
  const new_user = { email: "" };

  const roles = [
    { id: "Administrator", name: "Administrator" },
    { id: "Project Manager", name: "Project Manager" },
    { id: "User", name: "Field" },
    { id: "Timesheet User", name: "Timesheet User" },
    { id: "Timesheet Administrator", name: "Timesheet Administrator" }
  ];

  const user_sorter = (a, b) => {
    const a_name = a.name || "";
    const b_name = b.name || "";

    return naturalCompare(a_name, b_name) || naturalCompare(a.email, b.email);
  };

  onMount(on_mount);

  async function on_mount() {
    loading = true;
    // eslint-disable-next-line no-multi-assign
    users = filtered_users = await models.user.retrieve_all();
    users.sort(user_sorter);
    loading = false;
  }

  async function on_search_text_input() {
    filter_users();
  }

  async function on_new_user_keydown(event) {
    if (event.key === "Enter") {
      add_user(new_user);
    }
  }

  async function on_new_user_add_button_click() {
    add_user(new_user);
  }

  async function on_user_role_changed(event, user_uuid) {
    const { spinner, option: role } = event.detail;
    const user = users.find((_user) => _user.uuid === user_uuid);

    await spinner(async () => {
      const updated_user = await models.user.update(
        user.uuid,
        {
          role: role.id,
          date_updated: user.date_updated
        },
        { strategy: ["backend"] }
      );
      Object.assign(user, updated_user);
      filter_users();
    });
  }

  async function on_user_number_changed(event, user_uuid) {
    const employee_number = event.target.value;
    const user = users.find((_user) => _user.uuid === user_uuid);

    const updated_user = await models.user.update(
      user.uuid,
      {
        employee_number,
        date_updated: user.date_updated
      },
      { strategy: ["backend"] }
    );
    Object.assign(user, updated_user);
    filter_users();
  }

  async function add_user(new_user_info) {
    const user = await models.user.create(
      {
        ...new_user_info,
        name: new_user_info.email
          .split("@")[0]
          .split(".")
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(" ")
      },
      { strategy: ["backend"] }
    );
    users = [...users, user];
    new_user.email = "";
    new_user.role = undefined;
    filter_users();

    message.set({
      intent: "success",
      body: `${user.email} has been added to the User list`
    });
  }

  async function filter_users() {
    filtered_users = users
      .filter(
        (user) =>
          user.email.match(new RegExp(search_text, "i")) ||
          (user.name && user.name.match(new RegExp(search_text, "i")))
      )
      .sort(user_sorter);
  }

  async function toggle_user_status(event, user_uuid) {
    event.target.classList.add("is-loading");

    try {
      const user = users.find((_user) => _user.uuid === user_uuid);
      const updated_user = await models.user.update(
        user.uuid,
        {
          active: user.active === undefined ? false : !user.active,
          date_updated: user.date_updated
        },
        { strategy: ["backend"] }
      );
      Object.assign(user, updated_user);
      filter_users();
    } finally {
      event.target.classList.remove("is-loading");
    }
  }
</script>

<style>
/* Make the control bar above the list of users have the same outside
 * padding as the table containing them.  Yes, I am picky.
 */

.mdl-user-control {
  padding: 0.5em 0.75em;
}

/* The profile photo should be as small as possible. */

th:nth-child(1) {
  width: 1%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3VzZXItbWFuYWdlbWVudC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBOztFQUVFOztBQUVGO0VBQ0UscUJBQXFCO0FBQ3ZCOztBQUVBLHNEQUFzRDs7QUFFdEQ7RUFDRSxTQUFTO0FBQ1giLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdXNlci1tYW5hZ2VtZW50LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLyogTWFrZSB0aGUgY29udHJvbCBiYXIgYWJvdmUgdGhlIGxpc3Qgb2YgdXNlcnMgaGF2ZSB0aGUgc2FtZSBvdXRzaWRlXG4gKiBwYWRkaW5nIGFzIHRoZSB0YWJsZSBjb250YWluaW5nIHRoZW0uICBZZXMsIEkgYW0gcGlja3kuXG4gKi9cblxuLm1kbC11c2VyLWNvbnRyb2wge1xuICBwYWRkaW5nOiAwLjVlbSAwLjc1ZW07XG59XG5cbi8qIFRoZSBwcm9maWxlIHBob3RvIHNob3VsZCBiZSBhcyBzbWFsbCBhcyBwb3NzaWJsZS4gKi9cblxudGg6bnRoLWNoaWxkKDEpIHtcbiAgd2lkdGg6IDElO1xufVxuIl19 */</style>

<div class="columns mdl-user-control">
  <div class="column is-half">
    <div class="field">
      <div class="control has-icons-left">
        <input
          class="input"
          type="text"
          placeholder="Search users"
          bind:value={search_text}
          on:input={on_search_text_input} />
        <span class="icon is-small is-left">
          <Icon icon={faSearch} />
        </span>
      </div>
    </div>
  </div>

  <div class="column is-half is-hidden-handset">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field has-addons">
          <div class="control has-icons-left is-expanded">
            <input
              class="input"
              type="text"
              placeholder="New user email"
              disabled={!$heartbeat}
              bind:value={new_user.email}
              on:keydown={on_new_user_keydown} />
            <span class="icon is-small is-left">
              <Icon icon={faPlusCircle} />
            </span>
          </div>
          <div class="control has-icons-left is-expanded">
            <input
              class="input"
              type="text"
              placeholder="Employee #"
              disabled={!$heartbeat}
              bind:value={new_user.employee_number} />
          </div>
          <div class="control has-icons-left">
            <DropDown
              icon={faUser}
              data={roles}
              data_key="id"
              placeholder="Select Role"
              disabled={!$heartbeat}
              bind:value={new_user.role} />
          </div>
          <div class="control">
            <button
              class="button is-primary"
              disabled={!$heartbeat}
              on:click={on_new_user_add_button_click}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <p class="help">
      <strong>Note:</strong>
      The user's name and profile photo will be populated automatically by
      Google upon the user's first sign-in to the Drill Log System.
    </p>
  </div>
</div>

<table class="table is-fullwidth is-hoverable">
  <thead>
    <tr>
      <th />
      <th class="is-hidden-handset">Name</th>
      <th>Email</th>
      <th>Employee Number</th>
      <th class="is-hidden-mobile">Role</th>
      <th class="is-hidden-mobile">Date/Time Updated</th>
      <th class="is-hidden-handset" />
    </tr>
  </thead>
  <tbody>
    {#if loading}
      <tr>
        <td colspan="6">
          <div class="button is-fullwidth is-light is-loading" />
        </td>
      </tr>
    {:else}
      {#each filtered_users as user (user.uuid)}
        <tr>
          <td>
            {#if user.photo_url}
              <figure class="image is-32x32">
                <img
                  class="is-rounded"
                  src={user.photo_url}
                  alt="User Profile Photograph" />
              </figure>
            {/if}
          </td>
          <td class="is-hidden-handset">{user.name || ""}</td>
          <td>{user.email}</td>
          <td class="is-hidden-mobile">
            <input
              class="input"
              type="text"
              placeholder="Employee #"
              disabled={!$heartbeat}
              bind:value={user.employee_number}
              on:blur={(event) => on_user_number_changed(event, user.uuid)} />
          </td>
          <td class="is-hidden-mobile">
            <DropDown
              icon={faUser}
              data={roles}
              data_key="id"
              value={user.role}
              disabled={!$heartbeat}
              on:selection={(event) => on_user_role_changed(event, user.uuid)} />
          </td>
          <td class="is-hidden-mobile">
            {DateTime.format_american_date_and_time(user.date_updated)}
          </td>
          <td class="is-button-column is-hidden-handset">
            <button
              class="button {user.active !== false ? "is-danger" : ""}"
              disabled={!$heartbeat}
              on:click={(event) => toggle_user_status(event, user.uuid)}>
              {user.active === false ? "Reactivate" : "Deactivate"}
            </button>
          </td>
        </tr>
      {/each}
    {/if}
  </tbody>
</table>
