const Totals = require("../totals.js");

class CompanyTotal {
  static calculate(dataset) {
    const totals = Totals.calculate_all(dataset);
    return {
      bentonite: totals.company.bentonite,
      footage: totals.company.footage_drilled,
      hole_count: totals.company.hole_count,
      yardage: totals.company.yardage
    };
  }
}

module.exports = CompanyTotal;
