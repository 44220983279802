import DrillLogModel from "mdlui/models/drill-log";
import GraphModel from "mdlui/models/graph";
import CellModel from "mdlui/models/cell";
import UserModel from "mdlui/models/user";
import DrillListModel from "mdlui/models/list/drill";
import DrillerListModel from "mdlui/models/list/driller";
import HoleSizeListModel from "mdlui/models/list/hole-size";
import HoleMaterialListModel from "mdlui/models/list/hole-material";
import CompletedSafetyChecklistsModel from "mdlui/models/completed-safety-checklists";
import SafetyChecklistModel from "mdlui/models/safety-checklist";
import SubdrillListModel from "mdlui/models/list/subdrill";
import TimeSheetEntryModel from "mdlui/models/time-entry";
import TimeTypeModel from "mdlui/models/time-type";
import VehicleListModel from "mdlui/models/list/vehicle";
import DrillLogTimestampModel from "mdlui/models/timestamp/drill-log";
import GraphTimestampModel from "mdlui/models/timestamp/graph";
import SafetyChecklistTimestampModel from "mdlui/models/timestamp/safety_checklist";
import TimeSheetEntryTimestampModel from "mdlui/models/timestamp/time_sheet_entry";
import SyncTaskModel from "mdlui/models/sync-task";
import MetadataModel from "mdlui/models/metadata";

class Models {
  constructor({ backend, indexed }) {
    const models = this;

    Object.assign(this, {
      drill_log: new DrillLogModel({ models, backend, indexed }),
      graph: new GraphModel({ models, backend, indexed }),
      cell: new CellModel({ models, indexed }),
      completed_safety_checklists: new CompletedSafetyChecklistsModel({
        models,
        indexed
      }),
      user: new UserModel({ models, backend, indexed }),
      list: {
        drill: new DrillListModel({ models, backend, indexed }),
        driller: new DrillerListModel({ models, backend, indexed }),
        hole_size: new HoleSizeListModel({ models, backend, indexed }),
        hole_material: new HoleMaterialListModel({ models, backend, indexed }),
        subdrill: new SubdrillListModel({ models, backend, indexed }),
        vehicle: new VehicleListModel({ models, backend, indexed })
      },
      safety_checklist: new SafetyChecklistModel({ models, backend, indexed }),
      sync_task: new SyncTaskModel({ models, indexed }),
      time_sheet_entry: new TimeSheetEntryModel({ models, backend, indexed }),
      time_type: new TimeTypeModel({ models, backend, indexed }),
      timestamp: {
        drill_log: new DrillLogTimestampModel({ models, indexed }),
        graph: new GraphTimestampModel({ models, indexed }),
        safety_checklist: new SafetyChecklistTimestampModel({
          models,
          indexed
        }),
        time_sheet_entry: new TimeSheetEntryTimestampModel({ models, indexed })
      },
      metadata: new MetadataModel({ models, indexed })
    });

    // All models inherit from the Model class, which itself inherits
    // from EventEmitter.  For the drill log and graph models, we want
    // to catch any sync events and record the sync timestamps in the
    // corresponding timestamp models, but only for objects synced to
    // IndexedDB.

    this.drill_log.on("sync", ({ to, objects }) => {
      if (to === "indexed") {
        objects.forEach((object) =>
          this.timestamp.drill_log.update(object.uuid, { synced: new Date() })
        );
      }
    });

    this.graph.on("sync", ({ to, objects }) => {
      if (to === "indexed") {
        objects.forEach((object) =>
          this.timestamp.graph.update(object.uuid, { synced: new Date() })
        );
      }
    });

    this.safety_checklist.on("sync", ({ to, objects }) => {
      if (to === "indexed") {
        objects.forEach((object) =>
          this.timestamp.safety_checklist.update(object.uuid, {
            synced: new Date()
          })
        );
      }
    });

    this.time_sheet_entry.on("sync", ({ to, objects }) => {
      if (to === "indexed") {
        objects.forEach((object) =>
          this.timestamp.time_sheet_entry.update(object.uuid, {
            synced: new Date()
          })
        );
      }
    });
  }
}

export default Models;
