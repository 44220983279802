const personal_safety = require("./sections/personal_safety");
const pickup = require("./sections/pickup");
const quarry = require("./sections/quarry");

const sheet = {
  id: "pd1",
  name: "powder",
  sections: [personal_safety.section, pickup.section, quarry.section]
};

exports.sheet = sheet;
