<script>
  import { Switch } from "svelma";

  export let checked;
  export let disabled;
  export let label;
  export let type = "is-info";
  export let tip;
  export let tip_placement = "top";
</script>

<style>
.control {
  padding-top: 0.375em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm1zL3N3aXRjaC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBO0VBQ0Usb0JBQW9CO0FBQ3RCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2Zvcm1zL3N3aXRjaC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5jb250cm9sIHtcbiAgcGFkZGluZy10b3A6IDAuMzc1ZW07XG59XG4iXX0= */</style>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field">
      <div class="control">
        <span
          class="has-tooltip-{tip_placement} has-tooltip-arrow"
          data-tooltip={tip}>
          <Switch {type} bind:checked bind:disabled />
        </span>
      </div>
    </div>
  </div>
</div>
