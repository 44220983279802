<script>
  import { getContext, onMount } from "svelte";
  import Icon from "fa-svelte";
  import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

  export let daily_totals;

  const num = (x, y) =>
    Number(x || 0).toLocaleString("en-US", { maximumFractionDigits: y });

  const cache = getContext("cache");
  let driller_totals = {};

  onMount(on_mount);

  function on_mount() {
    driller_totals = tabulate_driller_totals(daily_totals);
  }

  function tabulate_driller_totals(daily_totals_obj) {
    const totals_by_driller = {};
    Object.keys(daily_totals_obj).forEach((day) => {
      Object.entries(daily_totals_obj[day])
        .filter(
          // eslint-disable-next-line no-unused-vars
          ([driller_uuid, driller_daily_values]) =>
            typeof driller_daily_values === "object"
        )
        .forEach(([driller_uuid, driller_daily_values]) => {
          if (totals_by_driller[driller_uuid] === undefined) {
            totals_by_driller[driller_uuid] = driller_daily_values;
          } else {
            totals_by_driller[driller_uuid].hole_count +=
              driller_daily_values.hole_count;
            totals_by_driller[driller_uuid].footage_drilled +=
              driller_daily_values.footage_drilled;
            totals_by_driller[driller_uuid].yardage +=
              driller_daily_values.yardage;
          }
        });
    });

    return totals_by_driller;
  }
</script>

<table class="table is-fullwidth is-hoverable">
  <thead>
    <tr>
      <th>Driller</th>
      <th># of Holes</th>
      <th>Footage</th>
      <th>Yardage</th>
    </tr>
  </thead>
  <tbody>
    {#each Object.entries(driller_totals) as [driller_uuid, driller_total]}
      <tr>
        <td>{cache.lookups.drillers[driller_uuid].name}</td>
        <td>{num(driller_total.hole_count, 0) || 0}</td>
        <td>{num(driller_total.footage_drilled, 0) || 0}</td>
        <td>{num(driller_total.yardage, 1) || 0}</td>
      </tr>
    {:else}
      <tr>
        <td colspan="4" class="mdl-no-data">
          <Icon
            class="mdl-icon-large has-text-grey-lighter"
            icon={faExclamationCircle} />
          <p>
            No totals were available for calculation. Please enter hole data and
            try again.
          </p>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
