const Totals = require("../totals.js");

class DailySubtotalByDriller {
  static calculate(dataset, report_parameters = {}) {
    const results = {};
    Object.values(dataset).forEach((drill_log) => {
      let totals_for_job = Totals.calculate_one(drill_log, [
        [drill_log.graphs]
      ]);
      if (report_parameters.driller !== undefined) {
        totals_for_job = DailySubtotalByDriller.filter_by_driller(
          totals_for_job,
          report_parameters.driller
        );
      }
      if (report_parameters.drill !== undefined) {
        totals_for_job = DailySubtotalByDriller.filter_by_drill(
          totals_for_job,
          report_parameters.drill
        );
      }
      if (Object.keys(totals_for_job.daily).length > 0) {
        results[drill_log.job_number] = {
          site_name: drill_log.site_name,
          totals: totals_for_job
        };
      }
    });
    return results;
  }

  static filter_by_driller(totals, driller) {
    const filtered_totals = { daily: {} };
    Object.keys(totals.daily).forEach((date_key) => {
      Object.keys(totals.daily[date_key]).forEach((driller_key) => {
        if (driller_key === driller) {
          if (filtered_totals.daily[date_key] === undefined) {
            filtered_totals.daily[date_key] = {};
          }
          filtered_totals.daily[date_key][driller_key] =
            totals.daily[date_key][driller_key];
        }
      });
    });
    return filtered_totals;
  }

  static filter_by_drill(totals, drill) {
    const filtered_totals = { daily: {} };
    Object.keys(totals.daily).forEach((date_key) => {
      Object.keys(totals.daily[date_key]).forEach((driller_key) => {
        Object.keys(totals.daily[date_key][driller_key]).forEach(
          (drill_key) => {
            if (drill_key === drill) {
              if (filtered_totals.daily[date_key] === undefined) {
                filtered_totals.daily[date_key] = {};
              }
              if (filtered_totals.daily[date_key][driller_key] === undefined) {
                filtered_totals.daily[date_key][driller_key] = {};
              }
              filtered_totals.daily[date_key][driller_key][drill_key] =
                totals.daily[date_key][driller_key][drill_key];
            }
          }
        );
      });
    });
    return filtered_totals;
  }
}

module.exports = DailySubtotalByDriller;
