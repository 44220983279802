const lodash = require("lodash");

class Capabilities {
  constructor() {
    const self = this;

    // The file looks cleaner with the big static global at the bottom.
    // eslint-disable-next-line no-use-before-define
    self.config = CONFIG;

    return self;
  }

  load() {
    const self = this;
    // Fetch all of the data from the config.

    const capabilities = self.config.capability;
    const roles = self.config.role;

    // Map capability name to capability id and vice versa.

    self.capability_id_by_name = capabilities.reduce(
      (map, capability) => ({ ...map, [capability.name]: capability.id }),
      {}
    );
    self.capability_name_by_id = lodash.invert(self.capability_id_by_name);

    // Map role id to role name and vice versa.

    self.role_id_by_name = roles.reduce(
      (map, role) => ({ ...map, [role.id]: role.name }),
      {}
    );
    self.role_name_by_id = lodash.invert(self.role_id_by_name);

    // Mapping of role ids and names to capability ids and names.
    self.capability_ids_by_role_id = self.config.capability_ids_by_role_id;

    self.capability_ids_by_role_name = roles.reduce(
      (map, role) => ({
        ...map,
        [role.name]: self.capability_ids_by_role_id[role.id]
      }),
      {}
    );

    self.capability_names_by_role_id = roles.reduce(
      (map, role) => ({
        ...map,
        [role.id]: self.capability_ids_by_role_id[role.id].map(
          (capability_id) => self.capability_name_by_id[capability_id]
        )
      }),
      {}
    );

    self.capability_names_by_role_name = roles.reduce(
      (map, role) => ({
        ...map,
        [role.name]: self.capability_ids_by_role_id[role.id].map(
          (capability_id) => self.capability_name_by_id[capability_id]
        )
      }),
      {}
    );
  }

  get_capabilities_for_role_name(role_name) {
    const self = this;

    const role_id = self.role_name_by_id[role_name];

    if (!role_id) {
      return [];
    }

    return self.get_capabilities_for_roles([role_id]);
  }

  get_capabilities_for_roles(role_ids) {
    const self = this;

    // Lookup each role id in our cached capability_names_by_role_id map
    // and then flatten the nested lookup to a list of capability names.
    // There may be duplicates, but that's okay.  We're going to feed
    // them to a Map object and the list of capabilities for a user is
    // the union of all capabilities for all roles for that user.

    const capability_names = lodash.flattenDeep(
      role_ids.map((role_id) => self.capability_names_by_role_id[role_id])
    );

    // Return a Map object.  This permits the caller to use Map#has to
    // check for a capability.

    return new Map(
      capability_names.map((capability_name) => [
        capability_name,
        self.capability_id_by_name[capability_name]
      ])
    );
  }
}

const CONFIG = {
  capability: [
    {
      id: 1,
      name: "auth:login",
      description: "Log in to the system"
    },
    {
      id: 2,
      name: "logs:create",
      description: "Create a new drill log"
    },
    {
      id: 3,
      name: "logs:fetch",
      description: "Retrieve a specific drill log or a list of them"
    },
    {
      id: 4,
      name: "logs:update",
      description: "Update a new drill log"
    },
    {
      id: 5,
      name: "logs:suspend",
      description: "Suspend a job/drill log"
    },
    {
      id: 6,
      name: "user:create",
      description: "Add an entry to the list of authorized users."
    },
    {
      id: 7,
      name: "user:update",
      description: "Update a user."
    },
    {
      id: 8,
      name: "user:deactivate",
      description: "Remove a user's authorization to use the system."
    },
    // 0 is create, 1 is read, 2 is delete, 3 is update
    {
      id: 1000,
      name: "list:drill:create",
      description: "Crate a new drill."
    },
    {
      id: 1001,
      name: "list:drill:fetch",
      description: "Fetch the list of drills."
    },
    {
      id: 1002,
      name: "list:drill:delete",
      description: "Delete a drill."
    },
    {
      id: 1003,
      name: "list:drill:update",
      description: "Update a drill."
    },
    {
      id: 13,
      name: "list:driller:fetch",
      description: "Fetch the list of drillers."
    },
    {
      id: 1100,
      name: "list:hole_size:create",
      description: "Create a new hole size."
    },
    {
      id: 1101,
      name: "list:hole_size:fetch",
      description: "Fetch the list of hole sizes."
    },
    {
      id: 1102,
      name: "list:hole_size:delete",
      description: "Remove a hole size."
    },
    {
      id: 1103,
      name: "list:hole_size:update",
      description: "Update a hole size."
    },
    {
      id: 1200,
      name: "list:hole_material:create",
      description: "Add a hole material to the list."
    },
    {
      id: 1201,
      name: "list:hole_material:fetch",
      description: "Fetch the list of hole materials."
    },
    {
      id: 1202,
      name: "list:hole_material:delete",
      description: "Remove a hole material from the list."
    },
    {
      id: 1203,
      name: "list:hole_material:update",
      description: "Update a hole material."
    },
    {
      id: 21,
      name: "logs:assign",
      description: "Manage drill log assignments."
    },
    {
      id: 22,
      name: "graph:create",
      description: "Create a drill graph.."
    },
    {
      id: 23,
      name: "graph:fetch",
      description: "Retrieve a drill graph."
    },
    {
      id: 24,
      name: "graph:update",
      description: "Update a drill graph."
    },
    {
      id: 25,
      name: "graph:delete",
      description: "Delete a drill graph."
    },
    {
      id: 26,
      name: "user:fetch",
      description: "Retrieve a user from the system."
    },
    {
      id: 27,
      name: "settings",
      description: "View system settings."
    },
    {
      id: 28,
      name: "reports:run",
      description: "Run reports in the system."
    },
    {
      id: 29,
      name: "logs:workflow",
      description: "Transition a drill log through its workflow."
    },
    {
      id: 30,
      name: "sync:read",
      description: "Synchronize the application data from the server."
    },
    {
      id: 40,
      name: "list:vehicle:create",
      description: "Create a new vehicle."
    },
    {
      id: 41,
      name: "list:vehicle:fetch",
      description: "Fetch the vehicles list."
    },
    {
      id: 42,
      name: "list:vehicle:delete",
      description: "Delete a vehicle from the list."
    },
    {
      id: 43,
      name: "list:vehicle:update",
      description: "Update a vehicle."
    },
    {
      id: 50,
      name: "list:time_type:create",
      description: "Create a new time_type."
    },
    {
      id: 51,
      name: "list:time_type:fetch",
      description: "Fetch the time_types list."
    },
    {
      id: 52,
      name: "list:time_type:delete",
      description: "Delete a time_type from the list."
    },
    {
      id: 53,
      name: "list:time_type:update",
      description: "Update a time_type."
    },
    {
      id: 60,
      name: "time_sheet_entry:create",
      description: "Create time_sheet_entry documents."
    },
    {
      id: 61,
      name: "time_sheet_entry:fetch",
      description: "Fetch time_sheet_entry documents."
    },
    {
      id: 62,
      name: "time_sheet_entry:delete",
      description: "Delete time_sheet_entry documents."
    },
    {
      id: 63,
      name: "time_sheet_entry:update",
      description: "Update time_sheet_entry documents."
    },
    {
      id: 64,
      name: "time_sheet_entry:superuser",
      description: "Update all and any ime_sheet_entry documents."
    },
    {
      id: 70,
      name: "safety_checklist:create",
      description: "Create safety_checklist documents."
    },
    {
      id: 71,
      name: "safety_checklist:fetch",
      description: "Fetch safety_checklist documents."
    },
    {
      id: 72,
      name: "safety_checklist:delete",
      description: "Delete safety_checklist documents."
    },
    {
      id: 73,
      name: "safety_checklist:update",
      description: "Update safety_checklist documents."
    },
    {
      id: 74,
      name: "safety_checklist:superuser",
      description: "Update all and any safety_checklist documents."
    }
  ],
  role: [
    {
      id: 1,
      name: "Administrator"
    },
    {
      id: 2,
      name: "Project Manager"
    },
    {
      id: 3,
      name: "User"
    },
    {
      id: 4,
      name: "Timesheet User"
    },
    {
      id: 5,
      name: "Timesheet Administrator"
    }
  ],
  /* prettier-ignore */
  capability_ids_by_role_id: {
    "1": [ 
      1,              // login 
      2, 3, 4, 5,     // logs permissions
      6, 7, 8,        // user
      1000, 1001, 1002, 1003, // drill (subdrill)
      13,                     // driller fetch method
      1100, 1101, 1102, 1103, // hole_size
      1200, 1201, 1203, 1203, // hole_material
      21,             // logs assign user 
      22, 23, 24, 25, // graphs 
      26, // user fetch 
      27, // setttings 
      28, // reports
      29, // workflow 
      30, // sync 
      40, 41, 42, 43,  // vehicle_type permissions
      50, 51, 52, 53,  // time_type (list) permissions
      60, 61, 62, 63, 64, //  time_sheet_entry permissions
      70, 71, 73, 74  //  safety_checklist permissions
    ],
    "2": [ 1, 2, 3, 4, 5, 1001, 13, 1101, 1201, 21, 22, 23, 24, 25, 26, 28, 29, 30, 41, 51, 60, 61, 62, 63, 64, 70, 71, 73 ],
    "3": [ 1, 3, 4, 1001, 13, 1101, 1201, 22, 23, 24, 25, 30, 41, 51, 60, 61 ,62, 63, 70, 71, 73, 74],
    "4": [ 1, 3, 60, 61, 62, 63, 70, 71, 73],
    "5": [ 1, 3, 60, 61, 62, 63, 64, 70, 71, 73, 74]
  }
};

module.exports = Capabilities;
