const graphlib = require("graphlib");

const status_graph = {
  options: { directed: true },
  nodes: [
    { v: undefined },
    { v: "Draft" },
    { v: "Ready for Job" },
    { v: "In Progress" },
    { v: "Complete" },
    { v: "Billed" },
    { v: "Requires Rework" }
  ],
  edges: [
    {
      v: undefined,
      w: "Draft",
      value: {
        name: "Save Draft"
      }
    },
    {
      v: undefined,
      w: "Ready for Job",
      value: {
        name: "Send to Drill Team"
      }
    },
    {
      v: "Draft",
      w: "Ready for Job",
      value: {
        name: "Send to Drill Team",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          },
          {
            description: "must have a four-digit job number",
            predicate: (log) => log.job_number && log.job_number.length === 4
          },
          {
            description: "must have a site name",
            predicate: (log) => log.site_name
          },
          {
            description: "must have at least one assigned driller",
            predicate: (log) => log.assigned && log.assigned.length > 0
          }
        ]
      }
    },
    {
      v: "Ready for Job",
      w: "In Progress",
      value: {
        name: "Start Job",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          }
        ]
      }
    },
    {
      v: "In Progress",
      w: "Complete",
      value: {
        name: "Complete Job",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          }
        ]
      }
    },
    {
      v: "Complete",
      w: "Billed",
      value: {
        name: "Mark as Billed",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          }
        ]
      }
    },
    {
      v: "Billed",
      w: "Requires Rework",
      value: {
        name: "Send for Rework",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          }
        ]
      }
    },
    {
      v: "Requires Rework",
      w: "Complete",
      value: {
        name: "Complete Job",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          }
        ]
      }
    },
    {
      v: "Complete",
      w: "Requires Rework",
      value: {
        name: "Send for Rework",
        conditions: [
          {
            description: "must not be suspended",
            predicate: (log) => log.suspended !== true
          }
        ]
      }
    }
  ]
};

class Workflow {
  constructor() {
    this.graph = graphlib.json.read(status_graph);
  }

  can_transition(drill_log, status) {
    if (typeof drill_log !== "object") {
      throw new Error("Workflow#can_transition: drill log is not an object");
    }

    if (drill_log.status === status) {
      return true;
    }

    if (this.graph.hasEdge(drill_log.status, status)) {
      const edge = this.graph.edge(drill_log.status, status);
      const conditions = edge && edge.conditions ? edge.conditions : [];

      return conditions.every((condition) => condition.predicate(drill_log));
    }

    return false;
  }

  get_status_transitions(drill_log) {
    if (typeof drill_log !== "object") {
      throw new Error("Workflow#can_transition: drill log is not an object");
    }

    const transitions = this.graph.outEdges(drill_log.status);

    return transitions.map(({ v, w }) => {
      const edge = this.graph.edge({ v, w });
      const conditions = edge && edge.conditions ? edge.conditions : [];
      const failing_conditions = conditions.filter(
        (condition) => !condition.predicate(drill_log)
      );

      return {
        name: edge.name,
        status: w,
        available: failing_conditions.length === 0,
        available_reason:
          failing_conditions.length > 0
            ? failing_conditions
                .map((condition) => condition.description)
                .join(", ")
            : "all conditions met"
      };
    });
  }
}

module.exports = Workflow;
