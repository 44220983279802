<script>
  import { IINAError } from "iina-client";
  import { getContext, onMount, onDestroy } from "svelte";
  import { Dialog } from "svelma";
  import Icon from "fa-svelte";
  import { faCheckCircle, faFlag } from "@fortawesome/free-solid-svg-icons";
  import { SafetyChecklist } from "mdl-shared";
  import SafetyChecklistModal from "mdlui/modals/safety-checklist";
  import TimeEditorModal from "mdlui/modals/time-editor";
  import ViewSafetyChecklistModal from "mdlui/modals/view-safety-checklist";

  import DateTime from "mdlui/datetime";
  import DateTimeField from "mdlui/components/forms/datetime";

  export let params;

  const backnav = getContext("backnav");
  const cache = getContext("cache");
  const history = getContext("history");
  const heartbeat = getContext("heartbeat");
  const models = getContext("models");
  const page = getContext("page");
  const sync = getContext("sync");
  const user = getContext("get_user")();

  // These will get replaced with actual lists
  let time_sheet_entries = [];
  let checklist_questions = {};
  let strategy = [];
  let timesheet = {};
  let users = [];
  let lookups;

  let heartbeat_unsubscribe;
  let loading = false;
  let readonly = false;
  let show_errors = false;
  let drill_log = {};
  let log_uuid = "";
  let edited_time = {};
  let new_time = {};
  let checklist_answers;
  let is_clock_out_checklist = false;
  let is_time_valid = false;
  let is_create_modal_active = false;
  let is_editor_modal_active = false;
  let is_safety_checklist_modal_active = false;
  let is_view_safety_checklist_modal_active = false;

  onMount(on_mount);
  onDestroy(on_destroy);

  async function on_mount() {
    loading = true;

    backnav.set(
      $history.previous && $history.previous.path.match("/logs")
        ? $history.previous.path
        : "/"
    );

    log_uuid = params.get("log_uuid");

    console.log("mdl-ui: job time: fetching drill_log and times");

    try {
      [drill_log] = await Promise.all([
        models.drill_log.retrieve(log_uuid, { sync: { to: "indexed" } })
      ]);

      drill_log.active_time = drill_log.active_time || [];
      drill_log.historical_time = drill_log.historical_time || [];
    } catch (err) {
      if (err.message.match(/no object found/)) {
        await Dialog.confirm({
          message:
            "This job is unavailable on your device.  Please reconnect to the Internet and sync this job to your device.",
          title: "Data Unavailable",
          type: "is-danger",
          confirmText: "Ok",
          showCancel: false
        });

        page.show("/");

        return;
      }
    }

    readonly = drill_log.suspended;

    console.log("mdl-ui: job time: drill log", drill_log);

    heartbeat_unsubscribe = heartbeat.subscribe(load);

    loading = false;
  }

  async function on_destroy() {
    heartbeat_unsubscribe();
  }

  function on_checklist_button_click(time, read_only, end) {
    const type = cache.lookups.time_types[time.time_type_uuid];
    const raw_checklist = SafetyChecklist.raw(
      end ? type.end_checklist_type : type.safety_checklist_type
    );
    is_clock_out_checklist = end;
    if (raw_checklist !== null) {
      timesheet = time;
      checklist_questions = raw_checklist;
      if (read_only) {
        is_view_safety_checklist_modal_active = true;
      } else {
        is_safety_checklist_modal_active = true;
      }
    }
  }

  async function on_checklist_modal_closed({ detail: { complete } }) {
    if (complete) {
      if (is_clock_out_checklist === true) {
        await stop_time();
      } else {
        await create_time();
      }
      is_clock_out_checklist = false;
      load();
    }
  }

  function on_edit_button_click(time) {
    if (time !== null) {
      edited_time = time;
      is_editor_modal_active = true;
    }
  }

  function on_create_button_click() {
    is_create_modal_active = true;
  }

  function on_editor_modal_closed({ detail: { modified } }) {
    if (modified) {
      load();
    }
  }

  async function create_time() {
    try {
      delete new_time.is_active_end_time_valid;
      new_time = await models.time_sheet_entry.create(new_time, {
        strategy,
        sync: { to: "indexed" }
      });
      if (checklist_answers && checklist_answers.items) {
        checklist_answers = await models.safety_checklist.create(
          checklist_answers,
          {
            strategy
          }
        );
        new_time.safety_checklist_uuid = checklist_answers.uuid;
        await models.time_sheet_entry.update(new_time.uuid, new_time, {
          strategy,
          sync: { to: "indexed" }
        });
      }

      if (!$heartbeat) {
        await models.sync_task.update(new_time.uuid, {
          uuid: new_time.uuid,
          model: "time_sheet_entry",
          operation: "create",
          object: new_time
        });
        if (new_time.safety_checklist_uuid) {
          await models.sync_task.update(new_time.safety_checklist_uuid, {
            uuid: new_time.safety_checklist_uuid,
            model: "safety_checklist",
            operation: "create",
            object: checklist_answers
          });
        }
      } else {
        await sync.synchronize_time_sheet(new_time);
      }

      new_time = {};
      checklist_answers = {};
      load();
    } catch (err) {
      console.log(err);
      if (err.code === IINAError.Conflict) {
        await Dialog.confirm({
          message:
            "This person has an open timesheet. Clock out of that one before clocking in to this one.",
          title: "Clock Out First",
          type: "is-danger",
          confirmText: "Ok",
          showCancel: false
        });
      }
    }
  }

  async function stop_time() {
    try {
      if (checklist_answers && checklist_answers.items) {
        checklist_answers = await models.safety_checklist.create(
          checklist_answers,
          {
            strategy
          }
        );
        edited_time.end_checklist_uuid = checklist_answers.uuid;
      }
      delete edited_time.is_active_end_time_valid;
      await models.time_sheet_entry.update(edited_time.uuid, edited_time, {
        strategy,
        sync: { to: "indexed" }
      });
      if (!$heartbeat) {
        await models.sync_task.update(edited_time.uuid, {
          uuid: edited_time.uuid,
          model: "time_sheet_entry",
          operation: "update",
          object: edited_time
        });
        if (edited_time.end_checklist_uuid) {
          await models.sync_task.update(edited_time.end_checklist_uuid, {
            uuid: edited_time.end_checklist_uuid,
            model: "safety_checklist",
            operation: "update",
            object: checklist_answers
          });
        }
      } else {
        await sync.synchronize_time_sheet(edited_time);
      }
      edited_time = {};
      checklist_answers = {};
      is_clock_out_checklist = false;
      load();
    } catch (err) {
      console.log(err);
      if (err.code) {
        await Dialog.confirm({
          message: `The API returned a code of ${err.code}`,
          title: "API Error",
          type: "is-danger",
          confirmText: "Ok",
          showCancel: false
        });
      }
    }
  }

  async function on_create_modal_closed() {
    validate();
    if (is_time_valid) {
      show_errors = false;

      const type = cache.lookups.time_types[new_time.time_type_uuid];
      const raw_checklist = SafetyChecklist.raw(type.safety_checklist_type);
      const is_first_time = await models.safety_checklist.is_first_time_today(new_time.user_uuid, type.safety_checklist_type);

      if (is_first_time && raw_checklist !== null) {
        const items = SafetyChecklist.indexed(type.safety_checklist_type);
        checklist_answers = {
          items,
          creator: user.email,
          updator: user.email,
          user_uuid: new_time.user_uuid,
          type: type.safety_checklist_type
        };

        timesheet = new_time;
        checklist_questions = raw_checklist;
        is_safety_checklist_modal_active = true;
      } else {
        create_time();
      }
    } else {
      console.log("not a valid time");
    }
  }

  async function end_time(time) {
    edited_time = time;
    validate(edited_time);

    if (is_time_valid && !!edited_time.end_time) {
      show_errors = false;

      const type = cache.lookups.time_types[edited_time.time_type_uuid];
      const raw_checklist = SafetyChecklist.raw(type.end_checklist_type);
      const is_first_time = await models.safety_checklist.is_first_time_today(edited_time.user_uuid, type.end_checklist_type);
      if (is_first_time && raw_checklist !== null) {
        const items = SafetyChecklist.indexed(type.end_checklist_type);
        checklist_answers = {
          items,
          creator: user.email,
          updator: user.email,
          user_uuid: edited_time.user_uuid,
          type: type.end_checklist_type
        };

        timesheet = edited_time;
        checklist_questions = raw_checklist;
        is_safety_checklist_modal_active = true;
        is_clock_out_checklist = true;
      } else {
        stop_time();
      }
    } else {
      console.log("not a valid time");
    }
  }

  function validate(time = new_time) {
    show_errors = true;

    is_time_valid =
      !!time.user_uuid &&
      !!time.time_type_uuid &&
      !!time.vehicle_uuid &&
      !!time.start_time;
  }

  async function load() {
    lookups = cache.lists;
    if ($heartbeat) {
      await sync.push_time_sheets();
      await sync.push_safety_checklists();
    }

    strategy = $heartbeat ? ["backend"] : ["indexed"];

    // Get a list of users the current user is allowed to clock in
    // and make an array of their uuids.
    users = await models.time_sheet_entry.allowed_users(user);
    const user_uuids = users.map((u) => u.uuid);

    // Get a list of all the time_sheet_entries
    time_sheet_entries = await models.time_sheet_entry.retrieve_all();
    // Only show those for this job and only for allowed users
    time_sheet_entries = time_sheet_entries.filter(
      (tse) =>
        tse.log_uuid === drill_log.uuid && user_uuids.includes(tse.user_uuid)
    );

    // Split it up into active and historical times
    drill_log.historical_time = time_sheet_entries.filter(
      (tse) => !!tse.end_time
    );
    drill_log.historical_time.forEach((time) => {
      const entry = time;
      entry.editable = models.time_sheet_entry.is_editable(entry, user);
    });

    drill_log.active_time = time_sheet_entries.filter((tse) => !tse.end_time);
    drill_log.active_time = drill_log.active_time.map((at) => {
      const time = { ...at };
      time.end_time = DateTime.round_time_quarter_hour();
      time.is_active_end_time_valid = DateTime.is_time_entry_valid(
        time.start_time,
        time.end_time
      );
      return time;
    });

    // Create the shell of a new time_sheet_entry
    new_time.log_uuid = drill_log.uuid;
    if (!new_time.user_uuid) {
      new_time.user_uuid = user.uuid;
    }

    if (!new_time.start_time) {
      new_time.start_time = DateTime.round_time_quarter_hour();
    }
  }
</script>

<style>
th {
  text-align: left;
}

button {
  cursor: pointer;
}

:root {
  --mdl-heading: 0deg;
  --mdl-popover-heading: 0deg;
}

@media screen and (max-width: 768px) {
  .columns .columns.is-mobile .column {
    padding-top: 0;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy9sb2dzL3RpbWUuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGdCQUFnQjtBQUNsQjs7QUFFQTtFQUNFLGVBQWU7QUFDakI7O0FBRUE7RUFDRSxtQkFBbUI7RUFDbkIsMkJBQTJCO0FBQzdCOztBQUVBO0VBQ0U7SUFDRSxjQUFjO0VBQ2hCO0FBQ0YiLCJmaWxlIjoic3JjL3BhZ2VzL2xvZ3MvdGltZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbnRoIHtcbiAgdGV4dC1hbGlnbjogbGVmdDtcbn1cblxuYnV0dG9uIHtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG46cm9vdCB7XG4gIC0tbWRsLWhlYWRpbmc6IDBkZWc7XG4gIC0tbWRsLXBvcG92ZXItaGVhZGluZzogMGRlZztcbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgLmNvbHVtbnMgLmNvbHVtbnMuaXMtbW9iaWxlIC5jb2x1bW4ge1xuICAgIHBhZGRpbmctdG9wOiAwO1xuICB9XG59XG4iXX0= */</style>

<SafetyChecklistModal
  {checklist_questions}
  {timesheet}
  {checklist_answers}
  {strategy}
  {lookups}
  bind:is_safety_checklist_modal_active
  on:close={on_checklist_modal_closed} />

<ViewSafetyChecklistModal
  {is_clock_out_checklist}
  {checklist_questions}
  {timesheet}
  bind:is_view_safety_checklist_modal_active
  on:close={on_checklist_modal_closed} />

<TimeEditorModal
  {new_time}
  bind:is_create_modal_active
  on:close={on_create_modal_closed} />

<TimeEditorModal
  {edited_time}
  bind:is_editor_modal_active
  on:close={on_editor_modal_closed} />

{#if loading}
  <div class="modal is-active">
    <div class="modal-content">
      <div class="box">
        <h1 class="title">Loading Time Data...</h1>

        <progress class="progress is-large is-primary" />
      </div>
    </div>
  </div>
{/if}

<nav class="level">
  <div class="level-left">
    <div class="level-item">
      <h1 class="title">Time Log</h1>
      {#if readonly}
        <p
          class="ml-2 has-tooltip-top has-tooltip-arrow"
          data-tooltip="This job has been suspended and is in read-only mode.">
          <Icon class="has-text-danger" icon={faFlag} />
        </p>
      {/if}
    </div>
  </div>
</nav>

<div>
  <p class="subtitle">
    <strong>Job Number:</strong>
    #{drill_log ? drill_log.job_number : ""}
  </p>
</div>
<div>
  <p class="subtitle">
    <strong>Site Name:</strong>
    {drill_log ? drill_log.site_name : ""}
  </p>
</div>

<section class="section">
  <div class="container">
    <p class="title">Start Work</p>
    <button
      class="button is-danger"
      disabled={is_create_modal_active}
      on:click={() => on_create_button_click()}>
      Start New Work
    </button>
  </div>
</section>

<section class="section">
  <div class="container">
    <p class="title">Active Work</p>
    <table class="table stack is-fullwidth is-hoverable time-five-headings">
      <thead>
        <tr>
          <th class="has-text-left">Name</th>
          <th class="has-text-left">Role</th>
          <th class="has-text-left">Vehicle</th>
          <th class="has-text-left">Start</th>
          <th class="has-text-left">Stop</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {#if loading}
          <tr>
            <td colspan="3">
              <div class="button is-fullwidth is-light is-loading" />
            </td>
          </tr>
        {:else if drill_log.active_time && drill_log.active_time.length}
          {#each drill_log.active_time as time (time.uuid)}
            <tr>
              <td>
                {(cache.lookups.users[time.user_uuid] && cache.lookups.users[time.user_uuid].name) || "unknown"}
              </td>
              <td>
                {(cache.lookups.time_types[time.time_type_uuid] && cache.lookups.time_types[time.time_type_uuid].name) || ""}
                {#if time.safety_checklist_uuid}
                  <button
                    class="button is-small"
                    on:click={() => on_checklist_button_click(time, true)}>
                    <Icon class="has-text-success" icon={faCheckCircle} />
                  </button>
                {/if}
              </td>
              <td>
                {(cache.lookups.vehicles[time.vehicle_uuid] && cache.lookups.vehicles[time.vehicle_uuid].name) || ""}
              </td>
              <td class="is-hidden-handset">
                {DateTime.format_american_date_and_time(time.start_time)}
              </td>
              <td>
                <DateTimeField
                  {show_errors}
                  on:time_validation={({ detail: { is_time_valid } }) => { // eslint-disable-line no-shadow
                    time.is_active_end_time_valid = is_time_valid;
                  }}
                  start_time={time.start_time}
                  bind:value={time.end_time}
                  no_future={true} />
              </td>
              <td class="is-button-column">
                <button
                  class="button is-danger"
                  disabled={!time.is_active_end_time_valid}
                  on:click={() => end_time(time)}>
                  Stop Work
                </button>
              </td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</section>

<section class="section">
  <div class="container">
    <p class="title">Historical Work</p>
    <table class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th class="has-text-left">Name</th>
          <th class="has-text-left">Role</th>
          <th class="has-text-left">Vehicle</th>
          <th class="has-text-left">Start</th>
          <th class="has-text-left">Stop</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {#if loading}
          <tr>
            <td colspan="3">
              <div class="button is-fullwidth is-light is-loading" />
            </td>
          </tr>
        {:else if drill_log.historical_time && drill_log.historical_time.length}
          {#each drill_log.historical_time as time (time.uuid)}
            <tr>
              <td>
                {(cache.lookups.users[time.user_uuid] && cache.lookups.users[time.user_uuid].name) || "unknown"}
              </td>
              <td>
                <div class="columns is-variable is-1 is-widescreen">
                  {#if cache.lookups.time_types[time.time_type_uuid] && cache.lookups.time_types[time.time_type_uuid].name}
                    <div class="column">
                      {cache.lookups.time_types[time.time_type_uuid].name}
                    </div>
                  {/if}
                  {#if time.safety_checklist_uuid || time.end_checklist_uuid}
                    <div class="column is-narrow">
                      <div class="columns is-variable is-1 is-mobile">
                        {#if time.safety_checklist_uuid}
                          <div class="column is-narrow">
                            <button
                              class="button is-small"
                              on:click={() => on_checklist_button_click(time, true)}>
                              <Icon
                                class="has-text-success"
                                icon={faCheckCircle} />
                            </button>
                          </div>
                        {/if}
                        {#if time.end_checklist_uuid}
                          <div class="column is-narrow">
                            <button
                              class="button is-small"
                              on:click={() => on_checklist_button_click(time, true, true)}>
                              <Icon
                                class="has-text-danger"
                                icon={faCheckCircle} />
                            </button>
                          </div>
                        {/if}
                      </div>
                    </div>
                  {/if}
                </div>
              </td>
              <td>
                {(cache.lookups.vehicles[time.vehicle_uuid] && cache.lookups.vehicles[time.vehicle_uuid].name) || ""}
              </td>
              <td>{DateTime.format_american_date_and_time(time.start_time)}</td>
              <td>{DateTime.format_american_date_and_time(time.end_time)}</td>
              <td class="is-button-column">
                <button
                  class="button is-danger"
                  disabled={!time.editable}
                  on:click={() => on_edit_button_click(time)}>
                  Edit Work
                </button>
              </td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</section>
