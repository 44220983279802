<script>
  import "bulma/css/bulma.css";
  import "mdlui/mdlui.css";
  import "mdlui/tables.scss";
  import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";
  import { Snackbar } from "svelma";
  import { setContext } from "svelte";
  import { writable } from "svelte/store";
  import page from "page";

  // components
  import Interstitial from "mdlui/components/interstitial";
  import Navigation from "mdlui/components/navigation";

  // pages
  import LoginPage from "mdlui/pages/login";
  import DashboardPage from "mdlui/pages/dashboard";
  import ReportsPage from "mdlui/pages/reports";
  import SettingsPage from "mdlui/pages/settings";
  import LogGraphPage from "mdlui/pages/logs/graph";
  import LogPrintPage from "mdlui/pages/logs/print";
  import LogTimePage from "mdlui/pages/logs/time";
  import TimesheetsPage from "mdlui/pages/timesheets";

  // services
  import BackendService from "mdlui/services/backend";
  import IndexedService from "mdlui/services/indexed";
  import SyncService from "mdlui/services/sync";
  import MDLUIWorker from "mdlui/workers/root";

  // models
  import Models from "mdlui/models";

  // shared libraries
  import { Workflow } from "mdl-shared";

  export let workbox;

  const current_page = {};
  let backend_metadata = null;
  let is_logged_in = false;
  let has_data_been_loaded = false;
  let user = null;

  // MCCDL-119
  //
  // This bit of reactive magic will automatically redirect the user to
  // the dashboard if they are logged in and currently viewing the login
  // page.  It works to route the user away from the login page as soon
  // as authentication has been completed.
  //
  // Since the OAuth code we received from Google is present in the URL,
  // if we don't move away from it ASAP, the LoginPage component could
  // be mounted again, which may cause a second authentication attempt.
  // If that happens, the second attempt will fail with a HTTP 403 from
  // the backend.

  $: if (is_logged_in && current_page.component === LoginPage) {
    page.show("/");
  }

  const RS = String.fromCharCode(30);

  const message = writable();
  const backnav = writable();
  const history = writable();
  const list_is_outdated = writable();
  const heartbeat = writable(true);

  const worker = new MDLUIWorker();
  const backend = new BackendService({
    handlers: {
      unauthorized: () => {
        console.log("mdl-ui: backend: unauthorized");
        message.set({
          intent: "info",
          header: "Logged Out",
          body: `Your session has expired.  Please log in again to continue
            using the Drill Log System.`
        });
        backend.logout();
      },
      forbidden: () => {
        console.log("mdl-ui: backend: forbidden");
        Snackbar.create({
          type: "is-warning",
          message: "You do not have permission to perform that action."
        });
      },
      network_error: () => {
        console.log("mdl-ui: backend: network error");
        Snackbar.create({
          type: "is-danger",
          message: "Unable to contact the server."
        });
      },
      application_error: () => {
        console.log("mdl-ui: backend: application error");
        Snackbar.create({
          type: "is-danger",
          message: "Error exchanging data with the server."
        });
      },
      login: ({ user: _user, date_login, storage }) => {
        console.log("mdl-ui: backend: login");
        is_logged_in = true;
        user = _user;
        user.date_login = date_login;

        // Notify the root web worker that the user has logged in.  This
        // will setup an interval for background sync and also fetch any
        // list data.

        worker.postMessage({
          type: "user-login",
          backend_storage: storage
        });
      },
      logout: () => {
        console.log("mdl-ui: backend: logout");
        is_logged_in = false;
        user = null;

        worker.postMessage({ type: "user-logout" });
        page.show("/login");
      }
    }
  });

  worker.addEventListener("message", on_worker_message);
  worker.postMessage({ type: "init" });

  const indexed = new IndexedService();
  const models = new Models({ backend, indexed });
  const cache = {};
  const sync = new SyncService({ backend, models });
  const workflow = new Workflow();
  const pages = [
    {
      path: "/",
      component: DashboardPage,
      is_auth_required: true
    },
    {
      path: "/login",
      component: LoginPage,
      is_auth_required: false
    },
    {
      path: "/logs/:log_uuid/print",
      component: LogPrintPage,
      is_auth_required: true,
      is_bare: true
    },
    {
      path: "/logs/:log_uuid/graph/:graph_uuid",
      component: LogGraphPage,
      is_auth_required: true
    },
    {
      path: "/logs/:log_uuid/time",
      component: LogTimePage,
      is_auth_required: true
    },
    {
      path: "/reports",
      component: ReportsPage,
      is_auth_required: true
    },
    {
      path: "/settings",
      component: SettingsPage,
      is_auth_required: true,
      capability_required: "settings"
    },
    {
      path: "/timesheets",
      component: TimesheetsPage,
      is_auth_required: true
    }
  ];

  heartbeat.subscribe(on_heartbeat_change);

  setContext("backend", backend);
  setContext("cache", cache);
  setContext("heartbeat", heartbeat);
  setContext("list_is_outdated", list_is_outdated);
  setContext("models", models);
  setContext("message", message);
  setContext("page", page);
  setContext("backnav", backnav);
  setContext("history", history);
  setContext("sync", sync);
  setContext("workbox", workbox);
  setContext("workflow", workflow);
  setContext("get_user", () => user);

  let params = null;

  page.configure({ window });
  pages.forEach(
    ({ path, component, is_auth_required, is_bare, capability_required }) => {
      console.log(`mdl-ui: setting up route for ${path}`);
      page(path, (ctx) => {
        console.log("mdl-ui: route: page", page);
        console.log("mdl-ui: route: path", path);
        console.log("mdl-ui: route: ctx", ctx);

        if ($message) {
          if ($message.seen) {
            message.set(null);
          } else {
            message.set({ ...$message, seen: true });
          }
        }

        params = new URLSearchParams(ctx.querystring);

        // Merge route parameters and search parameters for a unified
        // interface similar to Express.  Route parameters should always
        // override search parameters.

        if (ctx.params) {
          Object.entries(ctx.params).forEach((pair) => params.set(...pair));
        }

        // Redirect depending upon auth requirements and current state.

        if (is_auth_required) {
          if (!is_logged_in) {
            page.redirect("/login");
            return;
          }
        } else if (is_logged_in) {
          page.redirect("/");
          return;
        }

        if (
          capability_required &&
          !user.capabilities.includes(capability_required)
        ) {
          page.redirect("/");
          return;
        }

        if (page.current === "/") {
          backnav.set(null);
          history.set(null);
        }

        history.update((old_history) =>
          old_history && old_history.backnav
            ? old_history.previous
            : { previous: old_history, path: page.current }
        );

        current_page.component = component;
        current_page.is_bare = is_bare;
      });
    }
  );
  page.start();

  async function on_heartbeat_change(heartbeat_ok) {
    if (heartbeat_ok === false && has_data_been_loaded === false) {
      // We don't have a heartbeat and we haven't loaded any data.  We
      // can source what data we have from the IndexedDB storage layer.

      const strategy = ["indexed"];
      const drills = await models.list.drill.retrieve_all({ strategy });
      const subdrills = await models.list.subdrill.retrieve_all({ strategy });
      const drillers = await models.list.driller.retrieve_all({ strategy });
      const materials = await models.list.hole_material.retrieve_all({
        strategy
      });
      const sizes = await models.list.hole_size.retrieve_all({ strategy });
      const time_types = await models.time_type.retrieve_all({ strategy });
      const users = await models.user.retrieve_all({ strategy });
      const vehicles = await models.list.vehicle.retrieve_all({ strategy });

      const create_lookup_object = (list) =>
        list.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});

      Object.assign(cache, {
        lists: {
          drills,
          subdrills,
          drillers,
          materials,
          sizes,
          time_types,
          users,
          vehicles
        },
        lookups: {
          drills: create_lookup_object(drills),
          subdrills: create_lookup_object(subdrills),
          drillers: create_lookup_object(drillers),
          materials: create_lookup_object(materials),
          sizes: create_lookup_object(sizes),
          time_types: create_lookup_object(time_types),
          users: create_lookup_object(users),
          vehicles: create_lookup_object(vehicles)
        }
      });

      has_data_been_loaded = true;
    }
  }

  function on_sync_data_received(data) {
    // The background web worker will periodically poll the backend for
    // up to date list data.  This should be a fairly small set of data,
    // so there's no reason to require asynchronous lookups against the
    // backend or IndexedDB for accessing it.  Every time the background
    // worker notifies the application of new list data, throw it into a
    // cache context object so it's immediately available to the rest of
    // the application for synchronous lookups.
    //
    // We could use a Svelte store so that the UI updates automatically,
    // but it might introduce some odd lag or delay every 60s.  I'm not
    // sure that dynamically updating dropdowns while the user is using
    // them is the best UX, either.  So let's not do that.

    // We need the ability to uniquely identify a material in drop-down
    // lists by code and name.  This mirrors what we do in the materials
    // model.

    data.lists.materials.forEach((material) => {
      // eslint-disable-next-line no-param-reassign
      material.id = [material.code, material.name].join(RS);
    });

    Object.assign(cache, data);

    // Initially, I was going to set is_logged_in here, but that's not
    // strictly accurate.  Instead, we'll use two flags and check both
    // of them blow in the markup when deciding to flip the UI to the
    // logged in display.

    has_data_been_loaded = true;
  }

  async function on_worker_message(event) {
    console.log("mdl-ui: root: message", event);

    if (event.data && event.data.type) {
      switch (event.data.type) {
        case "heartbeat-pong":
          backend.set_availability(true);
          heartbeat.set(true);

          backend_metadata = {
            api_version: event.data.api_version,
            heartbeat_timestamp: new Date().toISOString()
          };

          await models.metadata.update("backend", backend_metadata);

          break;

        case "heartbeat-fail":
          backend.set_availability(false);
          heartbeat.set(false);
          break;

        case "sync-stopped":
          if (event.data.result === "success" && event.data.data) {
            on_sync_data_received(event.data.data);
          }
          break;

        default:
          break;
      }
    }
  }
</script>

<style>
/* Our navigation bar is fixed, so it does not occupy any space in the
 * document flow.  We'll need to make some extra space for it. */

.section {
  padding-top: 5em;
}

/* This min-width needs to match the min-width in the loading
 * interstitial on the login page to produce a seamless transition
 * from the login page to the loading screen.
 */

button {
  min-width: 5em;
}

@media screen and (max-width: 1024px) {
  .section.mdl-has-banner {
    padding-top: 7em;
  }
}

@media print {
  .container,
  .section {
    margin: 0;
    padding: 0;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tZGx1aS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBO2dFQUNnRTs7QUFFaEU7RUFDRSxnQkFBZ0I7QUFDbEI7O0FBRUE7OztFQUdFOztBQUVGO0VBQ0UsY0FBYztBQUNoQjs7QUFFQTtFQUNFO0lBQ0UsZ0JBQWdCO0VBQ2xCO0FBQ0Y7O0FBRUE7RUFDRTs7SUFFRSxTQUFTO0lBQ1QsVUFBVTtFQUNaO0FBQ0YiLCJmaWxlIjoic3JjL21kbHVpLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLyogT3VyIG5hdmlnYXRpb24gYmFyIGlzIGZpeGVkLCBzbyBpdCBkb2VzIG5vdCBvY2N1cHkgYW55IHNwYWNlIGluIHRoZVxuICogZG9jdW1lbnQgZmxvdy4gIFdlJ2xsIG5lZWQgdG8gbWFrZSBzb21lIGV4dHJhIHNwYWNlIGZvciBpdC4gKi9cblxuLnNlY3Rpb24ge1xuICBwYWRkaW5nLXRvcDogNWVtO1xufVxuXG4vKiBUaGlzIG1pbi13aWR0aCBuZWVkcyB0byBtYXRjaCB0aGUgbWluLXdpZHRoIGluIHRoZSBsb2FkaW5nXG4gKiBpbnRlcnN0aXRpYWwgb24gdGhlIGxvZ2luIHBhZ2UgdG8gcHJvZHVjZSBhIHNlYW1sZXNzIHRyYW5zaXRpb25cbiAqIGZyb20gdGhlIGxvZ2luIHBhZ2UgdG8gdGhlIGxvYWRpbmcgc2NyZWVuLlxuICovXG5cbmJ1dHRvbiB7XG4gIG1pbi13aWR0aDogNWVtO1xufVxuXG5AbWVkaWEgc2NyZWVuIGFuZCAobWF4LXdpZHRoOiAxMDI0cHgpIHtcbiAgLnNlY3Rpb24ubWRsLWhhcy1iYW5uZXIge1xuICAgIHBhZGRpbmctdG9wOiA3ZW07XG4gIH1cbn1cblxuQG1lZGlhIHByaW50IHtcbiAgLmNvbnRhaW5lcixcbiAgLnNlY3Rpb24ge1xuICAgIG1hcmdpbjogMDtcbiAgICBwYWRkaW5nOiAwO1xuICB9XG59XG4iXX0= */</style>

{#if is_logged_in}
  {#if has_data_been_loaded}
    {#if current_page.is_bare}
      <svelte:component this={current_page.component} {params} />
    {:else}
      <Navigation {user} {backend_metadata} />
      <section class="container section" class:mdl-has-banner={!$heartbeat}>
        {#if $message}
          <div class="message is-{$message.intent}">
            <div class="message-body">{$message.body}</div>
          </div>
        {/if}
        <svelte:component this={current_page.component} {params} />
      </section>
    {/if}
  {:else}
    <Interstitial subtitle="Loading data...">
      <p class="has-text-centered">
        <button class="button is-large is-dark is-loading" />
      </p>
    </Interstitial>
  {/if}
{:else}
  <svelte:component this={current_page.component} {params} />
{/if}
