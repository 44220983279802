import Model from "mdlui/model";

class UserModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/user" },
        indexed: { indexed, store: "user" }
      }
    });
  }
}

export default UserModel;
