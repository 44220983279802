<script>
  import { Dialog } from "svelma";
  import { createEventDispatcher, tick, onDestroy } from "svelte";
  import Icon from "fa-svelte";
  import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
  import { SafetyChecklist } from "mdl-shared";

  import DropDown from "mdlui/components/forms/dropdown";
  import Radio from "mdlui/components/forms/radio";
  import InputText from "mdlui/components/forms/input-text";

  export let is_safety_checklist_modal_active;
  export let checklist_questions;
  export let checklist_answers;
  export let lookups;

  $: if (is_safety_checklist_modal_active === true) {
    on_open();
  }

  const dispatch = createEventDispatcher();

  let complete = false;
  let content;
  let is_burger_selected = false;
  const standard_options = [
    { name: "Yes", value: "Yes" },
    { name: "No", value: "No" },
    { name: "n/a", value: "n/a" }
  ];

  async function on_open() {
    complete = false;

    Object.keys(checklist_answers.items).forEach((key) => {
      if (checklist_answers.items[key] === null) {
        checklist_answers.items[key] = { answer: {} };
      }
    });

    // Build any dynamic dropdowns
    checklist_questions.sections.forEach(async (section) => {
      section.items.forEach(async (item) => {
        if (item[1][0] === "d" && item[2] === null && item[3]) {
          // Build the list via the collection name
          const lookup = lookups[item[3]];
          // eslint-disable-next-line no-param-reassign
          item[2] = [...lookup];
        }
      });
    });

    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    content.scrollTo(0, 0);
  }

  async function on_burger_selected() {
    is_burger_selected = !is_burger_selected;
  }

  function on_back_button_click() {
    close();
  }

  function on_close_button_click() {
    close();
  }

  function on_modal_close_button_click() {
    close();
  }

  function check_completeness() {
    complete = true;
    Object.keys(checklist_answers.items).forEach((key) => {
      if (!(key.includes("-t") || key.includes("-n"))) {
        if (
          !checklist_answers.items[key].answer ||
          !checklist_answers.items[key].answer.response
        ) {
          complete = false;
        }
      }
    });
    return complete;
  }

  async function close() {
    if (complete) {
      Object.keys(checklist_answers.items).forEach((key) => {
        checklist_answers.items[key] = SafetyChecklist.set_answer(
          checklist_answers.items[key],
          checklist_answers.items[key].answer
        );
      });
      checklist_answers.date_completed = new Date().toISOString();
      is_safety_checklist_modal_active = false;

      document.documentElement.classList.remove("is-clipped");
      document.body.classList.remove("is-clipped");

      dispatch("close", { complete });
    } else {
      const confirm = await Dialog.confirm({
        message:
          "You have not completed your safety checklist. You will not be clocked in.",
        title: "Safety Checklist Incomplete",
        type: "is-danger",
        confirmText: "Ok",
        showCancel: true
      });

      if (confirm) {
        is_safety_checklist_modal_active = false;

        document.documentElement.classList.remove("is-clipped");
        document.body.classList.remove("is-clipped");
      }
    }
  }

  onDestroy(() => {
    if (is_safety_checklist_modal_active) {
      close();
    }
  });
</script>

<style>
.navbar-brand .button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvc2FmZXR5LWNoZWNrbGlzdC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBO0VBQ0UsZ0NBQWdDO0VBQ2hDLGlDQUFpQztBQUNuQzs7QUFFQTtFQUNFLGVBQWU7RUFDZixXQUFXO0VBQ1gsWUFBWTtFQUNaLE1BQU07QUFDUjs7QUFFQTtFQUNFLGFBQWE7RUFDYixZQUFZO0VBQ1osbUJBQW1CO0VBQ25CLHVCQUF1QjtFQUN2QixtQkFBbUI7QUFDckIiLCJmaWxlIjoic3JjL21vZGFscy9zYWZldHktY2hlY2tsaXN0LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLm5hdmJhci1icmFuZCAuYnV0dG9uIHtcbiAgcGFkZGluZy1sZWZ0OiBjYWxjKDAuNzVlbSAtIDFweCk7XG4gIHBhZGRpbmctcmlnaHQ6IGNhbGMoMC43NWVtIC0gMXB4KTtcbn1cblxuLm1kbC1tb2RhbC1uYXZpZ2F0aW9uIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB6LWluZGV4OiA1MDtcbiAgd2lkdGg6IDEwMHZ3O1xuICB0b3A6IDA7XG59XG5cbi5tZGwtbmF2YmFyLXRpdGxlIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1ncm93OiAxO1xuICBtaW4taGVpZ2h0OiAzLjI1cmVtO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cbiJdfQ== */</style>

<div class="modal mdl-modal" class:is-active={is_safety_checklist_modal_active}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <button
          class="button is-white is-medium"
          on:click={on_back_button_click}>
          <Icon icon={faChevronCircleLeft} />
        </button>

        <div class="mdl-navbar-title">
          <h1 class="subtitle">Safety Checklist</h1>
        </div>

        <span
          role="button"
          class="navbar-burger burger"
          class:is-active={is_burger_selected}
          aria-label="menu"
          aria-expanded="false"
          data-target="mdl-log-view-navigation"
          on:click|stopPropagation={on_burger_selected}>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">Safety Checklist</h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      <hr />

      {#if checklist_questions.sections && checklist_answers && checklist_answers.items}
        {#each checklist_questions.sections as section (section.id)}
          <section class="section">
            <div class="container">
              <p class="title mb-5">{section.name}</p>
            </div>
            {#if section.items}
              {#each section.items as item}
                {#if item[1][0] === "t" || item[1][0] === "n"}
                  <InputText
                    label={item[0]}
                    bind:value={checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.response} />
                {:else if item[1][0] === "d"}
                  <DropDown
                    label={item[0]}
                    data={item[2] || standard_options}
                    data_key="name"
                    bind:value={checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.response} />
                {:else}
                  <Radio
                    label={item[0]}
                    data={item[2] || standard_options}
                    data_key="name"
                    is_first_default={false}
                    on:change={check_completeness}
                    bind:value={checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.response} />
                  <InputText
                    label="Note:"
                    bind:value={checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.note} />
                {/if}
              {/each}
            {/if}
          </section>
        {/each}
      {/if}

      <button
        class="modal-close is-large"
        aria-label="close"
        on:click={on_modal_close_button_click} />

      <div class="level-right">
        <div class="buttons is-right">
          <button
            class="button"
            on:click={on_close_button_click}
            disabled={!complete}>
            Save and Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
