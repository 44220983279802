<script>
  import { createEventDispatcher } from "svelte";
  import naturalCompare from "natural-compare";
  import Icon from "fa-svelte";

  export let label;
  export let icon;
  export let data = [];
  export let data_key = "value";
  export let default_value;
  export let placeholder;
  export let tip;
  export let disabled = false;
  export let invalid = false;
  export let invalid_text = false;
  export let loading = false;
  export let custom_sort = null;
  export let value;

  const dispatch = createEventDispatcher();
  let data_sorted;

  $: data_sorted = [...data].sort((a, b) => {
    if (custom_sort !== null) {
      return custom_sort(a, b);
    }

    if (a[data_key] === default_value) {
      return -1;
    }
    if (b[data_key] === default_value) {
      return 1;
    }

    const a_name = a.name || "";
    const b_name = b.name || "";

    return naturalCompare(a_name, b_name);
  });

  // When a selection is made, we'll dispatch an event containing the
  // entire object of the new selection.  We'll also include a fancy
  // callback function that itself accepts a callback function.  This
  // callback can be used to disable the dropdown box while critical
  // asynchronous operations are occurring.

  function on_selection_changed() {
    dispatch("selection", {
      option: data.find((item) => item[data_key] === value),
      spinner: async (work) => {
        loading = true;
        try {
          await work();
        } finally {
          loading = false;
        }
      }
    });
  }
</script>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field">
      <div class="control" class:has-icons-left={icon}>
        <div
          class="select has-tooltip-top has-tooltip-arrow"
          class:is-loading={loading}
          class:is-danger={invalid}
          data-tooltip={tip}>
          <select
            disabled={disabled || loading || data.length === 0}
            required
            bind:value
            on:change={on_selection_changed}>
            {#if placeholder}
              <option disabled selected value={undefined}>{placeholder}</option>
            {/if}
            {#each data_sorted as item}
              {#if item.available !== false || item[data_key] === value}
                <option
                  disabled={item.available === false}
                  selected={item[data_key] === value}
                  value={item[data_key]}>
                  {item.name}
                </option>
              {/if}
            {/each}
          </select>
        </div>
        {#if icon}
          <span class="icon is-small is-left">
            <Icon {icon} />
          </span>
        {/if}
        <p class="help is-danger" class:is-hidden={!invalid || !invalid_text}>
          {invalid_text}
        </p>
      </div>
    </div>
  </div>
</div>
