import { Workbox } from "workbox-window";
import McCallumDrillLogsUI from "./mdlui.svelte";

function on_service_worker_installed(event) {
  console.log(`mdl-ui: service worker installed (isUpdate=${event.isUpdate})`);
}

function on_service_worker_waiting() {
  console.log(`mdl-ui: service worker waiting`);
}

function on_service_worker_activated() {
  console.log(`mdl-ui: service worker activated`);
}

function on_service_worker_controlling() {
  console.log(`mdl-ui: service worker controlling`);
}

let workbox = null;

if (navigator.serviceWorker) {
  workbox = new Workbox("/service-worker.js");

  if (process.env.NODE_ENV === "development") {
    workbox.addEventListener("installed", on_service_worker_installed);
    workbox.addEventListener("waiting", on_service_worker_waiting);
    workbox.addEventListener("activated", on_service_worker_activated);
    workbox.addEventListener("controlling", on_service_worker_controlling);
  }

  workbox
    .register()
    .then((registration) => console.log("mdl-ui: registration", registration));
}

const app = new McCallumDrillLogsUI({
  target: document.body,
  props: { workbox }
});

window.app = app;

export default app;
