/* eslint-disable no-empty-function */

class StorageServiceInterface {
  create() {
    throw new Error(
      `StorageServiceInterface#create: ${this.constructor.name} does not implement this interface`
    );
  }

  retrieve() {
    throw new Error(
      `StorageServiceInterface#retrieve: ${this.constructor.name} does not implement this interface`
    );
  }

  retrieve_all() {
    throw new Error(
      `StorageServiceInterface#retrieve_all: ${this.constructor.name} does not implement this interface`
    );
  }

  search() {
    throw new Error(
      `StorageServiceInterface#search: ${this.constructor.name} does not implement this interface`
    );
  }

  update() {
    throw new Error(
      `StorageServiceInterface#update: ${this.constructor.name} does not implement this interface`
    );
  }

  delete() {
    throw new Error(
      `StorageServiceInterface#delete: ${this.constructor.name} does not implement this interface`
    );
  }
}

export default StorageServiceInterface;
