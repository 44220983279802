const AUTH_TOKEN_KEY = "iina-auth-token";

class IINAClientAuth {
  constructor({ storage }) {
    this.storage = storage;
    this.token = storage.getItem(AUTH_TOKEN_KEY);

    if (this.token) {
      this.claims = IINAClientAuth.parse_claims(this.token);
    }
  }

  static parse_claims(token) {
    const payload = token.split(".")[1];
    let claims = null;

    if (typeof window === "object") {
      // eslint-disable-next-line no-undef
      claims = JSON.parse(atob(payload));
    } else {
      claims = JSON.parse(Buffer.from(payload, "base64").toString());
    }

    return claims;
  }

  clear({ include = [] }) {
    delete this.token;
    delete this.claims;

    [AUTH_TOKEN_KEY, ...include].forEach((key) => this.storage.removeItem(key));
  }

  get_storage() {
    return {
      [AUTH_TOKEN_KEY]: this.storage.getItem(AUTH_TOKEN_KEY)
    };
  }

  get_authz_header() {
    return `Bearer ${this.token}`;
  }

  get_ttl() {
    return this.claims ? this.claims.exp - this.claims.iat : null;
  }

  get_issue_date() {
    return this.claims ? new Date(this.claims.iat * 1000) : null;
  }

  update_token(token) {
    this.claims = IINAClientAuth.parse_claims(token);
    this.token = token;

    this.storage.setItem(AUTH_TOKEN_KEY, token);
  }

  is_stale() {
    if (!this.token) {
      return true;
    }

    const now = Math.floor(new Date().getTime() / 1000);
    const halflife = this.claims.iat + (this.claims.exp - this.claims.iat) / 2;

    return now > halflife;
  }

  is_expired() {
    if (!this.token) {
      return true;
    }

    const now = Math.floor(new Date().getTime() / 1000);

    return now >= this.claims.exp;
  }

  is_valid() {
    return !this.is_expired();
  }
}

export default IINAClientAuth;
