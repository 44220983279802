<script>
  import { Dialog } from "svelma";
  import Icon from "fa-svelte";
  import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

  export let button_text = "Cancel";
  export let disabled;
  export let message =
    "Are you sure you want to cancel? Your changes will not be saved.";
  export let do_we_prompt = null;
  export let close_callback = null;
  export let classes = "";
  export let button_type = null;

  if (do_we_prompt === null || close_callback === null) {
    throw new Error(
      "do_we_prompt and close_callback callbacks must be supplied "
    );
  }

  async function on_cancel_button_click() {
    // Svelma's Dialog uses FA's icon font instead of the svg icons
    // we're using, so icons don't work.

    let should_close = true;

    if (do_we_prompt()) {
      should_close = await Dialog.confirm({
        message,
        title: "Confirm Cancel",
        type: "is-danger",
        confirmText: "Yes, Cancel",
        cancelText: "No, Don't Cancel"
      });
    }

    if (should_close) {
      close_callback();
    }
  }
</script>

<style>
.modal-close:disabled {
  cursor: not-allowed;
}

.back-button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm1zL2NhbmNlbC1idXR0b24uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLG1CQUFtQjtBQUNyQjs7QUFFQTtFQUNFLGdDQUFnQztFQUNoQyxpQ0FBaUM7QUFDbkMiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvZm9ybXMvY2FuY2VsLWJ1dHRvbi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5tb2RhbC1jbG9zZTpkaXNhYmxlZCB7XG4gIGN1cnNvcjogbm90LWFsbG93ZWQ7XG59XG5cbi5iYWNrLWJ1dHRvbiB7XG4gIHBhZGRpbmctbGVmdDogY2FsYygwLjc1ZW0gLSAxcHgpO1xuICBwYWRkaW5nLXJpZ2h0OiBjYWxjKDAuNzVlbSAtIDFweCk7XG59XG4iXX0= */</style>

{#if button_type === "close"}
  <button
    class="modal-close is-large"
    {disabled}
    on:click={on_cancel_button_click}
    aria_label="close" />
{:else if button_type === "back"}
  <button
    class="button is-white is-medium back-button"
    {disabled}
    on:click={on_cancel_button_click}>
    <Icon icon={faChevronCircleLeft} />
  </button>
{:else}
  <button class="button {classes}" {disabled} on:click={on_cancel_button_click}>
    {button_text}
  </button>
{/if}
