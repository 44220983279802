class SyncService {
  constructor({ backend, models }) {
    this.models = models;
    this.backend = backend;
  }

  // SyncService#process_cells was migrated here from its original home
  // in the graph web worker.  Since then, synchronization of bentonite
  // values has been implemented via this new SyncService and sync_task
  // model.  In the interests of consistency, we should probably try to
  // migrate graph cell synchronization to this new service and model.

  async process_cells(graph_uuid, cells) {
    const map = cells.reduce(
      (obj, cell) => ({
        ...obj,
        [[cell.row, cell.column].join(":")]: cell
      }),
      {}
    );

    const graph_data = Object.entries(map).reduce(
      (data, [id, cell]) => ({
        ...data,
        [id]: cell.data
          ? {
              id: cell.id,
              row: cell.row,
              row_index: cell.row_index,
              column: cell.column,
              column_index: cell.column_index,
              ...cell.data
            }
          : null
      }),
      {}
    );

    const results = await this.models.graph.update(
      graph_uuid,
      {
        graph_data,
        date_updated: new Date().toISOString()
      },
      { strategy: ["backend"] }
    );

    const accepted = Object.entries(results.updated)
      .filter(([, was_updated]) => was_updated)
      .map(([id]) => map[id]);

    const rejected = Object.entries(results.updated)
      .filter(([, was_updated]) => !was_updated)
      .map(([id]) => map[id]);

    console.log(`mdl-graph-worker: sync: ${accepted.length} cells accepted`);
    console.log(`mdl-graph-worker: sync: ${rejected.length} cells rejected`);

    await Promise.all(
      Object.entries(results.updated).map(([id, was_updated]) =>
        was_updated
          ? this.models.cell.delete(map[id].uuid)
          : this.models.cell.update(map[id].uuid, { conflict: true })
      )
    );

    return {
      result: rejected.length > 0 ? "partial" : "success",
      synced: accepted.map((cell) => [cell.row_index, cell.column_index]),
      accepted,
      rejected
    };
  }

  // Synchronization task processing.  It's very dumb at the moment; it
  // will fail if date_updated does not agree with the backend.  I want
  // to see some feedback reported to the user with an override button
  // that invokes process_task -> model.update -> backend.post with the
  // ignore_version_check flag that the API supports.
  //
  // I'm rolling with this for now because we need to ship.

  async process_tasks(tasks) {
    await Promise.all(tasks.map((task) => this.process_task(task)));
  }

  async process_task(task) {
    const model = await this.models[task.model];

    try {
      if (task.operation === "create") {
        await model.create(task.object);
      } else if (task.operation === "update") {
        await model.update(task.object.uuid, task.object);
      } else {
        throw new Error(
          `SyncService#process_task: unknown sync task operation ${task.operation} for sync task ${task.uuid}`
        );
      }

      await this.models.sync_task.delete(task.uuid);
    } catch (err) {
      console.error(`Unsyncable local data. Skipping ${task.uuid}`);
      console.log(err);
    }
  }

  // The update_sync_data method will pull down all lightweight sync
  // data and store it in the IndexedDB database.  This includes all
  // list data and last modified timestamps of drill logs and graphs.
  //
  // Larger data sets such as drill logs and graphs currently must be
  // pulled individually using the sychronize_drill_log method.

  async update_sync_data() {
    const sync_parameters = { to: "indexed", prune: true };
    const sync_data = await this.backend.get_sync_data();
    const {
      completed_safety_checklists,
      graph_mtimes,
      drill_logs_mtimes,
      safety_checklists_mtimes,
      time_sheet_entries_mtimes,
      list_data
    } = sync_data;

    // Turn list data into lists.

    const drills = Object.values(list_data.drill);
    const subdrills = Object.values(list_data.subdrill);
    const drillers = Object.values(list_data.drillers);
    const materials = Object.values(list_data.hole_material);
    const sizes = Object.values(list_data.hole_size);
    const time_types = Object.values(list_data.time_type);
    const users = Object.values(list_data.user);
    const vehicles = Object.values(list_data.vehicle);

    // Sync list data.

    await Promise.all([
      this.models.list.drill.sync(drills, sync_parameters),
      this.models.list.subdrill.sync(subdrills, sync_parameters),
      this.models.list.driller.sync(drillers, sync_parameters),
      this.models.list.hole_material.sync(materials, sync_parameters),
      this.models.list.hole_size.sync(sizes, sync_parameters),
      this.models.time_type.sync(time_types, sync_parameters),
      this.models.list.vehicle.sync(vehicles, sync_parameters),
      this.models.user.sync(users, sync_parameters)
    ]);

    // Refresh the recently completed checklists

    await this.models.completed_safety_checklists.clear(sync_parameters);
    await Promise.all([
      ...Object.entries(
        completed_safety_checklists
      ).map(([uuid, completed_checklist]) =>
        this.models.completed_safety_checklists.update(
          uuid,
          completed_checklist
        )
      )
    ]);

    // Update timestamps for logs and graphs.

    await Promise.all([
      ...Object.entries(drill_logs_mtimes).map(([uuid, mtime]) =>
        this.models.timestamp.drill_log.update(uuid, { updated: mtime })
      ),
      ...Object.entries(graph_mtimes).map(([uuid, mtime]) =>
        this.models.timestamp.graph.update(uuid, { updated: mtime })
      ),
      ...Object.entries(safety_checklists_mtimes).map(([uuid, mtime]) =>
        this.models.timestamp.safety_checklist.update(uuid, { updated: mtime })
      ),
      ...Object.entries(time_sheet_entries_mtimes).map(([uuid, mtime]) =>
        this.models.timestamp.time_sheet_entry.update(uuid, { updated: mtime })
      )
    ]);

    const data = {
      lists: {
        drills,
        subdrills,
        drillers,
        materials,
        sizes,
        time_types,
        users,
        vehicles
      },
      lookups: {
        drills: list_data.drill,
        subdrills: list_data.subdrill,
        drillers: list_data.drillers,
        materials: list_data.hole_material,
        sizes: list_data.hole_size,
        time_types: list_data.time_type,
        users: list_data.user,
        vehicles: list_data.vehicle
      }
    };

    return data;
  }

  // The synchronize_drill_log method will push all outstanding drill
  // log data to the backend.  This includes all sync tasks and graph
  // cells in the queue.
  //
  // After flushing all data, the latest drill log and graph data will
  // be pulled from the backend and stored in the IndexedDB database.
  //
  // An object is returned containing the result of the sync along with
  // up-to-date synchronization timestamps.

  async synchronize_drill_log(drill_log) {
    const drill_log_uuid =
      typeof drill_log === "object" ? drill_log.uuid : drill_log;

    console.log(
      `mdl-sync-service: synchronize_drill_log: drill_log_uuid=${drill_log_uuid}`
    );

    // Push any outstanding drill log update tasks.

    console.log("mdl-sync-service: synchronize_drill_log: process sync tasks");

    const drill_log_sync_tasks = await this.models.sync_task.search({
      filters: {
        model_name: "drill_log",
        object_uuid: drill_log_uuid
      }
    });

    drill_log_sync_tasks.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    await this.process_tasks(drill_log_sync_tasks);

    // Pull the latest drill log.

    console.log("mdl-sync-service: synchronize_drill_log: retrieve new log");

    const updated_drill_log = await this.models.drill_log.retrieve(
      drill_log_uuid,
      { sync: { to: "indexed" } }
    );

    const graph_sync_results = await Promise.all(
      updated_drill_log.graphs.map(async (graph_uuid) => {
        console.log(
          `mdl-sync-service: synchronize_drill_log: graph_uuid=${graph_uuid}`
        );

        // Push any outstanding graph update tasks.

        console.log(
          "mdl-sync-service: synchronize_drill_log: process sync tasks"
        );

        const graph_sync_tasks = await this.models.sync_task.search({
          filters: {
            model_name: "graph",
            object_uuid: graph_uuid
          }
        });

        graph_sync_tasks.sort(
          (a, b) => new Date(a.date_created) - new Date(b.date_created)
        );

        await this.process_tasks(graph_sync_tasks);

        // Push any outstanding graph cells updates.

        console.log("mdl-sync-service: synchronize_drill_log: process cells");

        const cells = await this.models.cell.search({
          filters: { graph_uuid }
        });
        const cells_sync_result = await this.process_cells(graph_uuid, cells);

        // Pull the latest graph

        console.log(
          "mdl-sync-service: synchronize_drill_log: retrieve new graph"
        );

        const graph = await this.models.graph.retrieve(graph_uuid, {
          strategy: ["backend"],
          sync: { to: "indexed" }
        });

        return { graph, ...cells_sync_result };
      })
    );

    // Return an object detailing where we stand to the caller.

    console.log("mdl-sync-service: synchronize_drill_log: retrieve timestamps");

    const drill_log_timestamp = await this.models.timestamp.drill_log.retrieve(
      drill_log_uuid
    );
    const graph_timestamps = await Promise.all(
      graph_sync_results.map(({ graph }) =>
        this.models.timestamp.graph.retrieve(graph.uuid)
      )
    );

    const sync_result = {
      drill_log: updated_drill_log,
      graphs: graph_sync_results.reduce(
        (acc, graph_sync_result) => ({
          ...acc,
          [graph_sync_result.graph.uuid]: graph_sync_result
        }),
        {}
      ),
      timestamps: {
        drill_log: drill_log_timestamp,
        graphs: graph_sync_results.reduce(
          (acc, { graph }, i) => ({
            ...acc,
            [graph.uuid]: graph_timestamps[i]
          }),
          {}
        )
      }
    };

    console.log("mdl-sync-service: synchronize_drill_log: result", sync_result);

    return sync_result;
  }

  async push_safety_checklists() {
    console.log("mdl-sync-service: push_safety_checklists: process sync tasks");

    const checklist_sync_tasks = await this.models.sync_task.search({
      filters: {
        model_name: "safety_checklist"
      }
    });

    checklist_sync_tasks.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    await this.process_tasks(checklist_sync_tasks);
  }

  async push_time_sheets() {
    console.log("mdl-sync-service: push_time_sheets: process sync tasks");

    const time_sheet_sync_tasks = await this.models.sync_task.search({
      filters: {
        model_name: "time_sheet_entry"
      }
    });

    time_sheet_sync_tasks.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    await this.process_tasks(time_sheet_sync_tasks);
  }

  async synchronize_time_sheet(time_sheet_entry) {
    const time_sheet_uuid =
      typeof time_sheet_entry === "object"
        ? time_sheet_entry.uuid
        : time_sheet_entry;

    console.log(
      `mdl-sync-service: synchronize_time_sheet: time_sheet_uuid=${time_sheet_uuid}`
    );

    // Push any outstanding time sheet update tasks.
    await this.push_time_sheets();
    await this.push_safety_checklists();

    console.log("mdl-sync-service: synchronize_time_sheet: process sync tasks");

    const time_sheet_sync_tasks = await this.models.sync_task.search({
      filters: {
        model_name: "time_sheet_entry",
        object_uuid: time_sheet_uuid
      }
    });

    time_sheet_sync_tasks.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    await this.process_tasks(time_sheet_sync_tasks);

    console.log(
      "mdl-sync-service: synchronize_time_sheet: retrieve new time sheet"
    );

    const updated_time_sheet = await this.models.time_sheet_entry.retrieve(
      time_sheet_uuid,
      { sync: { to: "indexed" } }
    );
    let updated_safety_checklist;
    let updated_end_checklist;

    if (time_sheet_entry.safety_checklist_uuid) {
      const checklist_sync_tasks = await this.models.sync_task.search({
        filters: {
          model_name: "safety_checklist",
          object_uuid: time_sheet_entry.safety_checklist_uuid
        }
      });
      checklist_sync_tasks.sort(
        (a, b) => new Date(a.date_created) - new Date(b.date_created)
      );

      await this.process_tasks(checklist_sync_tasks);

      console.log(
        "mdl-sync-service: synchronize_time_sheet: retrieve new checklist"
      );

      updated_safety_checklist = await this.models.safety_checklist.retrieve(
        time_sheet_entry.safety_checklist_uuid,
        { sync: { to: "indexed" } }
      );
    }

    if (time_sheet_entry.end_checklist_uuid) {
      const checklist_sync_tasks = await this.models.sync_task.search({
        filters: {
          model_name: "safety_checklist",
          object_uuid: time_sheet_entry.end_checklist_uuid
        }
      });

      checklist_sync_tasks.sort(
        (a, b) => new Date(a.date_created) - new Date(b.date_created)
      );

      await this.process_tasks(checklist_sync_tasks);

      console.log(
        "mdl-sync-service: synchronize_time_sheet: retrieve new end checklist"
      );

      updated_end_checklist = await this.models.safety_checklist.retrieve(
        time_sheet_entry.end_checklist_uuid,
        { sync: { to: "indexed" } }
      );
    }

    // Return an object detailing where we stand to the caller.

    console.log(
      "mdl-sync-service: synchronize_time_sheet: retrieve timestamps"
    );
    let time_sheet_timestamp;

    try {
      time_sheet_timestamp = await this.models.timestamp.time_sheet_entry.retrieve(
        time_sheet_uuid
      );
    } catch (err) {
      time_sheet_timestamp = await this.models.timestamp.time_sheet_entry.update(
        time_sheet_uuid,
        { updated: updated_time_sheet.date_updated }
      );
    }
    console.log("got time sheet timestamp");

    let checklist_timestamp;
    let end_checklist_timestamp;
    if (updated_safety_checklist) {
      try {
        checklist_timestamp = await this.models.timestamp.safety_checklist.retrieve(
          time_sheet_entry.safety_checklist_uuid
        );
      } catch (err) {
        checklist_timestamp = await this.models.timestamp.safety_checklist.update(
          updated_safety_checklist.uuid,
          { updated: updated_safety_checklist.date_updated }
        );
      }
    }

    if (updated_end_checklist) {
      try {
        end_checklist_timestamp = await this.models.timestamp.safety_checklist.retrieve(
          time_sheet_entry.end_checklist_uuid
        );
      } catch (err) {
        checklist_timestamp = await this.models.timestamp.safety_checklist.update(
          updated_end_checklist.uuid,
          { updated: updated_end_checklist.date_updated }
        );
      }
    }

    const sync_result = {
      time_sheet: updated_time_sheet,
      checklist: updated_safety_checklist || undefined,
      end_checklist: updated_end_checklist || undefined,
      timestamps: {
        time_sheet_entry: time_sheet_timestamp,
        safety_checklist: checklist_timestamp || undefined,
        end_checklist: end_checklist_timestamp || undefined
      }
    };

    console.log(
      "mdl-sync-service: synchronize_time_sheet: result",
      sync_result
    );

    return sync_result;
  }
}

export default SyncService;
