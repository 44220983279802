const RS = String.fromCharCode(30);

class PatternSubtotal {
  static calculate(dataset) {
    const results = {};
    Object.values(dataset).forEach((drill_log) => {
      results[drill_log.uuid] = PatternSubtotal.process_drill_log(
        drill_log,
        drill_log.graphs
      );
    });
    return results;
  }

  static process_drill_log(drill_log, graphs) {
    const results = {
      job_number: drill_log.job_number,
      site_name: drill_log.site_name,
      patterns: {}
    };
    const pattern_info = {};
    drill_log.patterns.forEach((pattern) => {
      pattern_info[pattern.pattern] = pattern;
    });
    graphs.forEach((graph) => {
      const { pattern } = graph;
      if (pattern && pattern_info[pattern] !== undefined) {
        if (results.patterns[pattern] === undefined) {
          results.patterns[pattern] = {
            bentonite: 0,
            footage_drilled: 0,
            hole_count: 0,
            hole_size: pattern_info[pattern].hole_size
          };
        }

        if (graph.depth) {
          if (
            results.patterns[pattern].earliest === undefined ||
            results.patterns[pattern].earliest > graph.date_drilled
          ) {
            results.patterns[pattern].earliest = graph.date_drilled;
          }

          if (
            results.patterns[pattern].latest === undefined ||
            results.patterns[pattern].latest < graph.date_drilled
          ) {
            results.patterns[pattern].latest = graph.date_drilled;
          }

          results.patterns[pattern].footage_drilled += parseFloat(graph.depth);
          results.patterns[pattern].hole_count += 1;
        }

        const dimensions = pattern.split("x");
        if (
          dimensions.length === 2 &&
          results.patterns[pattern] &&
          results.patterns[pattern].footage_drilled
        ) {
          results.patterns[pattern].yardage =
            (results.patterns[pattern].footage_drilled *
              parseFloat(dimensions[0]) *
              parseFloat(dimensions[1])) /
            27;
        }
      }
    });

    // Bentonite calculations
    const bentonite_entries = drill_log.bentonite || {};
    Object.entries(bentonite_entries).forEach(
      ([record_indicator, no_of_bags]) => {
        const [, , , b_pattern, ,] = record_indicator.split(RS);

        if (results.patterns[b_pattern] === undefined) {
          results.patterns[b_pattern] = {};
        }

        if (results.patterns[b_pattern].bentonite === undefined) {
          results.patterns[b_pattern].bentonite = 0;
        }
        results.patterns[b_pattern].bentonite += parseInt(no_of_bags, 10);
      }
    );

    return results;
  }
}

module.exports = PatternSubtotal;
