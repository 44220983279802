// iina-client
//
// Synoposis
//
//    const client = new IINAClient({
//      url: process.env.MY_API_URL,
//      version: process.env.MY_APP_VERSION,
//      handlers: {
//        unauthorized: (res) => my_app_redirect_to_login(),
//        forbidden: (res) => my_app_show_forbidden(),
//        session_expired: (res) => my_app_logout(),
//        network_error: (res) => my_app_show_error(),
//        application_error: (res) => my_app_show_error(),
//        unknown_error: (res) => my_app_show_error()
//      }
//    });
//
//    const res = await client.get("version");
//    const res = await client.post("auth/login", {
//      body: {
//        username: "me@example.com",
//        password: "mypassword"
//      }
//    });

import IINAClientAuth from "iina-client-auth";

const CLIENT_IDENTIFIER_KEY = "iina-identifier";

const is_node =
  typeof process === "object" &&
  typeof process.release === "object" &&
  process.release.name === "node";

const IINAError = {
  UnknownError: 0,
  Unauthorized: 1,
  Forbidden: 2,
  Conflict: 3,
  NetworkError: 4,
  ApplicationError: 5
};

const network_error_patterns = [
  /NetworkError/, // Firefox
  /The Internet connection appears to be offline/, // Safari
  /Failed to fetch/ // Chrome
];

const nullStorage = {
  getItem() {},
  setItem() {},
  removeItem() {},
  clear() {},
  key() {},
  length: 0
};

class IINAClient {
  constructor({ url, version, fetch, storage, handlers }) {
    if (!(url && version)) {
      throw new Error("IINAClient.constructor: url and version are required");
    }

    this.url = url.replace(/\/*$/, "/"); // ensure one trailing slash
    this.version = version;
    this.fetch = fetch;
    this.storage = storage || nullStorage;
    this.handlers = handlers || {};

    this.auth = new IINAClientAuth({ storage });

    this.headers = {
      "X-IINA-Agent": `platform=${
        is_node ? "node" : "web"
      }; version=${version}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    };

    // Instead of trying to load fetch implementations automatically, we
    // rely on the consumer to pass it to us.  This permits lazy loading
    // of polyfills to keep bundle sizes down.  This is also referred to
    // as code splitting.
    //
    //    https://webpack.js.org/guides/lazy-loading/
    //    https://reacttraining.com/react-router/web/guides/code-splitting

    if (this.fetch === undefined) {
      // eslint-disable-next-line no-undef
      if (typeof window === "object") {
        // eslint-disable-next-line no-undef
        this.fetch = window.fetch.bind(window);
      }
    }

    if (this.fetch === undefined) {
      throw new Error(
        "IINAClient.constructor: Fetch API not found; provide an implementation to the constructor using whatwg-fetch or node-fetch"
      );
    }

    if (this.storage.getItem(CLIENT_IDENTIFIER_KEY)) {
      this.identifier = this.storage.getItem(CLIENT_IDENTIFIER_KEY);
    }
  }

  reset(...args) {
    this.auth.clear(...args);
  }

  // The client identifier is a random value that the client generates
  // and sends to the server.  The identifier is used by the server to
  // issue tokens and to verify that later requests using the token also
  // have the same identifier.

  get_client_identifier() {
    // Unless we have both an existing identifier and a valid auth
    // token, we will want to generate a new unique identifier.

    if (!(this.identifier && this.auth.is_valid())) {
      if (is_node) {
        // eslint-disable-next-line no-undef
        const bytes = crypto.randomBytes(36);

        this.identifier = bytes.toString("base64");
      } else {
        // eslint-disable-next-line no-undef
        const bytes = crypto.getRandomValues(new Uint8Array(36));

        // eslint-disable-next-line no-undef
        this.identifier = btoa(String.fromCharCode(...bytes));

        this.storage.setItem(CLIENT_IDENTIFIER_KEY, this.identifier);
      }
    }

    return this.identifier;
  }

  get_storage() {
    return {
      [CLIENT_IDENTIFIER_KEY]: this.storage.getItem(CLIENT_IDENTIFIER_KEY),
      ...this.auth.get_storage()
    };
  }

  async request(endpoint, args = {}) {
    const url = this.url + endpoint;
    const options = {
      ...args,
      method: args.method || "GET",
      credentials: "same-origin",
      headers: {
        ...this.headers,
        ...args.headers,
        "X-IINA-Identifier": this.get_client_identifier()
      }
    };

    // If this is a request requiring authentication, then check the
    // token and handle it accordingly.

    // eslint-disable-next-line no-lonely-if
    if (args.auth !== false) {
      if (this.auth.is_valid()) {
        options.headers.Authorization = this.auth.get_authz_header();
      } else if (this.handlers.session_expired) {
        this.handlers.session_expired();

        return null;
      }
    }

    // If a request body was passed, we'll helpfully serialize native
    // types (arrays, objects, and booleans) to JSON.  Otherwise, pass
    // it verbatim.

    if (args.body !== null && args.body !== undefined) {
      if (typeof args.body === "object" || typeof args.body === "boolean") {
        options.body = JSON.stringify(args.body);
      } else {
        options.body = args.body;
      }
    }

    // Use the Fetch API to issue the request.

    try {
      const res = await this.fetch(url, options);

      return await this.handle_response(res);
    } catch (_error) {
      console.log("IINAClient#request: error", _error.toString());

      let error = _error;

      if (_error.name !== "IINAError") {
        if (network_error_patterns.find((re) => _error.message.match(re))) {
          error = new Error(error.message);
          error.name = "IINAError";
          error.code = IINAError.NetworkError;
        } else {
          error = new Error(error.message);
          error.name = "IINAError";
          error.code = IINAError.UnknownError;
        }
      }

      return this.handle_error(error, !args.bypass);
    }
  }

  async handle_response(res) {
    const content_type = res.headers.get("Content-Type");
    const is_json =
      content_type && content_type.split(/; */)[0] === "application/json";

    let body = null;

    if (is_json) {
      try {
        body = await res.json();
      } catch (err) {
        body = null;
      }
    } else {
      body = res.blob();
    }

    if (!res.ok) {
      let error;

      if (res.status === 401) {
        error = new Error("HTTP 401");
        error.name = "IINAError";
        error.code = IINAError.Unauthorized;
      } else if (res.status === 403) {
        error = new Error("HTTP 403");
        error.name = "IINAError";
        error.code = IINAError.Forbidden;
      } else if (res.status === 409) {
        error = new Error("HTTP 409");
        error.name = "IINAError";
        error.code = IINAError.Conflict;
      } else if (res.status >= 400) {
        // Most every other 4xx or 5xx response is unexpected, so treat
        // them as general application errors.
        error = new Error(`HTTP ${res.status}`);
        error.name = "IINAError";
        error.code = IINAError.ApplicationError;
      } else {
        error = new Error(`HTTP ${res.status}`);
        error.name = "IINAError";
        error.code = IINAError.UnknownError;
      }

      error.response = res;
      error.body = body;

      throw error;
    }

    if (res.headers.get("X-IINA-Token")) {
      this.auth.update_token(res.headers.get("X-IINA-Token"));
    }

    return body;
  }

  async handle_error(error, should_invoke_handlers) {
    if (should_invoke_handlers) {
      switch (error.code) {
        case IINAError.Unauthorized:
          if (this.handlers.unauthorized) {
            this.handlers.unauthorized();
          }
          break;

        case IINAError.Forbidden:
          if (this.handlers.forbidden) {
            this.handlers.forbidden();
          }
          break;

        case IINAError.NetworkError:
          if (this.handlers.network_error) {
            this.handlers.network_error();
          }
          break;

        case IINAError.ApplicationError:
          if (this.handlers.application_error) {
            this.handlers.application_error();
          }
          break;

        case IINAError.UnknownError:
          if (this.handlers.unknown_error) {
            this.handlers.unknown_error();
          }
          break;

        default:
          break;
      }
    }

    throw error;
  }

  async get(endpoint, args = {}) {
    return this.request(endpoint, { ...args, method: "GET" });
  }

  async post(endpoint, args = {}) {
    return this.request(endpoint, { ...args, method: "POST" });
  }

  async put(endpoint, args = {}) {
    return this.request(endpoint, { ...args, method: "PUT" });
  }

  async patch(endpoint, args = {}) {
    return this.request(endpoint, { ...args, method: "PATCH" });
  }

  async delete(endpoint, args = {}) {
    return this.request(endpoint, { ...args, method: "DELETE" });
  }
}

export { IINAError };
export default IINAClient;
