import * as idb from "idb";

class IndexedService {
  constructor() {
    this.db = idb.openDB("mdl", 11, {
      blocked: () => {
        console.log("IndexedService: BLOCKED", "color: #ff0000");
      },
      blocking: () => {
        console.log("IndexedService: BLOCKING", "color: #ff0000");
      },
      terminated: () => {
        console.log("IndexedService: TERMINATED", "color: #ff0000");
      },
      upgrade: (db, version) => {
        if (version < 1) {
          db.createObjectStore("list.drill", { keyPath: "uuid" });
          db.createObjectStore("drill-log", { keyPath: "uuid" });
          db.createObjectStore("list.driller", { keyPath: "uuid" });
          db.createObjectStore("graph", { keyPath: "uuid" });
          db.createObjectStore("list.hole_material", { keyPath: "uuid" });
          db.createObjectStore("list.hole_size", { keyPath: "uuid" });
          db.createObjectStore("cell", {
            keyPath: "uuid"
          }).createIndex("graph_uuid", "graph_uuid", { unique: false });
        }

        if (version < 2) {
          db.createObjectStore("timestamp.drill-log", { keyPath: "uuid" });
          db.createObjectStore("timestamp.graph", { keyPath: "uuid" });
        }

        if (version < 3) {
          db.createObjectStore("list.subdrill", { keyPath: "uuid" });
          db.createObjectStore("user", { keyPath: "uuid" });
        }

        if (version < 4) {
          const store = db.createObjectStore("sync_task", { keyPath: "uuid" });

          store.createIndex("model_name", "model", { unique: false });
          store.createIndex("object_uuid", "object.uuid", { unique: false });
        }

        if (version < 5) {
          db.deleteObjectStore("timestamp.drill-log");
          db.deleteObjectStore("timestamp.graph");

          db.createObjectStore("metadata");
          db.createObjectStore("timestamp.drill-log");
          db.createObjectStore("timestamp.graph");
        }

        if (version < 6) {
          db.createObjectStore("list.vehicle", { keyPath: "uuid" });
          db.createObjectStore("time_type", { keyPath: "uuid" });
        }

        if (version < 7) {
          db.createObjectStore("time_sheet_entry", { keyPath: "uuid" });
        }

        if (version < 8) {
          db.createObjectStore("safety_checklist", { keyPath: "uuid" });
        }

        if (version < 9) {
          db.createObjectStore("timestamp.safety_checklist");
          db.createObjectStore("timestamp.time_sheet_entry");
        }

        if (version < 10) {
          db.createObjectStore("completed_safety_checklists", {
            keyPath: "uuid"
          });
        }
      }
    });
  }

  get_store(store_name) {
    return {
      get: async (key) => {
        const db = await this.db;

        return db.get(store_name, key);
      },

      get_from_index: async (index, key) => {
        const db = await this.db;

        return db.getFromIndex(store_name, index, key);
      },

      get_all: async () => {
        const db = await this.db;

        return db.getAll(store_name);
      },

      get_all_from_index: async (index, key) => {
        const db = await this.db;

        return db.getAllFromIndex(store_name, index, key);
      },

      set: async (key, val) => {
        const db = await this.db;

        // Anything with a UUID should be using inline keys

        return val.uuid
          ? db.put(store_name, val)
          : db.put(store_name, val, key);
      },

      delete: async (key) => {
        const db = await this.db;

        return db.delete(store_name, key);
      },

      clear: async () => {
        const db = await this.db;

        return db.clear(store_name);
      },

      count: async (...args) => {
        const db = await this.db;

        return db.count(store_name, ...args);
      },

      keys: async () => {
        const db = await this.db;

        return db.getAllKeys(store_name);
      }
    };
  }
}

export default IndexedService;
