<script>
  import { IINAError } from "iina-client";
  import { Dialog } from "svelma";
  import { createEventDispatcher, getContext, tick, onDestroy } from "svelte";
  import Icon from "fa-svelte";
  import {
    faChevronCircleLeft,
    faClock,
    faUser
  } from "@fortawesome/free-solid-svg-icons";

  import DateTime from "mdlui/datetime";
  import DropDown from "mdlui/components/forms/dropdown";
  import DateTimeField from "mdlui/components/forms/datetime";

  export let active;

  const heartbeat = getContext("heartbeat");
  const models = getContext("models");
  const user = getContext("get_user")();
  const sync = getContext("sync");
  const cache = getContext("cache");

  $: if (active === true) {
    on_open();
  }

  // These will get replaced with actual lists
  let users = [];
  let time_types = [];
  const dispatch = createEventDispatcher();
  const modified = false;

  let content;
  let is_burger_selected = false;
  let is_time_valid = false;
  let show_errors = false;
  let strategy = [];
  let time_entry = {};
  let timesheet_entries = [];

  async function start_time() {
    validate();

    if (is_time_valid) {
      show_errors = false;

      try {
        delete time_entry.is_active_end_time_valid;
        const new_time = await models.time_sheet_entry.create(time_entry, {
          strategy,
          sync: { to: "indexed" }
        });

        if (!$heartbeat) {
          await models.sync_task.update(new_time.uuid, {
            uuid: new_time.uuid,
            model: "time_sheet_entry",
            operation: "create",
            object: new_time
          });
        } else {
          await sync.synchronize_time_sheet(new_time.uuid);
        }

        close();
      } catch (err) {
        if (err.code === IINAError.Conflict) {
          await Dialog.confirm({
            message:
              "This person has an open timesheet. Clock out of that one before clocking in to this one.",
            title: "Clock Out First",
            type: "is-danger",
            confirmText: "Ok",
            showCancel: false
          });
        }
      }
    }
  }

  async function end_time(entry) {
    validate(entry);

    if (is_time_valid && !!entry.end_time) {
      show_errors = false;
      delete entry.is_active_end_time_valid;
      await models.time_sheet_entry.update(
        entry.uuid,
        {
          end_time: entry.end_time,
          date_updated: entry.date_updated
        },
        { strategy }
      );

      if (!$heartbeat) {
        await models.sync_task.update(entry.uuid, {
          uuid: entry.uuid,
          model: "time_sheet_entry",
          operation: typeof entry.start_time === "string" ? "update" : "create",
          object: entry
        });
      } else {
        await sync.synchronize_time_sheet(entry.uuid);
      }

      close();
    }
  }

  function validate(entry = time_entry) {
    show_errors = true;

    is_time_valid =
      !!entry.user_uuid && !!entry.time_type_uuid && !!entry.start_time;
  }

  async function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    if ($heartbeat) {
      await sync.push_time_sheets();
      await sync.push_safety_checklists();
    }

    strategy = $heartbeat ? ["backend"] : ["indexed"];

    users = await models.time_sheet_entry.allowed_users(user);
    const user_uuids = users.map((u) => u.uuid);
    time_types = await models.time_type.retrieve_all({ strategy });
    time_types = time_types.filter((tt) => tt.job_time !== true);
    timesheet_entries = await models.time_sheet_entry.retrieve_all({
      strategy,
      sync: { to: "indexed" }
    });
    timesheet_entries = timesheet_entries.filter(
      (tse) =>
        !tse.log_uuid &&
        typeof tse.end_time !== "string" &&
        user_uuids.includes(tse.user_uuid)
    );
    timesheet_entries = timesheet_entries.map((tse) => {
      const time = { ...tse };
      time.end_time = DateTime.round_time_quarter_hour();
      time.time_type_name = cache.lookups.time_types[time.time_type_uuid].name;
      time.user_name =
        (cache.lookups.users[time.user_uuid] &&
          cache.lookups.users[time.user_uuid].name) ||
        "unknown";
      time.is_active_end_time_valid = DateTime.is_time_entry_valid(
        time.start_time,
        time.end_time
      );
      return time;
    });

    if (!time_entry.user_uuid) {
      time_entry.user_uuid = user.uuid;
    }

    if (time_entry.uuid && !time_entry.end_time) {
      time_entry.end_time = DateTime.round_time_quarter_hour();
    }

    if (!time_entry.start_time) {
      time_entry.start_time = DateTime.round_time_quarter_hour();
    }

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    content.scrollTo(0, 0);
  }

  async function on_burger_selected() {
    is_burger_selected = !is_burger_selected;
  }

  function on_back_button_click() {
    close();
  }

  function on_close_button_click() {
    close();
  }

  function on_modal_close_button_click() {
    close();
  }

  function close() {
    active = false;
    time_entry = {};

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close", { modified });
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<style>
th {
  text-align: left;
}

.navbar-brand .button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvZ2VuZXJhbC10aW1lLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSxnQkFBZ0I7QUFDbEI7O0FBRUE7RUFDRSxnQ0FBZ0M7RUFDaEMsaUNBQWlDO0FBQ25DOztBQUVBO0VBQ0UsZUFBZTtFQUNmLFdBQVc7RUFDWCxZQUFZO0VBQ1osTUFBTTtBQUNSOztBQUVBO0VBQ0UsYUFBYTtFQUNiLFlBQVk7RUFDWixtQkFBbUI7RUFDbkIsdUJBQXVCO0VBQ3ZCLG1CQUFtQjtBQUNyQiIsImZpbGUiOiJzcmMvbW9kYWxzL2dlbmVyYWwtdGltZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbnRoIHtcbiAgdGV4dC1hbGlnbjogbGVmdDtcbn1cblxuLm5hdmJhci1icmFuZCAuYnV0dG9uIHtcbiAgcGFkZGluZy1sZWZ0OiBjYWxjKDAuNzVlbSAtIDFweCk7XG4gIHBhZGRpbmctcmlnaHQ6IGNhbGMoMC43NWVtIC0gMXB4KTtcbn1cblxuLm1kbC1tb2RhbC1uYXZpZ2F0aW9uIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB6LWluZGV4OiA1MDtcbiAgd2lkdGg6IDEwMHZ3O1xuICB0b3A6IDA7XG59XG5cbi5tZGwtbmF2YmFyLXRpdGxlIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1ncm93OiAxO1xuICBtaW4taGVpZ2h0OiAzLjI1cmVtO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cbiJdfQ== */</style>

<div class="modal mdl-modal" class:is-active={active}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <button
          class="button is-white is-medium"
          on:click={on_back_button_click}>
          <Icon icon={faChevronCircleLeft} />
        </button>

        <div class="mdl-navbar-title">
          <h1 class="subtitle">General Time Log</h1>
        </div>

        <span
          role="button"
          class="navbar-burger burger"
          class:is-active={is_burger_selected}
          aria-label="menu"
          aria-expanded="false"
          data-target="mdl-log-view-navigation"
          on:click|stopPropagation={on_burger_selected}>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">General Time Log</h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      {#if time_entry === undefined}
        <div class="button is-fullwidth is-light is-loading" />
      {/if}

      <hr />

      <section class="section">
        <div class="container">
          <p class="title">Start Work</p>
          <table
            class="table stack is-fullwidth is-hoverable
            general-time-three-headings">
            <thead>
              <tr>
                <th class="has-text-left">Name</th>
                <th class="has-text-left">Role</th>
                <th class="has-text-left">Start</th>
                {#if time_entry.uuid}
                  <th class="has-text-left">Stop</th>
                {/if}
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="control has-icons-left">
                    <DropDown
                      icon={faUser}
                      data={users}
                      data_key="uuid"
                      placeholder="Select User"
                      bind:value={time_entry.user_uuid} />
                  </div>
                </td>
                <td>
                  <div class="control has-icons-left">
                    <DropDown
                      icon={faClock}
                      data={time_types}
                      data_key="uuid"
                      placeholder="Select Role"
                      bind:value={time_entry.time_type_uuid} />
                  </div>
                </td>
                <td>
                  {#if !time_entry.uuid}
                    <DateTimeField
                      {show_errors}
                      on:time_validation={({ detail: { is_time_valid } }) => {
                        time_entry.is_active_end_time_valid = is_time_valid;
                      }}
                      bind:value={time_entry.start_time}
                      no_future={true} />
                  {:else}
                    {DateTime.format_american_date_and_time(time_entry.start_time)}
                  {/if}
                </td>
                {#if time_entry.uuid}
                  <td>
                    <DateTimeField
                      {show_errors}
                      on:time_validation={({ detail: { is_time_valid } }) => {
                        time_entry.is_active_end_time_valid = is_time_valid;
                      }}
                      start_time={time_entry.start_time}
                      bind:value={time_entry.end_time}
                      no_future={true} />
                  </td>
                {/if}
                <td class="is-button-column">
                  {#if !time_entry.uuid}
                    <button
                      class="button is-danger"
                      disabled={!time_entry.is_active_end_time_valid}
                      on:click={() => start_time()}>
                      Start Work
                    </button>
                  {:else}
                    <button
                      class="button is-danger"
                      disabled={!time_entry.is_active_end_time_valid}
                      on:click={() => end_time(time_entry)}>
                      Stop Work
                    </button>
                  {/if}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <p class="title">Active Work</p>
          <table
            class="table stack is-fullwidth is-hoverable
            general-time-four-headings">
            <thead>
              <tr>
                <th class="has-text-left">Name</th>
                <th class="has-text-left">Role</th>
                <th class="has-text-left">Start</th>
                <th class="has-text-left">Stop</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {#each timesheet_entries as entry (entry.uuid)}
                <tr>
                  <td>{entry.user_name}</td>
                  <td>{entry.time_type_name}</td>
                  <td class="is-hidden-handset">
                    {DateTime.format_american_date_and_time(entry.start_time)}
                  </td>
                  <td>
                    <DateTimeField
                      {show_errors}
                      on:time_validation={({ detail: { is_time_valid } }) => {
                        entry.is_active_end_time_valid = is_time_valid;
                      }}
                      start_time={entry.start_time}
                      bind:value={entry.end_time}
                      no_future={true} />
                  </td>
                  <td class="is-button-column">
                    <button
                      class="button is-danger"
                      disabled={!entry.is_active_end_time_valid}
                      on:click={() => end_time(entry)}>
                      Stop Work
                    </button>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </section>

      <button
        class="modal-close is-large"
        aria-label="close"
        on:click={on_modal_close_button_click} />

      <div class="level-right">
        <div class="buttons is-right">
          <button class="button" on:click={on_close_button_click}>Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
