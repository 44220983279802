import { v4 as uuidv4 } from "uuid";

import StorageServiceInterface from "mdlui/services/storage-interface";

class IndexedStorageService extends StorageServiceInterface {
  constructor({ indexed, store }) {
    super();

    this.store = indexed.get_store(store);

    this.store
      .count()
      .then((count) =>
        console.log(
          `IndexedStorageService#constructor: store ${store} count ${count}`
        )
      );
  }

  // eslint-disable-next-line class-methods-use-this
  is_available() {
    return true;
  }

  async clear() {
    return this.store.clear();
  }

  async create(create_info) {
    // Footgun protection.  This helps avoid those situations where you
    // thought you were inserting an object with one ID and instead you
    // were inserting an object with another ID and then you wake up on
    // Friday to find a few million objects in your IndexedDB instance.
    //
    // If you want to insert an object that already has an ID, use the
    // update method instead.  It is an upsert.
    //
    // XXX: are the different semantics between the Backend and Indexed
    //      storage services problematic?  Confusing?  Does it matter?

    if (create_info.uuid) {
      throw new Error(
        "IndexedStorageService#create: object already has a uuid!"
      );
    }

    // If no UUID exists, generate one and add the object to the store.

    const uuid = uuidv4();
    const obj = { ...create_info, uuid };

    await this.store.set(uuid, obj);

    return obj;
  }

  async retrieve(key) {
    let obj;

    if (typeof key === "object") {
      const key_value_pairs = Object.entries(key);

      if (key_value_pairs.length > 1) {
        throw new Error(
          "IndexedStorageService#retrieve: more than one index lookup specified!"
        );
      }

      obj = await this.store.get_from_index(...key_value_pairs);
    } else {
      obj = await this.store.get(key);
    }

    if (obj === null || obj === undefined) {
      throw new Error("IndexedStorageService#retrieve: no object found!");
    }

    return obj;
  }

  retrieve_all() {
    return this.store.get_all();
  }

  async search({ filters }) {
    const results = await Promise.all(
      Object.entries(filters).map(([key, val]) =>
        this.store.get_all_from_index(key, val)
      )
    );

    return results
      .map((list) => list || [])
      .reduce((accumulator, list) =>
        accumulator
          ? accumulator.filter(
              (obj1) => list.findIndex((obj2) => obj2.uuid === obj1.uuid) > -1
            )
          : list
      );
  }

  // IndexedStorageService#update is an upsert.

  async update(key, update_info) {
    let obj;

    try {
      obj = await this.retrieve(key);
    } catch (err) {
      obj = {};
    }

    Object.assign(obj, update_info);

    return this.store.set(key, obj);
  }

  delete(key) {
    return this.store.delete(key);
  }
}

export default IndexedStorageService;
