<script>
  export let drill_log;
  export let title;
  export let timestamp;
</script>

<style>
@media print {
  div.print-page {
    -moz-column-break-after: page;
         break-after: page;
  }
}

section {
  margin-bottom: 1em;
}

img {
  width: 1.5in;
}

hr {
  height: 0.03125in;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3ByaW50L3BhZ2Uuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFO0lBQ0UsNkJBQWlCO1NBQWpCLGlCQUFpQjtFQUNuQjtBQUNGOztBQUVBO0VBQ0Usa0JBQWtCO0FBQ3BCOztBQUVBO0VBQ0UsWUFBWTtBQUNkOztBQUVBO0VBQ0UsaUJBQWlCO0FBQ25CIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3ByaW50L3BhZ2Uuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG5AbWVkaWEgcHJpbnQge1xuICBkaXYucHJpbnQtcGFnZSB7XG4gICAgYnJlYWstYWZ0ZXI6IHBhZ2U7XG4gIH1cbn1cblxuc2VjdGlvbiB7XG4gIG1hcmdpbi1ib3R0b206IDFlbTtcbn1cblxuaW1nIHtcbiAgd2lkdGg6IDEuNWluO1xufVxuXG5ociB7XG4gIGhlaWdodDogMC4wMzEyNWluO1xufVxuIl19 */</style>

<div class="print-page">
<header class="mb-4">
  <div class="level mb-1">
    <div class="level-left">
      <div class="level-item">
        <h1 class="title">
          Job #{drill_log.job_number}: {drill_log.site_name}
        </h1>
      </div>
      {#if drill_log.suspended}
        <div class="level-item">
          <div class="tags">
            <div class="tag is-danger is-small mb-0">Suspended</div>
          </div>
        </div>
      {/if}
    </div>
    <div class="level-right">
      <div class="level-item">
        <img src="/logo-black-800.png" alt="McCallum Rock Drilling" />
      </div>
    </div>
  </div>

  <hr class="has-background-black my-0" />

  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <h2 class="subtitle">{title}</h2>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <small>{timestamp}</small>
      </div>
    </div>
  </div>
</header>

<section>
  <slot />
</section>
</div>