import Model from "mdlui/model";

class TimeSheetEntryTimestampModel extends Model {
  constructor({ models, indexed }) {
    super({
      models,
      storage: {
        indexed: { indexed, store: "timestamp.time_sheet_entry" }
      }
    });
  }
}

export default TimeSheetEntryTimestampModel;
