<script>
  import { createEventDispatcher } from "svelte";
  import isNil from "lodash/isNil";
  import Decimal from "big.js";
  import Icon from "fa-svelte";
  import {
    faAngleDown,
    faAngleUp,
    faAngleDoubleDown,
    faAngleDoubleUp
  } from "@fortawesome/free-solid-svg-icons";

  export let label;
  export let major = true;
  export let min = 0;
  export let max;
  export let step = 1;
  export let unit;
  export let collapse;
  export let placeholder;
  export let tip;
  export let readonly = false;
  export let invalid = false;
  export let invalid_text = false;
  export let value = 0;

  const dispatch = createEventDispatcher();

  let input_value;
  let decimal_min;
  let decimal_max;
  let decimal_step_minor;
  let decimal_step_major;

  $: decimal_min = min ? new Decimal(min) : null;
  $: decimal_max = max ? new Decimal(max) : null;
  $: decimal_step_minor = new Decimal(step);
  $: decimal_step_major = new Decimal(step).times(10);
  $: format_input_box(value);

  function on_dec_major() {
    update_value(new Decimal(value || 0).minus(decimal_step_major));
  }

  function on_dec_minor() {
    update_value(new Decimal(value || 0).minus(decimal_step_minor));
  }

  function on_inc_major() {
    update_value(new Decimal(value || 0).plus(decimal_step_major));
  }

  function on_inc_minor() {
    update_value(new Decimal(value || 0).plus(decimal_step_minor));
  }

  // When the input element is focused, strip any units from the input
  // box, permitting the user to edit the raw numeric value.

  function on_input_focus() {
    if (value !== undefined) {
      input_value = String(value);
    }
  }

  // When the input element is blurred, update the component's value
  // using the current contents of the input box.

  function on_input_blur() {
    update_value(input_value);
  }

  // Format the input box with any units specified.

  function format_input_box() {
    input_value = isNil(value)
      ? ""
      : (input_value = String(value) + (unit ? ` ${unit}` : ""));
  }

  // The update_value function is where we delegate any changes to the
  // stepper's value.  We'll validate the new value, make any changes,
  // and then reformat the input box accordingly.

  function update_value(new_value) {
    let has_changed = false;

    if (new_value === "" || new_value === undefined) {
      has_changed = value !== new_value;
      value = undefined;
    } else {
      try {
        let decimal_value = new Decimal(new_value);

        if (decimal_max && decimal_value.gt(decimal_max)) {
          decimal_value = decimal_max;
        }

        if (decimal_min && decimal_value.lt(decimal_min)) {
          decimal_value = decimal_min;
        }

        has_changed = !(value && decimal_value.eq(value));

        value = decimal_value.toFixed();
      } catch (err) {
        // If parsing fails, we won't perform any updates.
      }
    }

    format_input_box();

    if (has_changed) {
      dispatch("change");
    }
  }
</script>

<style>
/* Disable double-tap to zoom. */

button {
  touch-action: manipulation;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm1zL3N0ZXBwZXIuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQSxnQ0FBZ0M7O0FBRWhDO0VBQ0UsMEJBQTBCO0FBQzVCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2Zvcm1zL3N0ZXBwZXIuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4vKiBEaXNhYmxlIGRvdWJsZS10YXAgdG8gem9vbS4gKi9cblxuYnV0dG9uIHtcbiAgdG91Y2gtYWN0aW9uOiBtYW5pcHVsYXRpb247XG59XG4iXX0= */</style>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field has-addons">
      {#if major}
        <div
          class="control"
          class:is-hidden-handset={collapse === "handset"}
          class:is-hidden-mobile={collapse === "mobile"}
          class:is-hidden-touch={collapse === "touch"}>
          <div class="has-tooltip-top has-tooltip-arrow" data-tooltip={tip}>
            <button class="button" disabled={readonly} on:click={on_dec_major}>
              <Icon icon={faAngleDoubleDown} />
            </button>
          </div>
        </div>
      {/if}
      <div
        class="control"
        class:is-hidden-handset={collapse === "handset"}
        class:is-hidden-mobile={collapse === "mobile"}
        class:is-hidden-touch={collapse === "touch"}>
        <div class="has-tooltip-top has-tooltip-arrow" data-tooltip={tip}>
          <button class="button" disabled={readonly} on:click={on_dec_minor}>
            <Icon icon={faAngleDown} />
          </button>
        </div>
      </div>
      <div class="control">
        <div class="has-tooltip-top has-tooltip-arrow" data-tooltip={tip}>
          <input
            class="input"
            class:is-danger={invalid}
            type="text"
            {placeholder}
            disabled={readonly}
            on:focus={on_input_focus}
            on:blur={on_input_blur}
            bind:value={input_value} />
        </div>
        <p class="help is-danger" class:is-hidden={!invalid || !invalid_text}>
          {invalid_text}
        </p>
      </div>
      <div
        class="control"
        class:is-hidden-handset={collapse === "handset"}
        class:is-hidden-mobile={collapse === "mobile"}
        class:is-hidden-touch={collapse === "touch"}>
        <div class="has-tooltip-top has-tooltip-arrow" data-tooltip={tip}>
          <button class="button" disabled={readonly} on:click={on_inc_minor}>
            <Icon icon={faAngleUp} />
          </button>
        </div>
      </div>
      {#if major}
        <div
          class="control"
          class:is-hidden-handset={collapse === "handset"}
          class:is-hidden-mobile={collapse === "mobile"}
          class:is-hidden-touch={collapse === "touch"}>
          <div class="has-tooltip-top has-tooltip-arrow" data-tooltip={tip}>
            <button class="button" disabled={readonly} on:click={on_inc_major}>
              <Icon icon={faAngleDoubleUp} />
            </button>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
