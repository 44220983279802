const sheet = {
  id: "t1",
  name: "trucker",
  sections: [
    {
      id: "v2",
      name: "Truck",
      additional_comment: ["Other", "t1"],
      items: [
        ["Overall Comment", "t2"],
        ["Air Brake Check", 10],
        ["Air Compressor", 20],
        ["Air Lines", 30],
        ["Battery", 40],
        ["Back up Alarm", 50],
        ["Belts & Hoses", 60],
        ["Body", 70],
        ["E. Brake", 80],
        ["Brakes, Service", 90],
        ["Clutch", 100],
        ["Coupling Devices", 110],
        ["Defroster/Heater", 120],
        ["Drive Line", 130],
        ["Engine", 140],
        ["Exhaust", 150],
        ["Fifth Wheel", 160],
        ["Fluid Levels", 170],
        ["Frame & Assembly", 180],
        ["Front Axle", 190],
        ["Fuel Tanks", 200],
        ["Lights: head / tail / signal / clearance / markers", 210],
        ["Horn", 220],
        ["Mirrors", 230],
        ["Muffler", 240],
        ["Oil Pressure", 250],
        ["Radiator", 260],
        ["Read End", 270],
        ["Reflectors", 280],
        ["Starter", 290],
        ["Steering", 300],
        ["Seatbelts", 310],
        ["Suspension System", 320],
        ["Tire Chains", 330],
        ["Tires", 340],
        ["Transmission", 350],
        ["Trip Recorder", 360],
        ["Wheel Chocks", 370],
        ["Windows", 380],
        ["Windshield Wipers", 390]
      ]
    },
    {
      id: "vs1",
      name: "Safety",
      items: [
        ["Fire Extinguisher", 10],
        ["Relective Triangles", 20],
        ["First Aid Kit", 30],
        ["Spill Kit", 40],
        ["Shovel/Axe", 50]
      ]
    },
    {
      name: "Trailer",
      id: "v3",
      additional_comment: ["Other", "t1"],
      items: [
        ["Brake Connections", 10],
        ["Brakes, Service", 20],
        ["Coupling Devices", 30],
        ["Coupling (King) Pin", 40],
        ["Landing Gear", 50],
        ["Lights - All", 60],
        ["Relectors/Reflective Tape", 70],
        ["Suspension System", 80],
        ["Tires", 90]
      ]
    },
    {
      name: "Drill Detail",
      id: "dr1",
      optional: true,
      items: [["Drill Id", "d1", null, "drills"]]
    }
  ]
};

exports.sheet = sheet;
