const moment = require("moment");
const Totals = require("../totals.js");

class JobTotal {
  static calculate(dataset) {
    const results = {};
    const totals = Totals.new_totals_object();
    Object.values(dataset).forEach((drill_log) => {
      const totals_for_job = Totals.calculate_one(
        drill_log,
        [[drill_log.graphs]],
        totals
      );
      results[drill_log.uuid] = JobTotal.process_totals(
        drill_log,
        drill_log.graphs,
        totals_for_job
      );
    });
    return results;
  }

  static process_totals(drill_log, graphs, totals_for_job) {
    let earliest = null;
    let latest = null;

    graphs.forEach((cell) => {
      if (cell.date_drilled) {
        if (!earliest || moment(cell.date_drilled).isBefore(earliest)) {
          earliest = cell.date_drilled;
        }
        if (!latest || moment(cell.date_drilled).isAfter(latest)) {
          latest = cell.date_drilled;
        }
      }
    });

    return {
      earliest,
      latest,
      bentonite: totals_for_job.job[drill_log.job_number].bentonite,
      footage: totals_for_job.job[drill_log.job_number].footage_drilled,
      hole_count: totals_for_job.job[drill_log.job_number].hole_count,
      job_number: drill_log.job_number,
      site_name: drill_log.site_name,
      yardage: totals_for_job.job[drill_log.job_number].yardage
    };
  }
}

module.exports = JobTotal;
