<script>
  export let label;
</script>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field">
      <slot />
    </div>
  </div>
</div>
