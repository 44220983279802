import { IINAError } from "iina-client";
import EventEmitter from "events";

import BackendStorageService from "mdlui/services/storage/backend";
import IndexedStorageService from "mdlui/services/storage/indexed";

class Model extends EventEmitter {
  constructor({ models, storage, strategy = ["backend", "indexed"] }) {
    super();

    this.models = models;
    this.storage = {};
    this.strategy = [...strategy];

    if (storage.backend) {
      this.storage.backend = new BackendStorageService(storage.backend);
    } else {
      const index = this.strategy.findIndex(
        (storage_type) => storage_type === "backend"
      );
      if (index > -1) {
        this.strategy.splice(index, 1);
      }
    }

    if (storage.indexed) {
      this.storage.indexed = new IndexedStorageService(storage.indexed);
    } else {
      const index = this.strategy.findIndex(
        (storage_type) => storage_type === "backend"
      );
      if (index > -1) {
        this.strategy.splice(index, 1);
      }
    }
  }

  async clear() {
    await this.storage.indexed.clear();
  }

  async create(create_info, { strategy = this.strategy, sync } = {}) {
    const { data, storage_type } = await this.try_strategy({
      strategy,
      method: "create",
      args: [create_info]
    });

    if (sync && sync.to !== storage_type) {
      await this.sync(data, sync);
    }

    return data;
  }

  async retrieve(id, { strategy = this.strategy, sync } = {}) {
    const { data, storage_type } = await this.try_strategy({
      strategy,
      sync,
      method: "retrieve",
      args: [id]
    });

    if (sync && sync.to !== storage_type) {
      await this.sync(data, sync);
    }

    return data;
  }

  async retrieve_all({ strategy = this.strategy, sync } = {}) {
    const { data, storage_type } = await this.try_strategy({
      strategy,
      method: "retrieve_all",
      args: []
    });

    if (sync && sync.to !== storage_type) {
      await this.sync(data, sync);
    }

    return data;
  }

  async search({ filters, parameters, strategy = this.strategy, sync }) {
    const { data, storage_type } = await this.try_strategy({
      strategy,
      method: "search",
      args: [{ filters, parameters }]
    });

    if (sync && sync.to !== storage_type) {
      await this.sync(data, sync);
    }

    return data;
  }

  async update(id, update_info, { strategy = this.strategy, sync } = {}) {
    const { data, storage_type } = await this.try_strategy({
      strategy,
      method: "update",
      args: [id, update_info]
    });

    if (sync && sync.to !== storage_type) {
      await this.sync(data, sync);
    }

    return data;
  }

  async delete(id, { strategy = this.strategy } = {}) {
    const { data } = await this.try_strategy({
      strategy,
      method: "delete",
      args: [id]
    });

    return data;
  }

  async sync(objects, { to, prune = false }) {
    const array_of_objects = Array.isArray(objects) ? objects : [objects];

    await Promise.all(
      array_of_objects.map((object) =>
        this.storage[to].update(object.uuid, object)
      )
    );

    if (prune) {
      const objects_by_uuid = array_of_objects.reduce(
        (acc, object) => ({ ...acc, [object.uuid]: object }),
        {}
      );

      const existing_objects = await this.storage[to].retrieve_all();

      await existing_objects
        .filter((obj) => !objects_by_uuid[obj.uuid])
        .map((object_to_prune) =>
          this.storage[to].delete(object_to_prune.uuid)
        );
    }

    this.emit("sync", { to, objects: array_of_objects });
  }

  // try_strategy
  //
  // Given a strategy, a method, and a list of arguments, this method
  // will return the first successful result.

  async try_strategy({ strategy, method, args = [] }) {
    // This would be clearer if JavaScript supported await inside of
    // Array#find, but it doesn't.  ESLint complains about for loops
    // too, so let's duplicate the array and try each of the storage
    // types listed in the array by shifting them off while we still
    // have ones to consider.

    const storage_types = [...strategy];
    let success = false;
    let result = null;

    while (success === false && storage_types.length > 0) {
      const storage_type = storage_types.shift();

      // MCCDL-276: if we know this storage type is unavailable, don't
      // even bother with an attempt to interface with it.

      if (!this.storage[storage_type].is_available()) {
        continue; // eslint-disable-line no-continue
      }

      try {
        result = {
          // eslint-disable-next-line no-await-in-loop
          data: await this.storage[storage_type][method](...args),
          storage_type
        };
        success = true;
      } catch (err) {
        console.log(
          `Model#try_strategy: ${storage_type} failed for ${this.constructor.name}`,
          err
        );
        success = false;

        // We'll only fallback to other storage types if we encounter a
        // network error.  So unless the exception is an IINAError with
        // a NetworkError code, we will rethrow.

        if (err.name !== "IINAError" || err.code !== IINAError.NetworkError) {
          throw err;
        }
      }
    }

    // Give up the ghost if nothing we tried was successful.

    if (success === false) {
      throw new Error(
        `Model#try_strategy: strategy [${strategy.join(", ")}] failed for ${
          this.constructor.name
        }#${method}`
      );
    }

    return result;
  }
}

export default Model;
