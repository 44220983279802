<script>
  import { getContext, onMount } from "svelte";
  import naturalCompare from "natural-compare";
  import {
    faGlobeAmericas,
    faFile,
    faUser
  } from "@fortawesome/free-solid-svg-icons";

  import Info from "mdlui/components/forms/info";
  import TextInput from "mdlui/components/forms/input-text";
  import HolePatterns from "mdlui/components/forms/hole-patterns";
  import TagSelect from "mdlui/components/forms/tag-select";
  import Switch from "mdlui/components/forms/switch";
  import TextBox from "mdlui/components/forms/textbox";

  export let drill_log_info = { patterns: [{}] };
  export let show_errors = false;

  const cache = getContext("cache");

  let drillers = [];
  let is_valid = true;

  onMount(on_mount);

  function on_mount() {
    drillers = cache.lists.drillers
      .map((driller) => ({
        uuid: driller.uuid,
        name: driller.name || driller.email,
        available: driller.active !== false
      }))
      .sort((a, b) => naturalCompare(a.name, b.name));
  }

  export function validate() {
    show_errors = true;

    // Require patterns to have pattern, hole size, and drill.  Blank
    // patterns won't be saved, so don't bother validating them.

    const invalid_pattern = drill_log_info.patterns.find(
      (pattern) =>
        (pattern.pattern ||
          pattern.hole_size ||
          pattern.drill ||
          pattern.subdrill) &&
        !(pattern.pattern && pattern.hole_size && pattern.drill)
    );

    is_valid =
      drill_log_info.job_number &&
      drill_log_info.job_number.length === 4 &&
      drill_log_info.site_name &&
      drill_log_info.assigned &&
      drill_log_info.assigned.length > 0 &&
      !invalid_pattern;

    return is_valid;
  }
</script>

<TextInput
  label="Job Number"
  icon={faFile}
  maxlength="4"
  characters="0123456789"
  placeholder="Please enter a valid 4-digit Job Number"
  invalid={show_errors && !(drill_log_info.job_number && drill_log_info.job_number.length === 4)}
  invalid_text="Job Number is required and must be four digits"
  bind:value={drill_log_info.job_number} />
<TextInput
  label="Site Name"
  icon={faFile}
  placeholder="Please enter the Site Name"
  invalid={show_errors && !drill_log_info.site_name}
  invalid_text="Site Name is required"
  bind:value={drill_log_info.site_name} />
<TextInput
  label="GPS Coordinates"
  icon={faGlobeAmericas}
  placeholder="Please enter the GPS Coordinates"
  bind:value={drill_log_info.gps_coordinates} />
<HolePatterns
  hole_sizes={cache.lists.sizes}
  drills={cache.lists.drills}
  subdrills={cache.lists.subdrills}
  {show_errors}
  bind:patterns={drill_log_info.patterns} />
<TagSelect
  label="Drillers"
  icon={faUser}
  placeholder="Please enter the Driller(s) assigned to this job"
  no_results_text="No drillers found."
  data={drillers}
  invalid={show_errors && (!drill_log_info.assigned || drill_log_info.assigned.length === 0)}
  invalid_text="Drillers are required to be assigned"
  bind:selections={drill_log_info.assigned} />

{#if drill_log_info.status}
  <Info label="Status">{drill_log_info.status}</Info>
{/if}

<Switch
  label="Suspended"
  type="is-danger"
  disabled={drill_log_info.status === "Billed" && !drill_log_info.suspended}
  tip={drill_log_info.status === "Billed" && !drill_log_info.suspended ? "Suspend unavailable while in Billed status" : null}
  tip_placement="right"
  bind:checked={drill_log_info.suspended} />

<TextBox label="Notes" bind:value={drill_log_info.notes} />

<hr />

<div class="message is-danger" class:is-hidden={is_valid || !show_errors}>
  <div class="message-body">
    Unable to save; please correct the items listed above.
  </div>
</div>
