const section = {
  id: "v1",
  name: "Vehicle Safety",
  items: [
    ["Foot Brake", 10],
    ["E. Brake", 20],
    ["Horn", 30],
    ["Head/Tail/Signal Lights", 40],
    ["Seatbelts", 50],
    ["Mirrors", 60],
    ["Windows", 70],
    ["Tires", 80],
    ["Wheel Chocks", 90],
    ["B/U Alarm", 100],
    ["Coolant/Fuel/Oil", 110],
    ["Shovel/Ax", 120],
    ["Fire Extinguisher", 130],
    ["First Aid Kit", 140],
    ["Spill Kit", 150]
  ]
};
exports.section = section;
