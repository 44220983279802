<script>
  import { createEventDispatcher, getContext, tick, onDestroy } from "svelte";
  import Icon from "fa-svelte";
  import {
    faChevronCircleLeft,
    faClock,
    faMapMarkerAlt,
    faTruck,
    faUser
  } from "@fortawesome/free-solid-svg-icons";

  import DropDown from "mdlui/components/forms/dropdown";
  import DateTimeField from "mdlui/components/forms/datetime";
  import InputText from "mdlui/components/forms/input-text";

  export let is_create_modal_active;
  export let is_editor_modal_active;
  export let edited_time;
  export let new_time;

  const cache = getContext("cache");
  const heartbeat = getContext("heartbeat");
  const models = getContext("models");
  const sync = getContext("sync");
  const user = getContext("get_user")();

  const states = [
    { name: "California" },
    { name: "Idaho" },
    { name: "Montana" },
    { name: "Oregon" },
    { name: "Washington" }
  ];

  $: if (is_editor_modal_active === true) {
    on_open();
  }

  $: if (is_create_modal_active === true) {
    on_open();
  }

  // These will get replaced with actual lists
  let users = [];
  let strategy = [];
  let time_types = [];
  let vehicles = [];
  const dispatch = createEventDispatcher();

  let active_time = {};
  let content;
  let modified = false;
  let is_burger_selected = false;
  let is_time_valid = false;
  let show_errors = false;

  async function save_time() {
    validate(active_time);

    if (is_time_valid) {
      modified = true;
      show_errors = false;
      delete active_time.editable;
      delete active_time.job_number;
      delete active_time.is_active_end_time_valid;
      await models.time_sheet_entry.update(active_time.uuid, active_time, {
        strategy,
        sync: { to: "indexed" }
      });

      if (!$heartbeat) {
        await models.sync_task.update(active_time.uuid, {
          uuid: active_time.uuid,
          model: "time_sheet_entry",
          operation:
            typeof active_time.start_time === "string" ? "update" : "create",
          object: active_time
        });
      } else {
        await sync.synchronize_time_sheet(active_time.uuid);
      }

      close();
    }
  }

  function validate(time) {
    show_errors = true;

    is_time_valid =
      !!time.user_uuid &&
      !!time.time_type_uuid &&
      !!time.start_time &&
      !!time.end_time;
  }

  async function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    if ($heartbeat) {
      await sync.push_time_sheets();
      await sync.push_safety_checklists();
    }

    strategy = $heartbeat ? ["backend"] : ["indexed"];
    active_time = is_create_modal_active ? new_time : edited_time;

    // Get a list of users the current user is allowed to clock in
    // and make an array of their uuids.
    users = await models.time_sheet_entry.allowed_users(user);

    // Get a list of all the job time types and vehicles
    time_types = await models.time_type.retrieve_all({ strategy });
    if (active_time.log_uuid) {
      time_types = time_types.filter((tt) => tt.job_time === true);
    } else {
      time_types = await models.time_type.retrieve_all({ strategy });
    }

    vehicles = await models.list.vehicle.retrieve_all({ strategy });

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    content.scrollTo(0, 0);
  }

  async function on_burger_selected() {
    is_burger_selected = !is_burger_selected;
  }

  function on_back_button_click() {
    close();
  }

  function on_close_button_click() {
    close();
  }

  function on_modal_close_button_click() {
    close();
  }

  function close() {
    is_editor_modal_active = false;
    is_create_modal_active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close", { modified });
  }

  onDestroy(() => {
    if (is_editor_modal_active || is_create_modal_active) {
      close();
    }
  });
</script>

<style>
.navbar-brand .button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

.checkbox {
  width: 40px;
  height: 40px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvdGltZS1lZGl0b3Iuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLGdDQUFnQztFQUNoQyxpQ0FBaUM7QUFDbkM7O0FBRUE7RUFDRSxlQUFlO0VBQ2YsV0FBVztFQUNYLFlBQVk7RUFDWixNQUFNO0FBQ1I7O0FBRUE7RUFDRSxhQUFhO0VBQ2IsWUFBWTtFQUNaLG1CQUFtQjtFQUNuQix1QkFBdUI7RUFDdkIsbUJBQW1CO0FBQ3JCOztBQUVBO0VBQ0UsV0FBVztFQUNYLFlBQVk7QUFDZCIsImZpbGUiOiJzcmMvbW9kYWxzL3RpbWUtZWRpdG9yLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLm5hdmJhci1icmFuZCAuYnV0dG9uIHtcbiAgcGFkZGluZy1sZWZ0OiBjYWxjKDAuNzVlbSAtIDFweCk7XG4gIHBhZGRpbmctcmlnaHQ6IGNhbGMoMC43NWVtIC0gMXB4KTtcbn1cblxuLm1kbC1tb2RhbC1uYXZpZ2F0aW9uIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICB6LWluZGV4OiA1MDtcbiAgd2lkdGg6IDEwMHZ3O1xuICB0b3A6IDA7XG59XG5cbi5tZGwtbmF2YmFyLXRpdGxlIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1ncm93OiAxO1xuICBtaW4taGVpZ2h0OiAzLjI1cmVtO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cblxuLmNoZWNrYm94IHtcbiAgd2lkdGg6IDQwcHg7XG4gIGhlaWdodDogNDBweDtcbn1cbiJdfQ== */</style>

<div
  class="modal mdl-modal"
  class:is-active={is_editor_modal_active || is_create_modal_active}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <button
          class="button is-white is-medium"
          on:click={on_back_button_click}>
          <Icon icon={faChevronCircleLeft} />
        </button>

        <div class="mdl-navbar-title">
          <h1 class="subtitle">
            {`Time Log $(is_editor_modal_active ? "Edit" : "Creattion")`}
          </h1>
        </div>

        <span
          role="button"
          class="navbar-burger burger"
          class:is-active={is_burger_selected}
          aria-label="menu"
          aria-expanded="false"
          data-target="mdl-log-view-navigation"
          on:click|stopPropagation={on_burger_selected}>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">
        {`Time Log ${is_editor_modal_active ? "Edit" : "Creation"}`}
      </h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      {#if active_time === undefined}
        <div class="button is-fullwidth is-light is-loading" />
      {/if}

      <hr />

      <section class="section">
        <div class="container">
          <div class="control mb-3">
            {#if !active_time.safety_checklist_uuid}
              <DropDown
                icon={faUser}
                data={users}
                data_key="uuid"
                label="Name:"
                placeholder="Select User"
                bind:value={active_time.user_uuid} />
            {:else}
              {(cache.lookups.users[active_time.user_uuid] && cache.lookups.users[active_time.user_uuid].name) || ""}
            {/if}
          </div>
          <div class="control mb-3">
            {#if !active_time.safety_checklist_uuid}
              <DropDown
                icon={faClock}
                data={time_types}
                data_key="uuid"
                label="Role:"
                placeholder="Select Role"
                bind:value={active_time.time_type_uuid} />
            {:else}
              {(cache.lookups.time_types[active_time.time_type_uuid] && cache.lookups.time_types[active_time.time_type_uuid].name) || ""}
            {/if}
          </div>
          {#if is_create_modal_active || active_time.vehicle_uuid}
            <div class="control mb-3">
              <DropDown
                icon={faTruck}
                data={vehicles}
                data_key="uuid"
                label="Vehicle:"
                placeholder="Select Vehicle"
                bind:value={active_time.vehicle_uuid} />
            </div>
          {/if}
          <div class="control mb-3">
            <DropDown
              icon={faMapMarkerAlt}
              data={states}
              data_key="name"
              label="State Worked In:"
              placeholder="Select State"
              bind:value={active_time.state} />
          </div>
          <div class="control mb-3">
            <InputText label="Nearest Town:" bind:value={active_time.town} />
          </div>
          <div class="control is-expanded mb-3">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Overnight Stay?</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      class="checkbox"
                      type="checkbox"
                      velue="true"
                      bind:checked={active_time.overnight} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DateTimeField
            {show_errors}
            label="Start Time:"
            on:time_validation={({ detail: { is_time_valid } }) => {
              active_time.is_active_end_time_valid = is_time_valid;
            }}
            bind:value={active_time.start_time}
            no_future={true} />
          {#if is_editor_modal_active}
            <DateTimeField
              {show_errors}
              label="End Time:"
              on:time_validation={({ detail: { is_time_valid } }) => {
                active_time.is_active_end_time_valid = is_time_valid;
              }}
              start_time={active_time.start_time}
              bind:value={active_time.end_time}
              no_future={true} />
          {/if}

          <hr />

          <div class="field is-horizontal">
            <div class="field-label" />
            <div class="field-body">
              <div class="field is-grouped">
                <div class="control">
                  {#if is_create_modal_active}
                    <button
                      class="button"
                      on:click={() => close()}
                      disabled={!active_time.is_active_end_time_valid}>
                      Clock In
                    </button>
                  {:else}
                    <button
                      class="button"
                      on:click={() => save_time()}
                      disabled={!active_time.is_active_end_time_valid}>
                      Save
                    </button>
                  {/if}
                  <button
                    class="button is-danger"
                    on:click={on_close_button_click}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <button
        class="modal-close is-large"
        aria-label="close"
        on:click={on_modal_close_button_click} />
    </div>
  </div>
</div>
