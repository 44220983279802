<script>
  import { createEventDispatcher, getContext, tick, onDestroy } from "svelte";
  import Icon from "fa-svelte";
  import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

  export let is_clock_out_checklist;
  export let is_view_safety_checklist_modal_active;
  export let timesheet;
  export let checklist_questions;

  $: if (is_view_safety_checklist_modal_active === true) {
    on_open();
  }

  const heartbeat = getContext("heartbeat");
  const models = getContext("models");
  const sync = getContext("sync");
  const dispatch = createEventDispatcher();
  const modified = false;

  let content;
  let is_burger_selected = false;
  let checklist_answers;
  let strategy = [];
  let title = "Safety Checklist";

  async function on_open() {
    if ($heartbeat) {
      await sync.push_time_sheets();
      await sync.push_safety_checklists();
    }

    strategy = $heartbeat ? ["backend"] : ["indexed"];

    if (is_clock_out_checklist) {
      checklist_answers = await models.safety_checklist.retrieve(
        timesheet.end_checklist_uuid,
        { strategy }
      );
      title = "Clock Out Checklist";
    } else {
      checklist_answers = await models.safety_checklist.retrieve(
        timesheet.safety_checklist_uuid,
        { strategy }
      );
      title = "Safety Checklist";
    }

    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    // We want a fresh view every time this modal is opened, so we need
    // to reset the scroll position.  For some reason, the damned thing
    // won't scroll immediately.  I think it's because at the time this
    // on_open event handler fires, the DOM hasn't yet been updated and
    // the modal still has display:none on it.  Svelte offers this tick
    // function to get around the issue and it seems to DTRT.

    await tick();

    content.scrollTo(0, 0);
  }

  async function on_burger_selected() {
    is_burger_selected = !is_burger_selected;
  }

  function on_back_button_click() {
    close();
  }

  function on_close_button_click() {
    close();
  }

  function on_modal_close_button_click() {
    close();
  }

  async function close() {
    is_view_safety_checklist_modal_active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close", { modified });
  }

  onDestroy(() => {
    if (is_view_safety_checklist_modal_active) {
      close();
    }
  });
</script>

<style>
.navbar-brand .button {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
}

.mdl-modal-navigation {
  position: fixed;
  z-index: 50;
  width: 100vw;
  top: 0;
}

.mdl-navbar-title {
  display: flex;
  flex-grow: 1;
  min-height: 3.25rem;
  justify-content: center;
  align-items: center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvdmlldy1zYWZldHktY2hlY2tsaXN0LnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSxnQ0FBZ0M7RUFDaEMsaUNBQWlDO0FBQ25DOztBQUVBO0VBQ0UsZUFBZTtFQUNmLFdBQVc7RUFDWCxZQUFZO0VBQ1osTUFBTTtBQUNSOztBQUVBO0VBQ0UsYUFBYTtFQUNiLFlBQVk7RUFDWixtQkFBbUI7RUFDbkIsdUJBQXVCO0VBQ3ZCLG1CQUFtQjtBQUNyQiIsImZpbGUiOiJzcmMvbW9kYWxzL3ZpZXctc2FmZXR5LWNoZWNrbGlzdC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5uYXZiYXItYnJhbmQgLmJ1dHRvbiB7XG4gIHBhZGRpbmctbGVmdDogY2FsYygwLjc1ZW0gLSAxcHgpO1xuICBwYWRkaW5nLXJpZ2h0OiBjYWxjKDAuNzVlbSAtIDFweCk7XG59XG5cbi5tZGwtbW9kYWwtbmF2aWdhdGlvbiB7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgei1pbmRleDogNTA7XG4gIHdpZHRoOiAxMDB2dztcbiAgdG9wOiAwO1xufVxuXG4ubWRsLW5hdmJhci10aXRsZSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZ3JvdzogMTtcbiAgbWluLWhlaWdodDogMy4yNXJlbTtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG4iXX0= */</style>

<div
  class="modal mdl-modal"
  class:is-active={is_view_safety_checklist_modal_active}>
  <div class="modal-background" />

  <div class="mdl-modal-navigation">
    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="log-view navigation">
      <div class="navbar-brand">
        <button
          class="button is-white is-medium"
          on:click={on_back_button_click}>
          <Icon icon={faChevronCircleLeft} />
        </button>

        <div class="mdl-navbar-title">
          <h1 class="subtitle">{title}</h1>
        </div>

        <span
          role="button"
          class="navbar-burger burger"
          class:is-active={is_burger_selected}
          aria-label="menu"
          aria-expanded="false"
          data-target="mdl-log-view-navigation"
          on:click|stopPropagation={on_burger_selected}>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
    </nav>
  </div>

  <div class="modal-content" bind:this={content}>
    <div class="box">
      <h1 class="title is-hidden-mobile">{title}</h1>

      <br class="is-hidden-tablet" />
      <br class="is-hidden-tablet" />

      <hr />

      {#if checklist_questions.sections && checklist_answers}
        {#each checklist_questions.sections as section (section.id)}
          <section class="section">
            <div class="container">
              <p class="title mb-5">{section.name}</p>
            </div>
            {#if section.items}
              {#each section.items as item}
                <p>
                  {item[0]} : {!!checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`] && !!checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer && !!checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.response ? checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.response : ""}
                  {" "}
                  {!!checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`] && !!checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer && !!checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.note ? checklist_answers.items[`${checklist_questions.id}-${section.id}-${item[1]}`].answer.note : ""}
                </p>
              {/each}
            {/if}
          </section>
        {/each}
      {/if}

      <button
        class="modal-close is-large"
        aria-label="close"
        on:click={on_modal_close_button_click} />

      <div class="level-right">
        <div class="buttons is-right">
          <button class="button" on:click={on_close_button_click}>Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
