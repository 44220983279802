<script>
  import { faCircle, faTools } from "@fortawesome/free-solid-svg-icons";

  import NumberInput from "mdlui/components/forms/input-number";
  import DropDown from "mdlui/components/forms/dropdown";

  export let pattern = {};
  export let hole_sizes = [];
  export let drills = [];
  export let subdrills = [];
  export let show_errors = true;

  let is_blank;

  $: is_blank = !(
    pattern.burden ||
    pattern.space ||
    pattern.hole_size ||
    pattern.drill ||
    pattern.subdrill
  );

  $: {
    // Seamlessly handle old data by splitting patterns if we don't have
    // a defined burden and space.

    if (
      pattern.pattern &&
      pattern.burden === undefined &&
      pattern.space === undefined
    ) {
      [pattern.burden, pattern.space] = pattern.pattern.split("x");
    }

    // Assemble the pattern so that the rest of the application can
    // continue to function as it has been.  Require both burden and
    // space for a pattern to be defined; otherwise, form validation
    // will succeed when it should fail.

    pattern.pattern =
      pattern.burden && pattern.space
        ? [pattern.burden, pattern.space].join("x")
        : undefined;
  }
</script>

<div class="control">
  <NumberInput
    placeholder="Burden"
    invalid={show_errors && !pattern.burden}
    invalid_text="Burden is required"
    bind:value={pattern.burden} />
</div>

<div class="control has-icons-left">
  <p class="button is-static">&#xd7;</p>
</div>

<div class="control">
  <NumberInput
    placeholder="Space"
    invalid={show_errors && !pattern.space}
    invalid_text="Space is required"
    bind:value={pattern.space} />
</div>

<div class="control has-icons-left">
  <DropDown
    icon={faCircle}
    data={hole_sizes}
    data_key="name"
    placeholder="Hole Size"
    invalid={show_errors && !is_blank && !pattern.hole_size}
    bind:value={pattern.hole_size} />
  <p
    class="help is-danger"
    class:is-hidden={!show_errors || is_blank || pattern.hole_size}>
    Hole size is required
  </p>
</div>

<div class="control has-icons-left">
  <DropDown
    icon={faTools}
    data={drills}
    data_key="name"
    placeholder="Drill"
    invalid={show_errors && !is_blank && !pattern.drill}
    bind:value={pattern.drill} />
  <p
    class="help is-danger"
    class:is-hidden={!show_errors || is_blank || pattern.drill}>
    Drill is required
  </p>
</div>

<div class="control has-icons-left">
  <DropDown
    icon={faTools}
    data={subdrills}
    data_key="name"
    placeholder="Subdrill"
    bind:value={pattern.subdrill} />
</div>
