import IINAClient, { IINAError } from "iina-client";

const USER_METADATA_KEY = "mdl-user";

class BackendService {
  constructor({ handlers = {}, storage, fetch }) {
    this.capabilities = [];
    this.handlers = handlers;
    this.storage = storage || localStorage;
    this.available = true;

    this.client = new IINAClient({
      url: process.env.MDL_API_URL,
      version: process.env.MDL_UI_VERSION,
      storage: this.storage,
      fetch: fetch || window.fetch.bind(window),
      handlers
    });

    if (this.is_logged_in() && this.handlers.login) {
      this.handlers.login({
        user: JSON.parse(this.storage.getItem(USER_METADATA_KEY)),
        date_login: this.client.auth.get_issue_date()
      });
    }
  }

  is_available() {
    return this.available;
  }

  set_availability(available) {
    this.available = available;
  }

  async get_version() {
    const version_info = await this.client.get("v1/version");

    return version_info.version;
  }

  get_storage() {
    return {
      [USER_METADATA_KEY]: this.storage.getItem(USER_METADATA_KEY),
      ...this.client.get_storage()
    };
  }

  is_logged_in() {
    return this.client.auth.is_valid();
  }

  get_session_ttl() {
    return this.client.auth.get_ttl();
  }

  async get_auth_url() {
    const auth_info = await this.client.get("v1/auth/start", { bypass: true });

    return auth_info.auth_url;
  }

  async login(code) {
    let user = null;

    const res = await this.client.post("v1/auth/complete", {
      body: { code },
      bypass: true
    });

    if (res && res.user) {
      user = res.user;
    } else {
      const err = new Error("");
      err.name = "MDLError";
      err.message = "login-no-user";
      throw err;
    }

    this.storage.setItem(USER_METADATA_KEY, JSON.stringify(user));

    if (this.handlers.login) {
      this.handlers.login({
        user,
        date_login: this.client.auth.get_issue_date(),
        storage: this.get_storage()
      });
    }

    return user;
  }

  async refresh() {
    if (this.client.auth.is_stale()) {
      await this.client.post("v1/auth/refresh");
    }
  }

  logout() {
    // Don't try to logout if not logged in.

    if (this.is_logged_in()) {
      this.client.post("v1/auth/logout");
    }

    this.client.reset({ include: [USER_METADATA_KEY] });

    if (this.handlers.logout) {
      this.handlers.logout();
    }
  }

  async export_timesheets(date_range, powder = false) {
    const res = await this.client.post("v1/time_sheet_entry/export_csv", {
      body: { date_range, powder }
    });

    if (res) {
      return res;
    }
    return false;
  }

  async run_report(report_query) {
    const { start_date, end_date, type, report_parameters } = report_query;
    const res = await this.client.post("v1/reports", {
      body: {
        start_date,
        end_date,
        type,
        report_parameters
      }
    });

    if (res) {
      return res;
    }
    const err = new Error("");
    err.name = "MDLError";
    err.message = "report-api-failure";
    throw err;
  }

  get_sync_data() {
    return this.client.get("v1/sync");
  }

  async handle_request(method, args) {
    let result;

    try {
      result = await this.client[method](...args);
      this.available = true;
    } catch (err) {
      if (err.name === "IINAError" && err.code === IINAError.NetworkError) {
        this.available = false;
      }
      throw err;
    }

    return result;
  }

  get(...args) {
    return this.handle_request("get", args);
  }

  post(...args) {
    return this.handle_request("post", args);
  }

  patch(...args) {
    return this.handle_request("patch", args);
  }

  put(...args) {
    return this.handle_request("put", args);
  }

  delete(...args) {
    return this.handle_request("delete", args);
  }
}

export default BackendService;
