import Model from "mdlui/model";

const RS = String.fromCharCode(30);

class HoleMaterialListModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/hole_material" },
        indexed: { indexed, store: "list.hole_material" }
      }
    });
  }

  async retrieve(...args) {
    const material = await super.retrieve(...args);

    return {
      ...material,
      id: [material.code, material.name].join(RS)
    };
  }

  async retrieve_all(...args) {
    const materials = await super.retrieve_all(...args);

    return materials.map((material) => ({
      ...material,
      id: [material.code, material.name].join(RS)
    }));
  }

  async search(...args) {
    const materials = await super.search(...args);

    return materials.map((material) => ({
      ...material,
      id: [material.code, material.name].join(RS)
    }));
  }
}

export default HoleMaterialListModel;
