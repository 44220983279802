import Model from "mdlui/model";

class VehicleListModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/vehicle" },
        indexed: { indexed, store: "list.vehicle" }
      }
    });
  }
}

export default VehicleListModel;
