<script>
  import { createEventDispatcher } from "svelte";
  import Moment from "moment";
  import Icon from "fa-svelte";
  import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
  import DateTime from "mdlui/datetime";

  export let label;
  export let tip;
  export let value;
  export let date_only = false;
  export let show_errors = false;
  export let readonly = false;
  export let no_future = false;
  export let start_time = null;
  export let is_time_valid = false;

  const dispatch = createEventDispatcher();

  // The date and time input element types are currently the most widely
  // supported standard elements for date and time entry.  They require
  // values formatted according to ISO8601.  This is reasonable, but the
  // elements do not deal in UTC.  Ah, time zones, my old friend.
  //
  // Our reaction to outside changes in component value and user input
  // should always be in agreement.  Data must be stored and transfered
  // as ISO8601 in UTC while all input elements should display date/time
  // values according to locale.
  //
  // The trouble is trying to coax localized date and time values out of
  // the native Date object, which was crafted by Satan himself to drive
  // programmers mad.  I believe the theory is that JavaScript will find
  // its way into the maintenance of nuclear launch systems, so a mishap
  // is an eventuality.
  //
  // Anyways, we can use Unicode extensions and Canadian locales to get
  // tz-appropriate ISO8601 date/time values out of Date.  There is an
  // en-US-u-ca-iso8601 calendar extension, but it didn't work for me.

  let date;
  let time;

  // If the component value changes, attempt to parse it as an ISO8601
  // string and then convert that string into locale-specific date and
  // time values suitable for feeding into the date/time input elements.

  $: if (value) {
    parse_value();
  } else {
    date = null;
    time = null;
  }

  function parse_value() {
    const moment = new Moment(value);

    if (moment.isValid()) {
      if (moment.year() > 9999) {
        moment.year(9999);
      }

      date = moment.format("YYYY-MM-DD");
      time = moment.format("HH:mm");
    } else {
      date = null;
      time = null;
    }
    validate_time();
    dispatch("date_change");
    dispatch("time_change");
  }

  // If the component start_time changes
  // attempt to parse it and set the min and max date

  let min_date;
  let max_date;
  let min_time;
  let max_time;

  $: if (start_time) {
    parse_min_max_date_time();
  } else {
    set_default_min_max_date_time();
  }

  function parse_min_max_date_time() {
    const start_moment = new Moment(start_time);

    if (start_time && start_moment.isValid()) {
      if (start_moment.year() > 9999) {
        start_moment.year(9999);
      }

      const current_rounded_time = new Moment(
        DateTime.round_time_quarter_hour()
      );
      const current_value = new Moment(value);

      // Set min and max date
      min_date = start_time ? start_moment.format("YYYY-MM-DD") : null;

      // max time span allowed is 24 hours so max date is 1 day after min date
      max_date =
        no_future && start_moment.isSame(current_rounded_time, "day")
          ? current_rounded_time.format("YYYY-MM-DD")
          : start_moment.clone().add({ days: 1 }).format("YYYY-MM-DD");

      // set maximum time
      if (no_future && start_moment.isSame(current_rounded_time, "day")) {
        max_time = Moment(current_rounded_time).format("HH:mm");
      } else if (
        current_value.isSame(start_moment.clone().add({ days: 1 }), "day")
      ) {
        max_time = start_moment.format("HH:mm");
      } else {
        max_time = null;
      }

      // set minimum time
      min_time = current_value.isSame(start_moment, "day")
        ? start_moment.clone().add({ minutes: 15 }).format("HH:mm")
        : null;

      validate_time();
    } else {
      set_default_min_max_date_time();
    }
  }

  function set_default_min_max_date_time() {
    // Set max date to current day when no future time is set to true.
    const current_rounded_time = new Moment(DateTime.round_time_quarter_hour());
    const current_value = new Moment(value);
    min_date = null;
    max_date = no_future
      ? current_rounded_time.format("YYYY-MM-DD")
      : "9999-12-31";
    min_time = null;
    max_time =
      no_future && value && current_rounded_time.isSame(current_value, "day")
        ? current_rounded_time.format("HH:mm")
        : null;
    validate_time();
  }

  function validate_time() {
    const current_rounded_time = new Moment(DateTime.round_time_quarter_hour());
    const start_moment = new Moment(start_time);
    const current_value = new Moment(value);
    if (value && current_value.isValid()) {
      if (start_time && start_moment.isValid()) {
        // check current time entry (start and end times) is valid
        is_time_valid = DateTime.is_time_entry_valid(
          start_moment,
          current_value
        );
      } else {
        // if no start time is set
        is_time_valid = no_future
          ? current_value.isSameOrBefore(current_rounded_time)
          : true;
      }
    } else {
      is_time_valid = false;
    }
    dispatch("time_validation", { is_time_valid });
  }

  // If the date or time input elements change, we'll generate a new
  // ISO8601 date that we'll use to update the component value.

  function on_date_change() {
    console.log("testing date");
    const moment = new Moment(date);

    if (moment.isValid() === false) {
      date = null;
      value = null;
      return;
    }

    // Input elements of type date happily accepts dates with years up
    // to 275760.  On September 13, 275760, the number of milliseconds
    // since the epoch began will eclipse 8,640,000,000,000,000.  The
    // Date object, however, cannot parse ISO 8601 formats with years
    // beyond that of 9999.
    //
    // ISO 8601:2004 has this to say about years:
    //
    //    By mutual agreement of the partners in information interchange,
    //    it is permitted to expand the component identifying the calendar
    //    year, which is otherwise limited to four digits.
    //
    // Ah, HTML, how I loathe you.
    //
    // We set an upper limit of 9999-12-31 via the "max" attribute on
    // the input element, not all browsers respect it, so we need to
    // make sure we truncate the year if it exceeds the maximum size
    // that Date will accept.

    if (moment.year() > 9999) {
      moment.year(9999);
    }

    date = moment.format("YYYY-MM-DD");

    // Double check date is not after max date.
    if (no_future && moment.isAfter(max_date, "day")) {
      date = Moment(max_date).format("YYYY-MM-DD");
    }

    if (date_only === true) {
      value = date;
    } else if (time) {
      value = new Moment(`${date}T${time}`).toISOString();
    } else {
      value = null;
    }

    parse_min_max_date_time();

    dispatch("date_change");
  }

  function on_time_change() {
    if (time && date) {
      const moment = new Moment(`${date}T${time}`);

      if (moment.isValid()) {
        value = moment.toISOString();
      } else {
        time = null;
        value = null;
      }
    } else {
      value = null;
    }
    validate_time();
    dispatch("time_change");
  }
</script>

<style>
.input[type="date"] {
  min-width: 11em;
}
.input[type="time"] {
  min-width: 9em;
}
input:invalid {
  background-color: rgba(241, 70, 104, 0.1);
}
input:valid {
  background: none;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm1zL2RhdGV0aW1lLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7RUFDRSxlQUFlO0FBQ2pCO0FBQ0E7RUFDRSxjQUFjO0FBQ2hCO0FBQ0E7RUFDRSx5Q0FBeUM7QUFDM0M7QUFDQTtFQUNFLGdCQUFnQjtBQUNsQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9mb3Jtcy9kYXRldGltZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5pbnB1dFt0eXBlPVwiZGF0ZVwiXSB7XG4gIG1pbi13aWR0aDogMTFlbTtcbn1cbi5pbnB1dFt0eXBlPVwidGltZVwiXSB7XG4gIG1pbi13aWR0aDogOWVtO1xufVxuaW5wdXQ6aW52YWxpZCB7XG4gIGJhY2tncm91bmQtY29sb3I6IHJnYmEoMjQxLCA3MCwgMTA0LCAwLjEpO1xufVxuaW5wdXQ6dmFsaWQge1xuICBiYWNrZ3JvdW5kOiBub25lO1xufVxuIl19 */</style>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field is-grouped date-time-field-group">
      <div
        class="control is-expanded has-icons-left has-tooltip-top
        has-tooltip-arrow"
        data-tooltip={tip}>
        <input
          class="input"
          class:is-danger={show_errors && !date}
          type="date"
          disabled={readonly}
          bind:value={date}
          min={min_date}
          max={max_date}
          on:change={on_date_change} />
        <span class="icon is-small is-left">
          <Icon icon={faCalendar} />
        </span>
        <p class="help is-danger" class:is-hidden={!show_errors || date}>
          Date is required
        </p>
      </div>
      {#if date_only !== true}
        <div
          class="control is-expanded has-icons-left has-tooltip-top
          has-tooltip-arrow"
          data-tooltip={tip}>
          <input
            class="input"
            class:is-danger={show_errors && !time}
            type="time"
            disabled={readonly}
            bind:value={time}
            min={min_time}
            max={max_time}
            on:change={on_time_change} />
          <span class="icon is-small is-left">
            <Icon icon={faClock} />
          </span>
          <p class="help is-danger" class:is-hidden={!show_errors || time}>
            Time is required
          </p>
        </div>
      {/if}
    </div>
  </div>
</div>
