<script>
  import Icon from "fa-svelte";

  export let label;
  export let icon;
  export let placeholder;
  export let value = "";
  export let readonly = false;
</script>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field">
      <div class="control {icon ? "has-icons-left" : ""}">
        <textarea {readonly} class="textarea" {placeholder} bind:value />
        {#if icon}
          <span class="icon is-small is-left">
            <Icon {icon} />
          </span>
        {/if}
      </div>
    </div>
  </div>
</div>
