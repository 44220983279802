const moment = require("moment");
const DailySubtotalByDriller = require("./reports/daily_subtotal_by_driller.js");
const PatternSubtotal = require("./reports/pattern_subtotal.js");
const JobTotal = require("./reports/job_total.js");
const CompanyTotal = require("./reports/company_total.js");

class Reports {
  constructor() {
    const self = this;

    return self;
  }

  static calculate(
    type,
    passed_in_start_date,
    passed_in_end_date,
    dataset,
    report_parameters
  ) {
    const start_date =
      passed_in_start_date || moment().subtract(30, "days").toISOString();
    const end_date = passed_in_end_date || moment().toISOString();

    const results = {
      start_date,
      end_date,
      report_results: {}
    };

    const date_filtered_dataset = Reports._date_filtered_dataset(
      start_date,
      end_date,
      dataset
    );

    if (Object.keys(date_filtered_dataset).length === 0) {
      return results;
    }

    switch (type) {
      case "daily subtotal by driller":
        results.report_results = DailySubtotalByDriller.calculate(
          date_filtered_dataset,
          report_parameters
        );
        break;
      case "pattern subtotal":
        results.report_results = PatternSubtotal.calculate(
          date_filtered_dataset
        );
        break;
      case "job total":
        results.report_results = JobTotal.calculate(date_filtered_dataset);
        break;
      case "company total":
        results.report_results = CompanyTotal.calculate(date_filtered_dataset);
        break;
      default:
        throw new Error("invalid request");
    }

    return results;
  }

  // type:2020-08-21:2020-08-25::foo_param:foo_value::bar_param:bar_value
  static generate_report_id(
    type,
    passed_in_start_date,
    passed_in_end_date,
    report_parameters = {}
  ) {
    let formatted_params = "";

    const start_date = moment(passed_in_start_date).toISOString().split("T")[0];
    const end_date = moment(passed_in_end_date).toISOString().split("T")[0];

    if (Object.keys(report_parameters).length > 0) {
      for (const param_key of Object.keys(report_parameters).sort()) {
        formatted_params = `${formatted_params}::${param_key}:${report_parameters[param_key]}`;
      }
    }

    return `${type}:${start_date}:${end_date}${formatted_params}`;
  }

  // Helper methods
  static _date_filtered_dataset(start_date, end_date, initial_dataset) {
    const filtered_dataset = {};
    Object.values(initial_dataset).forEach((drill_log) => {
      const filtered_cells = [];
      drill_log.graphs.forEach((cell) => {
        if (cell.date_drilled) {
          if (
            moment(cell.date_drilled).isBefore(end_date) &&
            moment(cell.date_drilled).isAfter(start_date)
          ) {
            filtered_cells.push(cell);
          }
        }
      });
      if (filtered_cells.length > 0) {
        filtered_dataset[drill_log.uuid] = drill_log;
        filtered_dataset[drill_log.uuid].graphs = filtered_cells;
      }
    });
    return filtered_dataset;
  }

  static _flatten(arr) {
    return arr.reduce((acc, val) => [...acc, ...val], []);
  }
}

module.exports = Reports;
