const sheet = {
  id: "b2",
  name: "blaster_after",
  sections: [
    {
      id: "b2",
      name: "Blaster Clock Out",
      items: [
        ["How many seismographs", "n10"],
        ["Skidsteer", 20],
        ["Dewater pump", 30]
      ]
    }
  ]
};

exports.sheet = sheet;
