<script>
  import { getContext } from "svelte";
  import Decimal from "big.js";
  import Icon from "fa-svelte";
  import {
    faMountain,
    faMinusCircle,
    faPlusCircle
  } from "@fortawesome/free-solid-svg-icons";

  import DropDown from "mdlui/components/forms/dropdown";

  export let profiles = [];
  export let max_depth;
  export let show_errors = false;
  export let readonly = false;

  const cache = getContext("cache");

  const hole_depth_start = 0;
  let hole_depth_end = max_depth;

  let depth_start_input_values = [];
  let depth_end_input_values = [];

  $: hole_depth_end = max_depth;

  $: if (profiles.length) {
    profiles[profiles.length - 1].end = max_depth;

    // This has to be delegated to a separate function or else Svelte
    // will invoke this reactive block whenever any of the input values
    // change, which is not what we want.  We only want to initialize
    // input values when the profiles change.

    format_input_values();
  }

  function on_remove_click(index) {
    const above = profiles[index - 1];
    const below = profiles[index + 1];

    profiles.splice(index, 1);

    if (above && below) {
      below.start = above.end;
    } else if (!above && below) {
      below.start = hole_depth_start;
    } else if (above && !below) {
      above.end = hole_depth_end;
    }

    format_input_values();
  }

  function on_add_click() {
    profiles.push({
      start: profiles.length ? profiles[profiles.length - 1].end : 0,
      end: max_depth
    });

    format_input_values();
  }

  function on_depth_end_input_focus(index) {
    const profile = profiles[index];

    depth_end_input_values[index] = String(profile.end);
  }

  function on_depth_end_input_blur(index) {
    const profile = profiles[index];
    const below = profiles[index + 1];

    try {
      let end = new Decimal(depth_end_input_values[index]);

      if (end.lt(profile.start)) {
        end = new Decimal(profile.start);
      }

      profile.end = end.toFixed(0);

      if (below) {
        if (end.lt(below.end)) {
          below.start = profile.end;
        } else {
          // eslint-disable-next-line no-multi-assign
          below.start = profile.end = below.end;
        }
      }
    } catch (err) {
      // If parsing fails, we won't perform any updates.
    }

    format_input_values();
  }

  function format_input_values() {
    depth_start_input_values = profiles.map((profile) => `${profile.start} ft`);
    depth_end_input_values = profiles.map((profile) => `${profile.end} ft`);
  }

  function on_density_select(index, density) {
    profiles[index].density = density;
  }
</script>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Hole Profile</label>
  </div>

  <div class="field-body is-block">
    {#each profiles as profile, index}
      <div class="field has-addons">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Start"
            disabled
            value={depth_start_input_values[index]} />
        </div>
        <div
          class="control has-tooltip-top has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <input
            class="input"
            type="text"
            placeholder="End"
            disabled={readonly || index + 1 === profiles.length}
            on:focus={() => on_depth_end_input_focus(index)}
            on:blur={() => on_depth_end_input_blur(index)}
            bind:value={depth_end_input_values[index]} />
          {#if show_errors}
            {#if profile.end === "" || profile.end === undefined}
              <p class="help is-danger">End depth is required</p>
            {:else if Number(profile.end) <= Number(profile.start)}
              <p class="help is-danger">End depth must be below start depth</p>
            {:else if Number(profile.end) > Number(max_depth)}
              <p class="help is-danger">End depth cannot be below hole depth</p>
            {/if}
          {/if}
        </div>
        <div
          class="control has-tooltip-top has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <DropDown
            icon={faMountain}
            data={cache.lists.materials}
            data_key="id"
            disabled={readonly}
            placeholder="Select Material"
            invalid={show_errors && !profile.material}
            invalid_text="Material is required"
            bind:value={profile.material} />
        </div>
        <div
          class="control has-tooltip-top has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <button
            class="button {profile.density === "Soft" ? "is-info" : ""}"
            disabled={readonly}
            on:click={() => on_density_select(index, "Soft")}>
            <span>S</span>
          </button>
        </div>
        <div
          class="control has-tooltip-top has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <button
            class="button {profile.density === "Medium" ? "is-info" : ""}"
            disabled={readonly}
            on:click={() => on_density_select(index, "Medium")}>
            <span>M</span>
          </button>
        </div>
        <div
          class="control has-tooltip-top has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <button
            class="button {profile.density === "Hard" ? "is-info" : ""}"
            disabled={readonly}
            on:click={() => on_density_select(index, "Hard")}>
            <span>H</span>
          </button>
        </div>
        <div
          class="control has-tooltip-left has-tooltip-arrow"
          data-tooltip={readonly ? "Hole data is currently read-only" : null}>
          <button
            class="button"
            disabled={readonly}
            on:click={() => on_remove_click(index)}>
            <Icon icon={faMinusCircle} />
          </button>
        </div>
      </div>
    {/each}
    <div class="control">
      <div
        class="has-tooltip-top has-tooltip-arrow"
        data-tooltip={readonly ? "Hole data is currently read-only" : null}>
        <button class="button" disabled={readonly} on:click={on_add_click}>
          <Icon icon={faPlusCircle} />
        </button>
      </div>
    </div>
  </div>
</div>
