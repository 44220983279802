import Moment from "moment";

function format_american_date(iso_string) {
  return iso_string ? new Moment(iso_string).format("MM/DD/YY") : "";
}

function format_american_date_and_time(iso_string) {
  const date = iso_string ? new Date(iso_string) : null;

  const options = {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit"
  };

  return date ? date.toLocaleString("en-US", options) : "";
}

function format_american_short_day_of_week(iso_string) {
  const date = iso_string ? new Date(iso_string) : null;

  const options = {
    weekday: "short",
    month: "numeric",
    day: "numeric"
  };

  return date ? date.toLocaleString("en-US", options) : "";
}

function format_extended_date_and_time(iso_string) {
  const date = iso_string ? new Date(iso_string) : null;

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  };

  return date ? date.toLocaleString("en-US", options) : "";
}

function round_time_quarter_hour() {
  const timeToReturn = new Date();

  timeToReturn.setMilliseconds(
    Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
}

function is_time_entry_valid(start_time, end_time) {
  if (start_time && end_time) {
    const startTime = Moment(start_time);
    const endTime = Moment(end_time);
    const minTime = startTime.clone().add({ minutes: 15 }); // end time must be after start time and minimum interval is 15 minutes
    const maxTime = startTime.clone().add({ days: 1 }); // maximum interval is 24 hours
    return endTime.isBetween(minTime, maxTime, undefined, "[]"); // end time must between min and max time
  }
  return false;
}

export default {
  format_american_date,
  format_american_date_and_time,
  format_american_short_day_of_week,
  format_extended_date_and_time,
  round_time_quarter_hour,
  is_time_entry_valid
};
