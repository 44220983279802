import Model from "mdlui/model";
import naturalCompare from "natural-compare";

class TimeSheetEntryModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/time_sheet_entry" },
        indexed: { indexed, store: "time_sheet_entry" }
      }
    });
  }

  async allowed_users(user) {
    let users;
    const strategy = ["indexed"];

    const user_sorter = (a, b) => {
      const a_name = a.name || "";
      const b_name = b.name || "";

      return naturalCompare(a_name, b_name) || naturalCompare(a.email, b.email);
    };

    if (user.capabilities.includes("time_sheet_entry:superuser")) {
      users = await this.models.user.retrieve_all({ strategy });
      users.sort(user_sorter);
    } else {
      users = [user];
    }

    return users;
  }

  // eslint-disable-next-line class-methods-use-this
  is_editable(entry, user) {
    return (
      // TODO: MCCDL-422 Check for submitted flag and deny if true
      entry.user_uuid === user.uuid ||
      user.capabilities.includes("time_sheet_entry:superuser")
    );
  }
}

export default TimeSheetEntryModel;
