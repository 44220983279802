<script>
  import { createEventDispatcher, onDestroy } from "svelte";
  import { Tabs, Tab } from "svelma";
  import Icon from "fa-svelte";
  import DailyTotals from "mdlui/components/logs/totals/daily";
  import DrillerTotals from "mdlui/components/logs/totals/driller";
  import CapTotals from "mdlui/components/logs/totals/cap";
  import JobTotals from "mdlui/components/logs/totals/job";
  import CancelButton from "mdlui/components/forms/cancel-button";

  import { Totals } from "mdl-shared";
  import { faCalculator, faFlag } from "@fortawesome/free-solid-svg-icons";

  export let active;
  export let drill_log;
  export let graph;
  export let readonly;

  $: if (active === true) {
    on_open();
  }

  const dispatch = createEventDispatcher();

  let totals;
  let bentonite;

  const do_we_prompt_on_cancel = () => !readonly;

  function close() {
    active = false;

    document.documentElement.classList.remove("is-clipped");
    document.body.classList.remove("is-clipped");

    dispatch("close");
  }

  function on_open() {
    document.documentElement.classList.add("is-clipped");
    document.body.classList.add("is-clipped");

    totals = Totals.calculate_one(drill_log, [
      graph.map((row) =>
        row.filter((cell) => cell.data).map((cell) => cell.data)
      )
    ]);

    console.log("totals", totals);

    // Pull out bentonite information.  Since it's data that's modified
    // by the UI, we don't want to scribble on the source object.

    bentonite = drill_log.bentonite ? { ...drill_log.bentonite } : {};
  }

  async function on_save() {
    dispatch("save", { bentonite });
    close();
  }

  onDestroy(() => {
    if (active) {
      close();
    }
  });
</script>

<style>
/* Svelma's Tabs component toggles the display attribute to control
 * visibilty; unfortunately, this allows the enclosing container to
 * reflow, which can cause the modal dialog to resize upon switching
 * tabs.  This is suboptimal UX, so force a minimum height. */

:global(.box .tabs-wrapper) {
  min-height: 50vh;
}

/* Svelma's Tabs component has tabs that scroll by default, but it's
 * clunky as hell, especially on mobile.  Let's prevent the tabs from
 * scrolling at all in favor of implementing scrolling on individual
 * elements with the tabs. */

:global(.tab) {
  overflow: hidden;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9tb2RhbHMvdG90YWxzLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7Ozs2REFHNkQ7O0FBRTdEO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBOzs7NEJBRzRCOztBQUU1QjtFQUNFLGdCQUFnQjtBQUNsQiIsImZpbGUiOiJzcmMvbW9kYWxzL3RvdGFscy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi8qIFN2ZWxtYSdzIFRhYnMgY29tcG9uZW50IHRvZ2dsZXMgdGhlIGRpc3BsYXkgYXR0cmlidXRlIHRvIGNvbnRyb2xcbiAqIHZpc2liaWx0eTsgdW5mb3J0dW5hdGVseSwgdGhpcyBhbGxvd3MgdGhlIGVuY2xvc2luZyBjb250YWluZXIgdG9cbiAqIHJlZmxvdywgd2hpY2ggY2FuIGNhdXNlIHRoZSBtb2RhbCBkaWFsb2cgdG8gcmVzaXplIHVwb24gc3dpdGNoaW5nXG4gKiB0YWJzLiAgVGhpcyBpcyBzdWJvcHRpbWFsIFVYLCBzbyBmb3JjZSBhIG1pbmltdW0gaGVpZ2h0LiAqL1xuXG46Z2xvYmFsKC5ib3ggLnRhYnMtd3JhcHBlcikge1xuICBtaW4taGVpZ2h0OiA1MHZoO1xufVxuXG4vKiBTdmVsbWEncyBUYWJzIGNvbXBvbmVudCBoYXMgdGFicyB0aGF0IHNjcm9sbCBieSBkZWZhdWx0LCBidXQgaXQnc1xuICogY2x1bmt5IGFzIGhlbGwsIGVzcGVjaWFsbHkgb24gbW9iaWxlLiAgTGV0J3MgcHJldmVudCB0aGUgdGFicyBmcm9tXG4gKiBzY3JvbGxpbmcgYXQgYWxsIGluIGZhdm9yIG9mIGltcGxlbWVudGluZyBzY3JvbGxpbmcgb24gaW5kaXZpZHVhbFxuICogZWxlbWVudHMgd2l0aCB0aGUgdGFicy4gKi9cblxuOmdsb2JhbCgudGFiKSB7XG4gIG92ZXJmbG93OiBoaWRkZW47XG59XG4iXX0= */</style>

<div class="modal mdl-modal" class:is-active={active}>
  <div class="modal-background" />
  <div class="modal-content">
    <div class="box">
      <h1 class="title">
        <Icon icon={faCalculator} />
        <span>Totals</span>
      </h1>

      <nav class="level">
        <nav class="level-item has-text-centered">
          <div>
            <p class="heading">Job #</p>
            <p class="subtitle">{drill_log ? drill_log.job_number : ""}</p>
          </div>
        </nav>
        <nav class="level-item has-text-centered">
          <div>
            <p class="heading">Site Name</p>
            <p class="subtitle">
              <span>{drill_log ? drill_log.site_name : ""}</span>
              {#if readonly}
                <span
                  class="has-tooltip-top has-tooltip-arrow"
                  data-tooltip="Job has been suspended, and is in read-only
                  mode.">
                  <Icon class="has-text-danger" icon={faFlag} />
                </span>
              {/if}
            </p>
          </div>
        </nav>
        <nav class="level-item has-text-centered">
          <div>
            <p class="heading">Created By</p>
            <p class="subtitle">{drill_log ? drill_log.creator : ""}</p>
          </div>
        </nav>
      </nav>

      <Tabs>
        <Tab label="Daily Totals">
          {#if totals}
            <DailyTotals daily_totals={totals.daily} bind:bentonite />
          {/if}
        </Tab>

        <Tab label="Driller Totals">
          {#if totals}
            <DrillerTotals daily_totals={totals.daily} />
          {/if}
        </Tab>

        <Tab label="Job Totals">
          {#if totals}
            <JobTotals
              {drill_log}
              job_totals={totals.job}
              pattern_totals={totals.patterns} />
          {/if}
        </Tab>

        <Tab label="Cap Counts">
          {#if totals}
            <CapTotals
              nonel={totals.nonel_counts}
              estar={totals.estar_counts} />
          {/if}
        </Tab>
      </Tabs>

      <hr />

      <div class="buttons is-right">
        {#if readonly === false}
          <button class="button" on:click={on_save}>Save</button>
        {/if}
        <CancelButton
          do_we_prompt={do_we_prompt_on_cancel}
          close_callback={close}
          classes="is-danger" />
      </div>
    </div>
  </div>
  <CancelButton
    do_we_prompt={do_we_prompt_on_cancel}
    close_callback={close}
    button_type="close" />
</div>
