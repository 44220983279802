<script>
  import { createEventDispatcher } from "svelte";

  import Icon from "fa-svelte";

  export let label = "";
  export let data = [];
  export let is_first_default = true;
  export let value = data && data[0] && is_first_default ? data[0].value : undefined;
  export let classes = "";
  export let disabled = false;
  export let tip;

  const dispatch = createEventDispatcher();

  function on_select(item) {
    if (value !== item.value) {
      value = item.value;

      dispatch("change", { item });
    }
  }
</script>

<div class="field is-horizontal {classes}">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field is-flex">
      <div
        class="buttons has-addons has-tooltip-top has-tooltip-arrow"
        data-tooltip={tip}>
        {#each data as item}
          <button
            class="button {value === item.value ? "is-info" : ""}"
            {disabled}
            on:click={() => on_select(item)}>
            {#if item.icon}
              <span class="icon is-small is-left">
                <Icon icon={item.icon} />
              </span>
            {/if}
            <span>{item.name}</span>
          </button>
        {/each}
      </div>
    </div>
  </div>
</div>
