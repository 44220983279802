<script>
  import { getContext, onMount } from "svelte";

  import Interstitial from "mdlui/components/interstitial";

  export let params;

  const backend = getContext("backend");
  const message = getContext("message");
  const heartbeat = getContext("heartbeat");

  let auth_url = null;
  let loading = false;

  onMount(on_mount);

  // The login page has two states: pre-auth and post-auth.  The state
  // change from pre-auth to post-auth is triggered by the presence of
  // the "code" parameter in the query string.  This is the auth token
  // we'll eventually get back in the OAuth redirect that takes place
  // after successful authentication occurs.
  //
  // In the pre-auth state, we grab a URL from the backend that we'll
  // use to start the OAuth dance.
  //
  // In the post-auth state, we activate a loading spinner while we talk
  // to the backend to exchange the OAuth code for a JWT session token.
  // This takes a second or two.  Once we get a successful response, we
  // show the dashboard.

  async function on_mount() {
    console.log("mdl-ui: login: mount");

    const auth_code = params.get("code");

    if (auth_code) {
      auth_complete(auth_code);
    } else {
      auth_url = await backend.get_auth_url();
    }
  }

  async function on_login_button_click() {
    window.location = auth_url || (await backend.get_auth_url());
  }

  async function auth_complete(auth_code) {
    loading = true;

    try {
      await backend.login(auth_code);
    } catch (err) {
      if (err.name === "IINAError" && err.message === "HTTP 403") {
        message.set({
          intent: "danger",
          header: "Unauthorized",
          body: `You are not authorized to use the Drill Log System.
            If you believe this to be incorrect, please contact your
            administrator.`
        });
      } else {
        message.set({
          intent: "danger",
          header: "Unexpected Error",
          body: `The application is experiencing trouble exchanging
            data with the server.  Please try again later.  If the
            problem continues, please contact your administrator.`,
          code: err.toString()
        });
      }

      // We caught an exception while trying to exchange authentication
      // tokens with the backend API.  We can attempt to obtain another
      // authentication URL so the user can try again if they want to.

      auth_url = await backend.get_auth_url();
    }

    loading = false;
  }
</script>

<style>
.message {
  margin-top: -1em !important;
  margin-bottom: 2em !important;
  max-width: 400px;
  margin: auto;
}

pre {
  margin-top: 1em;
}

/* This min-width needs to match the min-width in the loading
 * interstitial in the application root to produce a seamless
 * transition from the login page to the loading screen.
 */

button {
  min-width: 5em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy9sb2dpbi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBO0VBQ0UsMkJBQTJCO0VBQzNCLDZCQUE2QjtFQUM3QixnQkFBZ0I7RUFDaEIsWUFBWTtBQUNkOztBQUVBO0VBQ0UsZUFBZTtBQUNqQjs7QUFFQTs7O0VBR0U7O0FBRUY7RUFDRSxjQUFjO0FBQ2hCIiwiZmlsZSI6InNyYy9wYWdlcy9sb2dpbi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5tZXNzYWdlIHtcbiAgbWFyZ2luLXRvcDogLTFlbSAhaW1wb3J0YW50O1xuICBtYXJnaW4tYm90dG9tOiAyZW0gIWltcG9ydGFudDtcbiAgbWF4LXdpZHRoOiA0MDBweDtcbiAgbWFyZ2luOiBhdXRvO1xufVxuXG5wcmUge1xuICBtYXJnaW4tdG9wOiAxZW07XG59XG5cbi8qIFRoaXMgbWluLXdpZHRoIG5lZWRzIHRvIG1hdGNoIHRoZSBtaW4td2lkdGggaW4gdGhlIGxvYWRpbmdcbiAqIGludGVyc3RpdGlhbCBpbiB0aGUgYXBwbGljYXRpb24gcm9vdCB0byBwcm9kdWNlIGEgc2VhbWxlc3NcbiAqIHRyYW5zaXRpb24gZnJvbSB0aGUgbG9naW4gcGFnZSB0byB0aGUgbG9hZGluZyBzY3JlZW4uXG4gKi9cblxuYnV0dG9uIHtcbiAgbWluLXdpZHRoOiA1ZW07XG59XG4iXX0= */</style>

<Interstitial subtitle="Log in to begin using the Drill Log System.">
  {#if $message}
    <div class="message is-{$message.intent}">
      <div class="message-header">{$message.header}</div>
      <div class="message-body">
        {$message.body}
        {#if $message.code}
          Error code:
          <pre>{$message.code}</pre>
        {/if}
      </div>
    </div>
  {/if}

  {#if !$heartbeat}
    <div class="message is-danger">
      <div class="message-header">Offline</div>
      <div class="message-body">
        You must be connected to the Internet to login.
      </div>
    </div>
  {/if}

  <p class="has-text-centered">
    <button
      class="button is-large is-dark"
      class:is-loading={loading}
      disabled={!$heartbeat}
      on:click={on_login_button_click}>
      Login
    </button>
  </p>
</Interstitial>
