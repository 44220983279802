<script>
  import Icon from "fa-svelte";
  import {
    faMinusCircle,
    faPlusCircle
  } from "@fortawesome/free-solid-svg-icons";

  import HolePattern from "mdlui/components/forms/hole-pattern";

  export let patterns = [{}];
  export let hole_sizes = [];
  export let drills = [];
  export let subdrills = [];
  export let show_errors = true;

  function on_remove_click(index) {
    patterns.splice(index, 1);
    patterns = patterns;
  }

  function on_add_click() {
    patterns.push({});
    patterns = patterns;
  }
</script>

<style>
.pattern-fields {
  margin-bottom: 0.5em;
}
:global(.control .select) {
  max-width: 12em;
}
@media screen and (max-width: 1216px)  {
  .pattern-fields {
    display: flex;
    flex-wrap: wrap;
  }
  :global(input[type="number"]) {
    min-width: 4.5em;
    max-width: 6em;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm1zL2hvbGUtcGF0dGVybnMuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFDQTtFQUNFLG9CQUFvQjtBQUN0QjtBQUNBO0VBQ0UsZUFBZTtBQUNqQjtBQUNBO0VBQ0U7SUFDRSxhQUFhO0lBQ2IsZUFBZTtFQUNqQjtFQUNBO0lBQ0UsZ0JBQWdCO0lBQ2hCLGNBQWM7RUFDaEI7QUFDRiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9mb3Jtcy9ob2xlLXBhdHRlcm5zLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLnBhdHRlcm4tZmllbGRzIHtcbiAgbWFyZ2luLWJvdHRvbTogMC41ZW07XG59XG46Z2xvYmFsKC5jb250cm9sIC5zZWxlY3QpIHtcbiAgbWF4LXdpZHRoOiAxMmVtO1xufVxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogMTIxNnB4KSAge1xuICAucGF0dGVybi1maWVsZHMge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC13cmFwOiB3cmFwO1xuICB9XG4gIDpnbG9iYWwoaW5wdXRbdHlwZT1cIm51bWJlclwiXSkge1xuICAgIG1pbi13aWR0aDogNC41ZW07XG4gICAgbWF4LXdpZHRoOiA2ZW07XG4gIH1cbn1cbiJdfQ== */</style>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Patterns</label>
  </div>

  <div class="field-body is-block">
    {#each patterns as pattern, index}
      <div class="field has-addons pattern-fields">
        <HolePattern
          {pattern}
          {hole_sizes}
          {drills}
          {subdrills}
          {show_errors} />
        <div class="control">
          <button class="button" on:click={() => on_remove_click(index)}>
            <Icon icon={faMinusCircle} />
          </button>
        </div>
      </div>
    {/each}

    <div class="control">
      <button class="button" on:click={on_add_click}>
        <Icon icon={faPlusCircle} />
      </button>
    </div>
  </div>
</div>
