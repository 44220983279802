import Model from "mdlui/model";
import { IINAError } from "iina-client";

const MATRIX_HEIGHT = 100;
const MATRIX_WIDTH = 100;

class GraphModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/graph" },
        indexed: { indexed, store: "graph" }
      }
    });
  }

  async update(...args) {
    let res;

    try {
      res = await super.update(...args);
    } catch (err) {
      if (err.name === "IINAError" && err.code === IINAError.Conflict) {
        res = err.body;
      } else {
        throw err;
      }
    }

    return res;
  }

  async retrieve_aggregated_graph_and_matrix(graph_uuid) {
    const graph = await this.retrieve(graph_uuid);
    const matrix = Array(MATRIX_HEIGHT)
      .fill(null)
      .map((row, row_index) =>
        Array(MATRIX_WIDTH)
          .fill(null)
          .map((column, column_index) => {
            const cell = { row_index, column_index };
            cell.row = String(row_index + 1);
            cell.column = GraphModel.get_column_id_from_index(column_index);
            cell.id = cell.column + cell.row;
            return cell;
          })
      );

    // Update cells in the empty matrix with any existing graph data.

    graph.graph_data.flat().forEach((cell) => {
      matrix[cell.row_index][cell.column_index] = {
        id: cell.id,
        row: cell.row,
        row_index: cell.row_index,
        column: cell.column,
        column_index: cell.column_index,
        data: { ...cell }
      };
    });

    // Then overlay the graph data with any cells from the model (which
    // only supports IndexedDB storage).  These cells are locally stored
    // data that has yet to be synchronized to the backend.

    const cells = await this.models.cell.search({ filters: { graph_uuid } });

    cells.forEach((cell) => {
      matrix[cell.row_index][cell.column_index] = cell;
    });

    // Apply any outstanding tasks from the sync_task model (which only
    // supports our local IndexedDB instance and apply them to the drill
    // log and graph objects.

    const sync_tasks = await this.models.sync_task.search({
      filters: {
        model_name: "graph",
        object_uuid: graph_uuid
      }
    });

    sync_tasks.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    sync_tasks.forEach((sync_task) => Object.assign(graph, sync_task.object));

    return { graph, matrix, width: MATRIX_WIDTH, height: MATRIX_HEIGHT };
  }

  async retrieve_aggregated_graph_and_cropped_matrix(graph_uuid) {
    const { graph, matrix } = await this.retrieve_aggregated_graph_and_matrix(
      graph_uuid
    );

    const cells = matrix
      .flatMap((row, row_index) =>
        row.map((cell, column_index) =>
          cell.data ? [row_index, column_index] : null
        )
      )
      .filter((cell) => cell);

    const row_indices = cells.map((cell) => Number(cell[0]));
    const column_indices = cells.map((cell) => Number(cell[1]));
    const max_row_index = row_indices.sort((a, b) => a - b).pop() || -1;
    const max_column_index = column_indices.sort((a, b) => a - b).pop() || -1;
    const height = max_row_index + 1;
    const width = max_column_index + 1;

    matrix.splice(height);
    matrix.forEach((row) => row.splice(width));

    return { graph, matrix, width, height };
  }

  // get_column_id_from_index
  //
  // This function takes a zero-based index and returns an alphabetical
  // spreadsheet column identifier.  The indicies are unlimited in size.
  // E.g., f(0)=A, f(1)=B, f(26)=AA, etc.

  static get_column_id_from_index(column_index) {
    const remainder = column_index % 26;
    const digits = [String.fromCharCode(65 + remainder)];
    const new_column_index = column_index - remainder;

    if (new_column_index > 0) {
      digits.unshift(
        ...GraphModel.get_column_id_from_index(new_column_index / 26 - 1)
      );
    }

    return digits.join("");
  }
}

export default GraphModel;
