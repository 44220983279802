<script>
  export let nonel;
  export let estar;
</script>

<div class="columns">
  <div class="column">
    <h2 class="subtitle">Nonel</h2>

    <table class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>Footage</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {#each Object.entries(nonel) as [depth, count]}
          <tr>
            <td>{depth}</td>
            <td>{count}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>

  <div class="column">
    <h2 class="subtitle">Estar</h2>

    <table class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>Footage</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {#each Object.entries(estar) as [depth, count]}
          <tr>
            <td>{depth}</td>
            <td>{count}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
