const section = {
  id: "q1",
  name: "Quarry Safety",
  type: "text",
  items: [
    ["Quarry Name", "t10"],
    ["Berms", "t20"],
    ["Roads", "t30"],
    ["Highwalls", "t40"],
    ["Cracks on Surface", "t50"],
    ["Ground Conditions", "t60"],
    ["Quarry Comments", "t70"]
  ]
};

exports.section = section;
