import Model from "mdlui/model";
import { Workflow } from "mdl-shared";

const workflow = new Workflow();

class DrillLogModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/logs" },
        indexed: { indexed, store: "drill-log" }
      }
    });
  }

  async transition({ drill_log, status, strategy }) {
    if (workflow.can_transition(drill_log, status)) {
      await this.update(
        drill_log.uuid,
        {
          status,
          date_updated: drill_log.date_updated
        },
        { strategy }
      );
    } else {
      throw new Error(
        `DrillLogModel#transition: cannot transition ${drill_log.uuid} to ${status}`
      );
    }
  }

  async retrieve_aggregated_drill_log(
    drill_log_uuid,
    { crop_matrix = false } = {}
  ) {
    const drill_log = await this.retrieve(drill_log_uuid);
    const graphs = await Promise.all(
      drill_log.graphs.map((graph_uuid) =>
        crop_matrix
          ? this.models.graph.retrieve_aggregated_graph_and_cropped_matrix(
              graph_uuid
            )
          : this.models.graph.retrieve_aggregated_graph_and_matrix(graph_uuid)
      )
    );
    const timestamps = await this.models.timestamp.drill_log.retrieve(
      drill_log_uuid
    );

    // Apply any outstanding tasks from the sync_task model (which only
    // supports our local IndexedDB instance).  This basically overlays
    // what we know the backend representation is with any changes we've
    // made locally.

    const sync_tasks = await this.models.sync_task.search({
      filters: {
        model_name: "drill_log",
        object_uuid: drill_log.uuid
      }
    });

    sync_tasks.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    sync_tasks.forEach((sync_task) =>
      Object.assign(drill_log, sync_task.object)
    );

    return { drill_log, graphs, timestamps };
  }
}

export default DrillLogModel;
