<script>
  import Icon from "fa-svelte";
  import Decimal from "big.js";

  export let label;
  export let icon;
  export let min;
  export let max;
  export let placeholder;
  export let precision;
  export let invalid = false;
  export let invalid_text = false;
  export let readonly = false;
  export let value;

  let input_value;
  let number_precision;

  $: decimal_min = min ? new Decimal(min) : null;
  $: decimal_max = max ? new Decimal(max) : null;
  $: number_precision = precision ? Number(precision) : null;
  $: format_input_box(value);

  function on_input_input() {
    try {
      if (input_value === undefined) {
        value = undefined;
      } else {
        const decimal_value = new Decimal(input_value);

        update_value(decimal_value);
      }
    } catch {
      input_value = value;
    }
  }

  function on_input_keydown(event) {
    const acceptedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Delete",
      "Backspace",
      "ArrowLeft",
      "ArrowUp",
      "ArrowRight",
      "ArrowDown",
      "Control",
      "Meta",
      "End",
      "Home"
    ];

    if (
      !(acceptedKeys.indexOf(event.key) >= 0) &&
      !(event.key === "." && number_precision !== 0)
    ) {
      event.preventDefault();
    } else if (decimal_max) {
      const num = new Decimal(event.key);
      const decimal_value = new Decimal(input_value);

      if (decimal_max && decimal_value + num > decimal_max) {
        event.preventDefault();
      }
    }
  }

  function on_input_paste(event) {
    try {
      const decimal_value = new Decimal(event.clipboardData.getData("text"));

      update_value(decimal_value);
    } catch (err) {
      input_value = value;
    }
  }

  function format_input_box(value_to_format) {
    input_value = String(value_to_format);
  }

  function update_value(decimal_value) {
    let decimal_value_adjusted = decimal_value;

    if (decimal_min && decimal_value < decimal_min) {
      decimal_value_adjusted = decimal_min;
    }

    if (decimal_max && decimal_value > decimal_max) {
      decimal_value_adjusted = decimal_max;
    }

    if (number_precision) {
      value = decimal_value_adjusted.toFixed(number_precision);
    } else {
      value = decimal_value_adjusted.toFixed();
    }
  }
</script>

<style>
/* Don't render the steppers for number inputs.  They won't be used
 * and they look awful. */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm1zL2lucHV0LW51bWJlci5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUNBO3lCQUN5Qjs7QUFFekI7O0VBRUUsd0JBQXdCO0FBQzFCOztBQUVBO0VBQ0Usd0JBQWdCO1VBQWhCLGdCQUFnQjtFQUNoQiwwQkFBMEI7QUFDNUIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvZm9ybXMvaW5wdXQtbnVtYmVyLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLyogRG9uJ3QgcmVuZGVyIHRoZSBzdGVwcGVycyBmb3IgbnVtYmVyIGlucHV0cy4gIFRoZXkgd29uJ3QgYmUgdXNlZFxuICogYW5kIHRoZXkgbG9vayBhd2Z1bC4gKi9cblxuaW5wdXRbdHlwZT1cIm51bWJlclwiXTo6LXdlYmtpdC1vdXRlci1zcGluLWJ1dHRvbixcbmlucHV0W3R5cGU9XCJudW1iZXJcIl06Oi13ZWJraXQtaW5uZXItc3Bpbi1idXR0b24ge1xuICAtd2Via2l0LWFwcGVhcmFuY2U6IG5vbmU7XG59XG5cbmlucHV0W3R5cGU9XCJudW1iZXJcIl0ge1xuICBhcHBlYXJhbmNlOiBub25lO1xuICAtbW96LWFwcGVhcmFuY2U6IHRleHRmaWVsZDtcbn1cbiJdfQ== */</style>

<div class="field is-horizontal">
  {#if label}
    <div class="field-label is-normal">
      <label class="label">{label}</label>
    </div>
  {/if}

  <div class="field-body">
    <div class="field">
      <div class="control {icon ? "has-icons-left" : ""}">
        <input
          class="input"
          type="number"
          {placeholder}
          {readonly}
          bind:value={input_value}
          on:keydown={on_input_keydown}
          on:input={on_input_input}
          on:paste|preventDefault={on_input_paste} />
        {#if icon}
          <span class="icon is-small is-left">
            <Icon {icon} />
          </span>
        {/if}
        <p class="help is-danger" class:is-hidden={!invalid || !invalid_text}>
          {invalid_text}
        </p>
      </div>
    </div>
  </div>
</div>
