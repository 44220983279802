const personal_safety = require("./sections/personal_safety");
const quarry = require("./sections/quarry");

const sheet = {
  id: "l1",
  name: "driller",
  sections: [
    personal_safety.section,
    {
      id: "l1",
      name: "Drill",
      additional_comment: ["Drill Comments", "t1"],
      items: [
        ["Drill Hours", "n10"],
        ["Drifter Hours", "n20"],
        ["All Fluid Levels", 50],
        ["Fan/Alt/AC belts", 60],
        ["Exhaust", 70],
        ["Batteries", 80],
        ["Back-Up Alarm", 90],
        ["Pins on the Boom", 100],
        ["Cylinders for Leaks", 110],
        ["Check for Cracks", 120],
        ["Brakes", 130],
        ["Grease", 140],
        ["Track Pads & Pins", 150],
        ["Rod Changer", 160],
        ["Steel Rack", 170],
        ["Feed Chain", 180],
        ["Feed Motor", 190],
        ["Hydraulic Leaks", 200],
        ["Windows", 210],
        ["Windshield Wipers", 220],
        ["Lights", 230],
        ["Loose or Missing Bolts", 240],
        ["Step to Cab", 250],
        ["Seatbelts", 260],
        ["Dust Collection System", 270],
        ["Hydraulic Hoses", 280],
        ["Air Hoses", 290],
        ["Fire Extinguisher", 300],
        ["Drill Comments", "t2"]
      ]
    },
    quarry.section
  ]
};

exports.sheet = sheet;
