import Model from "mdlui/model";

class SubdrillListModel extends Model {
  constructor({ models, backend, indexed }) {
    super({
      models,
      storage: {
        backend: { backend, endpoint: "v1/subdrill" },
        indexed: { indexed, store: "list.subdrill" }
      }
    });
  }
}

export default SubdrillListModel;
