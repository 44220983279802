<script>
  import { faTag } from "@fortawesome/free-solid-svg-icons";

  import TextInput from "mdlui/components/forms/input-text";

  export let hole;
  export let show_errors = false;
  export let readonly = false;

  export function validate() {
    show_errors = true;

    return hole.label;
  }
</script>

<TextInput
  label="Label"
  icon={faTag}
  {readonly}
  invalid={show_errors && !hole.label}
  invalid_text="Label is required"
  bind:value={hole.label} />
